import React from 'react';
import { BasicRequestParser } from '../constants';
import { DataOptions } from 'cb-utils/console-entity-models';
import { TriggerSourceDefinition } from '../types';
import { CollectionConditionOptions } from './utils';

const CollectionDeleted: TriggerSourceDefinition = {
  name: DataOptions.CollectionDeleted,
  getSummary: (defKeyObject) => (
    <div>
      <em>
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore */}
        {defKeyObject.collectionName ? `Collection ${defKeyObject.collectionName} deleted` : 'Collection Deleted'}
      </em>
    </div>
  ),
  requestParser: BasicRequestParser,
  conditionOptions: CollectionConditionOptions,
};

export default CollectionDeleted;
