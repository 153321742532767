import React from 'react';
import { BasicRequestParser, getBasicConditionParser } from '../constants';
import { getTextForSummary, CollectionConditionOptions } from './utils';
import { DataOptions } from 'cb-utils/console-entity-models';
import { TriggerSourceDefinition } from '../types';

const ItemDeleted: TriggerSourceDefinition = {
  name: DataOptions.ItemDeleted,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSummary: (defKeyObject) => <div>{getTextForSummary(defKeyObject, 'deleted', 'from')}</div>,
  requestParser: BasicRequestParser,
  conditionOptions: CollectionConditionOptions,
  getConditionParser: getBasicConditionParser,
};

export default ItemDeleted;
