/*
 * OIDC Messages
 *
 * This contains all the text for the OIDC components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  loadingInfo: {
    id: 'app.components.OIDC.loadingInfo',
    defaultMessage: 'Loading User Information',
  },
  validatingInfo: {
    id: 'app.components.OIDC.validatingInfo',
    defaultMessage: 'Validating User Information',
  },
  oidcFormInstructions: {
    id: 'app.components.OIDC.oidcFormInstructions',
    defaultMessage: 'Complete the form to continue to ClearBlade through',
  },
  oidcRegisterMessage: {
    id: 'app.components.OIDC.oidcRegisterMessage',
    defaultMessage: 'Register with',
  },
  oidcContinueMessage: {
    id: 'app.components.OIDC.oidcContinueMessage',
    defaultMessage: 'Continue',
  },
  dividerText: {
    id: 'app.components.OIDC.dividerText',
    defaultMessage: 'OR',
  },
  loginWith: {
    id: 'app.components.OIDC.loginWith',
    defaultMessage: 'Log In With',
  },
  signUpWith: {
    id: 'app.components.OIDC.signUpWith',
    defaultMessage: 'Sign Up With',
  },
  providerFetchError: {
    id: 'app.components.OIDC.providerFetchError',
    defaultMessage: 'Error loading list of third-party login options.',
  },
  registrationKeyLabel: {
    id: 'app.components.OIDC.registrationKeyLabel',
    defaultMessage: 'Registration Key (Optional)',
  },
  firstNameLabel: {
    id: 'app.components.OIDC.firstNameLabel',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'app.components.OIDC.lastNameLabel',
    defaultMessage: 'Last Name',
  },
  organizationNameLabel: {
    id: 'app.components.OIDC.organizationNameLabel',
    defaultMessage: 'Organization',
  },
  registerError: {
    id: 'app.components.OIDC.registerError',
    defaultMessage: 'There was an error registering you through your provider.',
  },
  authenticationError: {
    id: 'app.components.OIDC.authenticationError',
    defaultMessage: 'There was an error authenticating you through your provider.',
  },
});
