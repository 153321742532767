import { systemBaseRoute } from 'cb-utils/consoleRouteUtils';
import { AppRoutes } from '../../App/routes';

export const codeRoot = 'code';

export const codeOverviewRoute = `${AppRoutes.CONSOLE_BASE_ROUTE}${systemBaseRoute}/${codeRoot}`;

export const libraryParam = 'libraryName';
export const serviceParam = 'serviceName';

export const servicesTabId = 'Services';
export const librariesTabId = 'Libraries';
export const triggersTabId = 'Triggers';
export const timersTabId = 'Timers';
export const webhooksTabId = 'Webhooks';

export const codePaths = {
  CODE_OVERVIEW: codeOverviewRoute,

  SERVICE_OVERVIEW: `${codeOverviewRoute}?tab=${servicesTabId}`,
  LIBRARY_OVERVIEW: `${codeOverviewRoute}?tab=${librariesTabId}`,
  TRIGGER_OVERVIEW: `${codeOverviewRoute}?tab=${triggersTabId}`,
  TIMER_OVERVIEW: `${codeOverviewRoute}?tab=${timersTabId}`,
  WEBHOOKS_OVERVIEW: `${codeOverviewRoute}?tab=${webhooksTabId}`,

  SERVICE_DETAIL: `${codeOverviewRoute}/services/:${serviceParam}`,
  SERVICE_DETAIL_LOGS: `${codeOverviewRoute}/services/:${serviceParam}/logs`,
  LIBRARY_DETAIL: `${codeOverviewRoute}/libraries/:${libraryParam}`,
};
