import { useMutation } from '@tanstack/react-query';
import { AllowedMethods, cbFetch, platformFetch } from 'cb-utils/cbFetch';
import { getPlatformInfo } from 'cb-utils/platformInfo';

export interface ValidateTwoFactorAuthMethodBody {
  type: 'email' | 'phone';
}
export const useValidateTwoFactorAuthMethod = () =>
  useMutation({
    mutationFn: (body: ValidateTwoFactorAuthMethodBody) => validateTwoFactorAuthMethod(new AbortController(), body),
  });
export const validateTwoFactorAuthMethod = (_c: AbortController, body: ValidateTwoFactorAuthMethodBody) => {
  return platformFetch(`/admin/validate`, {
    method: AllowedMethods.POST,
    body: JSON.stringify(body),
  });
};

export const getSecuritySettings = (): Promise<SecuritySettings> => {
  return platformFetch(`/admin/settings/security`, {
    method: AllowedMethods.GET,
  });
};

export interface SecuritySettings {
  two_factor_auth: {
    enabled: boolean;
  };
  developer_token_ttl: number;
}

export const updateSecuritySettings = (_c: AbortController, body: Partial<SecuritySettings>) => {
  return platformFetch(`/admin/settings/security`, {
    method: AllowedMethods.PUT,
    body: JSON.stringify(body),
  });
};

export enum EmailEncryptionType {
  STARTTLS = 'STARTTLS',
  TLS = 'TLS',
  None = 'None',
}

export enum EmailProtocolType {
  SMTP = 'SMTP',
}

export interface EmailServiceSettings {
  protocol: EmailProtocolType;
  encryption_type: EmailEncryptionType;
  host: string;
  port: string;
  username: string;
  password: string;
  from: string;
  validation_subject: string;
  validation_message: string;
  two_factor_subject: string;
  two_factor_message: string;
}

export const getEmailServiceSettings = (): Promise<EmailServiceSettings> =>
  platformFetch(`/admin/settings/email-service`, {
    method: AllowedMethods.GET,
  });

export const createEmailServiceSettings = (_c: AbortController, body: EmailServiceSettings) =>
  platformFetch(`/admin/settings/email-service`, {
    method: AllowedMethods.POST,
    body: JSON.stringify(body),
  });

export const updateEmailServiceSettings = (_c: AbortController, body: Partial<EmailServiceSettings>) =>
  platformFetch(`/admin/settings/email-service`, {
    method: AllowedMethods.PUT,
    body: JSON.stringify(body),
  });

export const deleteEmailServiceSettings = () =>
  platformFetch(`/admin/settings/email-service`, {
    method: AllowedMethods.DELETE,
  });

export interface TestCommunicationServiceBody {
  recipient: string;
}

export interface TestCommunicationServiceResponse {
  success: boolean;
  result: string;
}

export const testEmailServiceSettings = (
  body: TestCommunicationServiceBody,
): Promise<TestCommunicationServiceResponse> =>
  platformFetch(`/admin/settings/email-service/test`, {
    method: AllowedMethods.POST,
    body: JSON.stringify(body),
  });

export const testSMSServiceSettings = (body: TestCommunicationServiceBody): Promise<TestCommunicationServiceResponse> =>
  platformFetch(`/admin/settings/sms-service/test`, {
    method: AllowedMethods.POST,
    body: JSON.stringify(body),
  });

export enum SMSServiceNames {
  Twilio = 'Twilio',
}

export interface SMSServiceSettings {
  service_name: SMSServiceNames;
  url: string;
  username: string;
  password: string;
  from: string;
  validation_message: string;
  two_factor_message: string;
}

export const getSMSServiceSettings = (): Promise<SMSServiceSettings> =>
  platformFetch(`/admin/settings/sms-service`, {
    method: AllowedMethods.GET,
  });

export const createSMSServiceSettings = (_c: AbortController, body: SMSServiceSettings) =>
  platformFetch(`/admin/settings/sms-service`, {
    method: AllowedMethods.POST,
    body: JSON.stringify(body),
  });

export const updateSMSServiceSettings = (_c: AbortController, body: Partial<SMSServiceSettings>) =>
  platformFetch(`/admin/settings/sms-service`, {
    method: AllowedMethods.PUT,
    body: JSON.stringify(body),
  });

export const deleteSMSServiceSettings = () =>
  platformFetch(`/admin/settings/sms-service`, {
    method: AllowedMethods.DELETE,
  });

export const verifyTwoFactorAuthMethod = (_c: AbortController, query: string) => {
  const info = getPlatformInfo();
  return cbFetch(`${info.url}/admin/validate/verify${query}`, {
    method: AllowedMethods.GET,
  });
};
