import { AnyMap } from '.';
import { AssetColumnMetadata } from 'cb-utils/console-entity-models/columns';

export enum ARCHITECTURE {
  // this name is important, it is the name used in url
  LINUX_386 = 'linux-386',
  LINUX_32_ARM_V5 = 'linux-armv5',
  LINUX_32_ARM_V6 = 'linux-armv6',
  LINUX_32_ARM_V7 = 'linux-armv7',
  LINUX_64_ARM = 'linux-arm64',
  LINUX_64_AMD = 'linux-amd64',
  LINUX_64_PPCLE = 'linux-ppcle',
  LINUX_64_S390X = 'linux-s390x',
  LINUX_32_MIPSLE = 'linux-mipsle',
  LINUX_32_MIPS = 'linux-mips',
  LINUX_64_MIPS = 'linux-mips64',
  LINUX_64_MIPSLE = 'linux-mipsle64',
  DARWIN_386 = 'darwin-386',
  MAC_OS_64 = 'darwin-amd64',
}

export interface EdgeModel extends AnyMap {
  broker_auth_port: string;
  broker_port: string;
  broker_tls_port: string;
  broker_ws_auth_port: string;
  broker_ws_port: string;
  broker_wss_port: string;
  communication_style: string;
  description: string;
  edge_key: string;
  first_talked: string;
  isConnected: boolean;
  last_seen_version: string;
  last_seen_os: string;
  last_seen_architecture: string;
  last_talked: number;
  local_addr: string;
  local_port: string;
  location: string;
  mac_address: string;
  name: string;
  novi_system_key: string;
  policy_name: string;
  public_addr: string;
  public_port: string;
  resolver_func: string;
  sync_edge_tables: string;
  system_key: string;
  system_secret: string;
  token: string;
}

export interface EdgeDevTokenInfo {
  name: string;
  systemKey: string;
  devToken: string;
}

export enum EDGE_COLUMNS {
  broker_auth_port = 'broker_auth_port',
  broker_port = 'broker_port',
  broker_tls_port = 'broker_tls_port',
  broker_ws_auth_port = 'broker_ws_auth_port',
  broker_ws_port = 'broker_ws_port',
  broker_wss_port = 'broker_wss_port',
  communication_style = 'communication_style',
  description = 'description',
  edge_key = 'edge_key',
  first_talked = 'first_talked',
  isConnected = 'isConnected',
  last_seen_version = 'last_seen_version',
  last_seen_os = 'last_seen_os',
  last_seen_architecture = 'last_seen_architecture',
  last_talked = 'last_talked',
  local_addr = 'local_addr',
  local_port = 'local_port',
  location = 'location',
  mac_address = 'mac_address',
  name = 'name',
  novi_system_key = 'novi_system_key',
  policy_name = 'policy_name',
  public_addr = 'public_addr',
  public_port = 'public_port',
  resolver_func = 'resolver_func',
  sync_edge_tables = 'sync_edge_tables',
  system_key = 'system_key',
  system_secret = 'system_secret',
  token = 'token',
}

const showInCreateModal: EDGE_COLUMNS[] = [EDGE_COLUMNS.name, EDGE_COLUMNS.description, EDGE_COLUMNS.token];

const requiredInCreateModal: EDGE_COLUMNS[] = [EDGE_COLUMNS.name, EDGE_COLUMNS.token];

const noUpdateAllowed: EDGE_COLUMNS[] = [
  EDGE_COLUMNS.name,
  EDGE_COLUMNS.broker_auth_port,
  EDGE_COLUMNS.broker_port,
  EDGE_COLUMNS.broker_tls_port,
  EDGE_COLUMNS.broker_ws_auth_port,
  EDGE_COLUMNS.broker_ws_port,
  EDGE_COLUMNS.broker_wss_port,
  EDGE_COLUMNS.communication_style,
  EDGE_COLUMNS.edge_key,
  EDGE_COLUMNS.first_talked,
  EDGE_COLUMNS.isConnected,
  EDGE_COLUMNS.last_seen_version,
  EDGE_COLUMNS.last_talked,
  EDGE_COLUMNS.local_addr,
  EDGE_COLUMNS.local_port,
  EDGE_COLUMNS.location,
  EDGE_COLUMNS.mac_address,
  EDGE_COLUMNS.novi_system_key,
  EDGE_COLUMNS.policy_name,
  EDGE_COLUMNS.public_addr,
  EDGE_COLUMNS.public_port,
  EDGE_COLUMNS.resolver_func,
  EDGE_COLUMNS.sync_edge_tables,
  EDGE_COLUMNS.system_key,
  EDGE_COLUMNS.system_secret,
  EDGE_COLUMNS.last_seen_version,
  EDGE_COLUMNS.last_seen_os,
  EDGE_COLUMNS.last_seen_architecture,
];

const displayName: EDGE_COLUMNS = EDGE_COLUMNS.name;

export const EDGE_COLUMN_METADATA: AssetColumnMetadata<EDGE_COLUMNS> = {
  defaultColumns: Object.values(EDGE_COLUMNS) as EDGE_COLUMNS[],
  showInCreateModal,
  requiredInCreateModal,
  noUpdateAllowed,
  displayName,
};

export function canUpgradeEdge({ platformVersion, edgeVersion }: { platformVersion: string; edgeVersion: string }) {
  // don't know so allow try
  if (!platformVersion || !edgeVersion) return true;
  if (platformVersion === edgeVersion) return false;
  const platformVersionArr = platformVersion.split(/\.|-rc/);
  const edgeVersionArr = edgeVersion.split(/\.|-rc/);
  try {
    for (let i = 0; i < platformVersionArr.length; i += 1) {
      const num1 = JSON.parse(platformVersionArr[i]);
      const num2 = JSON.parse(edgeVersionArr[i]);
      if (num1 > num2) {
        return true;
      }
    }
  } catch (e) {
    return true; // don't know so allow try
  }
  return false;
}
