import React from 'react';
import { REQ_PARAMS_PATH, getBasicConditionParser } from '../constants';
import { REQ_OBJECT } from '../../serviceConstants';
import { TriggerSourceDefinition } from '../types';
import { DeviceOptions } from 'cb-utils/console-entity-models';
import { DeviceConditionVariables } from './utils';

const DeviceCreated: TriggerSourceDefinition = {
  name: DeviceOptions.DeviceCreated,
  getSummary: () => (
    <div>
      <em>Device Created</em>
    </div>
  ),
  requestParser: `
    var ${REQ_OBJECT} = ${REQ_PARAMS_PATH};
  `,
  conditionOptions: DeviceConditionVariables,
  getConditionParser: getBasicConditionParser,
};

export default DeviceCreated;
