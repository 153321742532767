import DatasourceModel from './DatasourceModel';
import { DEFAULT_VALUE } from 'plugins/datasources/utitility_local_variable/constants';
import { parseIfNecessary } from '../../parseIfNecessary';
import { tempDsName } from '../constants';

class LocalVariableModel extends DatasourceModel {
  processUpdateToSettings() {
    const defaultValueSetting = this.getSettingByName(DEFAULT_VALUE);
    const rtnVal = parseIfNecessary(defaultValueSetting);
    // this will be called on portal start up as well as any time the settings change for a datasource that uses this type
    // we don't want to call update if a user hasn't supplied a value for the default or if they've erased the value (rtnVal === '')
    if (this.name === tempDsName || (typeof rtnVal !== 'undefined' && rtnVal !== '')) {
      this.updateCallback(rtnVal);
    }
    return rtnVal;
  }
}

export default LocalVariableModel;
