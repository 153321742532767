import UserCreated from './UserCreated';
import UserDeleted from './UserDeleted';
import UserUpdated from './UserUpdated';

const UserModule = {
  UserCreated,
  UserDeleted,
  UserUpdated,
};

export default UserModule;
