import React from 'react';

import { REQ_PARAMS_PATH } from '../constants';
import { REQ_OBJECT } from '../../serviceConstants';
import { TriggerSourceDefinition } from '../types';
import { MessagingOptions } from 'cb-utils/console-entity-models';
import { PublishConditionOptions } from './constants';

const Publish: TriggerSourceDefinition = {
  name: MessagingOptions.Publish,
  getSummary: (defKeyObject) => (
    <div>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore */}
      <em>{MessagingOptions.Publish}</em> to <em>{defKeyObject.topic ? defKeyObject.topic : 'ANY topic'}</em>
    </div>
  ),
  requestParser: `
    var ${REQ_OBJECT};

    try {
        ${REQ_OBJECT} = JSON.parse(${REQ_PARAMS_PATH});
    } catch(e) {
        ${REQ_OBJECT} = ${REQ_PARAMS_PATH};
    }
  `,
  conditionOptions: PublishConditionOptions,
};

export default Publish;
