import React from 'react';
import { Button, Collapse } from 'reactstrap';
import styled from 'styled-components';
import { CaretDownIcon, CaretLeftIcon } from './Icons';

const ToggleLink = styled(Button)`
  border-bottom: #eee solid 1px;
  width: 100%;
  border-radius: 0;
  text-align: left;
  padding: 0 !important;
`;

interface Props {
  hideText?: string | JSX.Element;
  showText?: string | JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  doNotUse?: boolean;
  defaultOpen?: boolean;
  className?: string;
  onOpen?: () => void;
}

interface State {
  isOpen: boolean;
}

class CbCollapseArea extends React.Component<Props, State> {
  static defaultProps = {
    hideText: 'Hide',
    showText: 'Show',
    doNotUse: false, // difficult otherwise to toggle the existence of a wrapping element like this
    defaultOpen: false,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state: State = { isOpen: this.props.defaultOpen };

  toggle = () => {
    const newIsOpen = !this.state.isOpen;
    if (this.props.onOpen && newIsOpen) {
      this.props.onOpen();
    }
    this.setState({ isOpen: newIsOpen });
  };

  render() {
    if (this.props.doNotUse) {
      return <div className={this.props.className}>{this.props.children}</div>;
    }
    return (
      <div className={this.props.className}>
        <ToggleLink color="link" onClick={this.toggle}>
          {this.state.isOpen ? this.props.hideText : this.props.showText}
          {this.state.isOpen ? <CaretDownIcon /> : <CaretLeftIcon />}
        </ToggleLink>
        <Collapse isOpen={this.state.isOpen}>{this.props.children}</Collapse>
      </div>
    );
  }
}

export default CbCollapseArea;
