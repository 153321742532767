import React from 'react';
import { ModalBody, ModalBodyProps } from 'reactstrap';
import ScrollableDiv from './ScrollableDiv';

const ScrollableModalBody = ({ children, ...props }: ModalBodyProps) => (
  <ScrollableDiv height="calc(100vh - 15rem)">
    <ModalBody {...props}>{children}</ModalBody>
  </ScrollableDiv>
);

export default ScrollableModalBody;
