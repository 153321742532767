import { cb } from 'cb-utils/cbLib';
import { RoleChanges, Permissions } from 'cb-utils/console-entity-models';

// todo: make this more generic
export const addServiceToRole = (serviceName: string, roleId: string) =>
  new Promise((resolve, reject) => {
    const changes: RoleChanges = {
      services: [{ permissions: Permissions.Read, itemInfo: { name: serviceName } }],
    };
    cb.Roles().update(roleId, changes, (err: boolean, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
