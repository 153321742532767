import { createSelector } from 'reselect';
import { AppState } from 'reducers';

/**
 * Direct selector to the languageToggle state domain
 */
const selectLanguage = (state: AppState) => state.language;

/**
 * Select the language locale
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const makeSelectLocale = () => createSelector(selectLanguage, (languageState) => languageState.locale);

export { selectLanguage, makeSelectLocale };
