import { systemBaseRoute } from 'cb-utils/consoleRouteUtils';
import { AppRoutes } from 'containers/App/routes';

export const devicesRoot = 'devices';
export const devicesOverviewRoute = `${AppRoutes.CONSOLE_BASE_ROUTE}${systemBaseRoute}/${devicesRoot}`;

export const DevicesRouteParams = {
  deviceName: 'deviceName' as const,
};
export const devicePublicKeysRoute = `${devicesOverviewRoute}/:${DevicesRouteParams.deviceName}/public_keys`;
