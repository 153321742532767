import {
  ArraySettingDefinition,
  AsyncSettingDefinition,
  DataSettingDefinition,
  MultiObjectSettingDefinition,
  NumberSettingDefinition,
  OptionSettingDefinition,
  PluginSettingDefinitions,
  SettingDefinition,
  SettingOptions,
  SettingTypes,
  TypedSettingDefinition,
} from 'utils/types';
import { PARSER_TYPES } from './parserTypes';

import { CbEditorLanguages } from 'components/CbCodeEditor';
import { BaseHTMLModel } from 'plugins/widgets/HTMLWidget/def';
import { WidgetSettingGroups } from 'plugins/widgets/types';
import { CALL_ON_LOAD, DATASOURCE_PARSER, REFRESH_INTERVAL, SUPPRESS_DATASOURCE_ERRORS, USE_PARSER } from './constants';
import { ShowIf } from './utils/customSetting';

const createBaseSetting = (name: string, displayName: string): SettingDefinition => {
  return {
    name,
    display_name: displayName,
  };
};

export interface HelpObj {
  description?: string | JSX.Element;
  expectedFormat?: string;
  link?: string;
  expectedFormatTips?: string;
  language?: CbEditorLanguages;
}
const createHelpObject = (
  description?: string,
  expectedFormat?: string,
  link?: string,
  expectedFormatTips?: string,
) => {
  let helpObj: HelpObj = {};
  if (description) {
    helpObj = { description };
  }
  if (expectedFormat) {
    helpObj = { ...helpObj, expectedFormat };
  }
  if (link) {
    helpObj = { ...helpObj, link };
  }
  if (expectedFormatTips) {
    helpObj = { ...helpObj, expectedFormatTips };
  }
  if (Object.keys(helpObj).length === 0) {
    return null;
  }
  return { helpObj };
};

export function createColor<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.COLOR_TYPE,
  };
}

export function createText<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.TEXT_TYPE,
  };
}

export function createTypeAhead<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.TYPE_AHEAD_TYPE,
  };
}

export function createTextArea<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.TEXT_AREA_TYPE,
  };
}

export function createNumber<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): NumberSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.NUMBER_TYPE,
  };
}

export function createBoolean<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.BOOLEAN_TYPE,
  };
}

export function createDataKey<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.DATA_KEY_TYPE,
  };
}

// Only currently used for the form widget
export function createMultiDataKeyCheck<T>(
  name: keyof T,
  displayName: string,
  opts: { isCell?: boolean } & HelpObj,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(opts.description as string, opts.expectedFormat, opts.link),
    type: SettingTypes.MULTI_DATA_KEY_CHECK_TYPE,
    isCell: opts.isCell,
  };
}

export function createOption<T>(
  name: keyof T,
  displayName: string,
  options: SettingOptions,
  description?: string,
  expectedFormat?: string,
  link?: string,
): OptionSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.OPTION_TYPE,
    options,
    activeColor: 'primary',
  };
}

// Creates either a HTML select or a text field. If the options array is not empty,
// a HTML select will be created. If the options array is empty, a text field will
// be created
export function createAsyncSetting<T>(
  name: keyof T,
  displayName: string,
  options: SettingOptions,
  asyncImpl: () => Promise<SettingOptions>,
  description?: string,
  expectedFormat?: string,
  link?: string,
): AsyncSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.ASYNC_OPTIONS_TYPE,
    options,
    asyncImpl,
  };
}

export function createDataSetting<T>(
  name: keyof T,
  displayName: string,
  expectedFormat?: string,
  description?: string,
  expectedFormatTips?: string,
): DataSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, undefined, expectedFormatTips),
    type: SettingTypes.DATA_SETTING_TYPE,
  };
}

export function createMultiObject<T>(
  name: keyof T,
  displayName: string,
  objectShape: PluginSettingDefinitions,
  { description, expectedFormat, link }: HelpObj = {},
): MultiObjectSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description as string, expectedFormat, link),
    singularName: displayName.slice(0, -1),
    type: SettingTypes.MULTI_OBJECT_SETTING_TYPE,
    objectShape,
  };
}

export function createParameterizedTextArea<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.PARAMETERIZED_TEXT_AREA_TYPE,
  };
}

export function createJsonBuilder<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.JSON_BUILDER_TYPE,
  };
}

export function createJsonField<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.JSON_STRING_TYPE,
  };
}

interface ArrayOptions {
  arrayType: SettingTypes.TEXT_TYPE | SettingTypes.NUMBER_TYPE;
}
export function createArrayBuilder<T>(
  name: keyof T,
  displayName: string,
  { arrayType }: ArrayOptions,
  description?: string,
  expectedFormat?: string,
  link?: string,
): ArraySettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.ARRAY_BUILDER_TYPE,
    arrayType,
  };
}

export function createArrayOfAnyEditor(
  name: string,
  displayName: string,
  types: SettingTypes[],
  display_names: string[],
) {
  return {
    ...createBaseSetting(name, displayName),
    type: SettingTypes.ARRAY_OF_ANY_EDITOR,
    types,
    display_names,
  };
}

export function createKeyValBuilder<T>(
  name: keyof T,
  displayName: string,
  description?: string,
  expectedFormat?: string,
  link?: string,
): TypedSettingDefinition {
  return {
    ...createBaseSetting(name as string, displayName),
    ...createHelpObject(description, expectedFormat, link),
    type: SettingTypes.KEY_VALUE_BUILDER_TYPE,
    default_value: {},
  };
}

export interface DefaultSetting {
  dataType: PARSER_TYPES;
  value: DefaultValue;
}

type DefaultValue = string | BaseHTMLModel;

export const parserFnName = 'parser';
export const parserFnParams = ['ctx'];
export const parserFnDeclaration = `${parserFnName} = (${parserFnParams})`;

export const parserFnWrapper = (content: string) => `/**
* The 'parser' variable is the entry point for your parser. Write logic inside of the provided function and return a value
* Constants and utility functions can be created outside of the parser
* The provided ctx parameter is an object that contains data and model information on this item
* @param {context} ${parserFnParams[0]} 
* @returns {rtn} */
${parserFnDeclaration} => {
  ${content}
}`;

export const createDefaultForCalculatedSetting = (value: string): DefaultSetting => {
  return {
    dataType: PARSER_TYPES.CALCULATED,
    value: parserFnWrapper(value),
  };
};

export const createDefaultForStaticSetting = (rtnVal: DefaultValue): DefaultSetting => {
  return {
    dataType: PARSER_TYPES.STATIC,
    value: rtnVal,
  };
};

export const createWrappedReturn = (value: string) =>
  `return {
    widgetData: ${value},
    // Ctrl + SpaceBar on next line to see options (type a few spaces first if it has not loaded)
    
  }`;

export interface DatasourceSettingBase {
  [SUPPRESS_DATASOURCE_ERRORS]: boolean;
  [CALL_ON_LOAD]: boolean;
  [REFRESH_INTERVAL]: number;
  [USE_PARSER]: boolean;
  [DATASOURCE_PARSER]: string;
}

export const DEFAULT_DATASOURCE_SETTINGS = [
  SUPPRESS_DATASOURCE_ERRORS,
  CALL_ON_LOAD,
  REFRESH_INTERVAL,
  USE_PARSER,
  DATASOURCE_PARSER,
];

export function createSuppressDatasourceErrorSetting(): TypedSettingDefinition {
  return {
    ...createBoolean<DatasourceSettingBase>(
      SUPPRESS_DATASOURCE_ERRORS,
      'Suppress Errors',
      "If set to true, any network errors caused by this datasource won't be shown to the user",
    ),
    default_value: false,
  };
}

export function createRefreshIntervalSetting(): NumberSettingDefinition {
  return {
    ...createNumber<DatasourceSettingBase>(
      REFRESH_INTERVAL,
      'Refresh Interval (s)',
      'Update datasource every X seconds. 0 for no interval',
    ),
    default_value: 0,
  };
}

export function createCallOnLoadSetting(): TypedSettingDefinition {
  return {
    ...createBoolean<DatasourceSettingBase>(
      CALL_ON_LOAD,
      'Call on load',
      'If set to true, this datasource will be automatically invoked on portal startup',
    ),
    default_value: true,
  };
}

export function createUseParserSetting(): TypedSettingDefinition {
  return {
    ...createBoolean<DatasourceSettingBase>(
      USE_PARSER,
      'Parse incoming data',
      'If set to true, incoming data can be parsed so that latest data results in a desired format. View parser in the Data tab.',
    ),
    default_value: false,
  };
}

export const defaultDatasourceParser = `return ${parserFnParams[0]}.data`;

export function createDatasourceParserSetting(): TypedSettingDefinition {
  return {
    ...createDataSetting<DatasourceSettingBase>(
      DATASOURCE_PARSER,
      'Parser',
      parserFnWrapper(defaultDatasourceParser),
      'Use this parser to parse incoming data from the datasource to the desired format.',
    ),
    default_value: parserFnWrapper(defaultDatasourceParser),
  };
}

interface TrackingColumn {
  trackingColumnName?: string;
}
export function createTrackingColumnNameSetting<T extends TrackingColumn>() {
  return {
    ...createText<T>(
      'trackingColumnName',
      'Tracking Column Name',
      'Name of column used to keep track of active or default list item. This value is required to select list items.',
    ),
  };
}

interface DefaultListItem {
  defaultListItem?: string;
}
export function createDefaultListItemSetting<T extends DefaultListItem>() {
  return {
    ...createText<T>(
      'defaultListItem',
      'Default List Item',
      'Item selected by default on startup. Value determined by Tracking Column Name property. This setting only applies if not using the list source object, including highlightId, in the List Source parser.',
    ),
  };
}

interface DetectionStrictness {
  detectionStrictness?: number;
}
export function createDetectionStrictnessSetting<T extends DetectionStrictness>() {
  return {
    ...createNumber<T>(
      'detectionStrictness',
      'Anomaly Detection Strictness',
      'Number of standard deviations used to calculate and detect anomalies',
    ),
    default_value: 2,
    CustomSettingComponent: ShowIf('enableStatisticsView', true),
    group: WidgetSettingGroups.CHART_STATISTICS,
  };
}

interface StatisticsView {
  enableStatisticsView?: boolean;
}
export function createEnableStatisticsViewSetting<T extends StatisticsView>() {
  return {
    ...createBoolean<T>(
      'enableStatisticsView',
      'Enable Statistics',
      'Displays button to open modal for statistics about the graph. Button is disabled while editing a widget.',
    ),
    default_value: false,
    group: WidgetSettingGroups.CHART_STATISTICS,
  };
}

interface ListAutoSelect {
  enableListAutoSelect?: boolean;
}
export function createListAutoSelectSetting<T extends ListAutoSelect>() {
  return {
    ...createBoolean<T>(
      'enableListAutoSelect',
      'Enable Auto Select',
      'Automatically select first item in list on load if no Default List Item is indicated or found. This setting only applies if not using the list source object, including highlightId, in the List Source parser.',
    ),
    default_value: false,
    group: WidgetSettingGroups.FORMAT,
  };
}
