import { systemBaseRoute } from 'cb-utils/consoleRouteUtils';
import { AppRoutes } from 'containers/App/routes';

export const secretsRoot = 'secrets';
export const secretsOverviewRoute = `${AppRoutes.CONSOLE_BASE_ROUTE}${systemBaseRoute}/${secretsRoot}`;
export const SECRETS_ROUTE_PARAMS = {
  secretName: 'secretName',
};
export const secretsDetailRoute = `${secretsOverviewRoute}/:${SECRETS_ROUTE_PARAMS.secretName}/edit`;
export const newSecretRoute = `${secretsOverviewRoute}/new`;
