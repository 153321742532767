import DataModule from './Data';
import DeviceModule from './Device';
import MessagingModule from './Messaging';
import UserModule from './User';
import { TriggerModule } from './types';
import { DefModuleOptions } from 'cb-utils/console-entity-models';
import AssetModule from './Asset';

const ModulesByName = {
  Data: DataModule,
  Device: DeviceModule,
  Messaging: MessagingModule,
  User: UserModule,
  Asset: AssetModule,
};

export const getActionType = (module: DefModuleOptions, defName: string) => {
  if (module && defName) {
    return (ModulesByName[module as keyof typeof ModulesByName] as TriggerModule)[defName];
  }
};
