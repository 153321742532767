import { detailPageSuffix, systemBaseRoute } from 'cb-utils/consoleRouteUtils';
import { AppRoutes } from '../../App/routes';

export const filesRoot = 'files';
export const filesBaseRoute = `${AppRoutes.CONSOLE_BASE_ROUTE}${systemBaseRoute}/${filesRoot}`;
export const FILES_ROUTE_PARAMS = {
  BUCKET_SET_NAME: 'bucketSetName',
};
export const filesDetailRoute = `${filesBaseRoute}/:${FILES_ROUTE_PARAMS.BUCKET_SET_NAME}${detailPageSuffix}`;

export interface FilesDetailRouteProps {
  bucketSetName: string;
}
