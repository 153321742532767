import { AndOr } from 'react-querybuilder';
import { TypedSettingDefinition } from 'utils/types';
import { AnyMap, DefModuleOptions, DefNameOptions, TriggerEventDefinition } from '../console-entity-models';
import { getProviderTypeByName } from './AlertProviders';
import { createAlertCodeForRule, getAlertTypeByName } from './AlertTypes';
import buildCondition from './ConditionBuilder';
import { getActionType } from './TriggerSources';
import { BasicRequestParser } from './TriggerSources/constants';
import { ConditionOption, ConditionOptions } from './TriggerSources/types';
import { Alert, ListOfDefKeys } from './types';

const defaultValueForDefKey = '';

export enum LogicOperators {
  '==' = '==',
  '!=' = '!=',
  '<' = '<',
  '>' = '>',
  '<=' = '<=',
  '>=' = '>=',
}

export enum ArithmeticOperators {
  '+' = '+',
  '-' = '-',
  '*' = '*',
  '/' = '/',
}

export const createBlankRBCondition = (): RBCondition => ({
  id: 'fakeId',
  rules: [],
  combinator: 'and',
});

export interface RBCondition {
  id: string;
  rules: RBRule[];
  combinator: AndOr;
}
export interface RuleField {
  base: ConditionOption;
  remainder: string;
  value: string;
  name: string;
}

export interface EQPart {
  option: ConditionOption;
  operator: ArithmeticOperators;
}

export interface RuleValue {
  isArithmetic: boolean;
  content: string;
  operands?: EQPart[];
}

export interface RBRule {
  field: RuleField;
  id: string;
  operator: LogicOperators;
  value: RuleValue;
}

export const formatTriggerDefinitions = (rawDefs: TriggerEventDefinition[]) =>
  rawDefs.map((d) => ({ ...d, def_keys: d.def_keys.filter(filterIdKeys) }));

const filterIdKeys = (key: string) => {
  return key !== 'collectionId';
};

export const createDefKeysForAction = (currentDefKeys: ListOfDefKeys, action: TriggerEventDefinition): ListOfDefKeys =>
  action.def_keys
    .filter((_d, i) => i === 0 || (currentDefKeys[i - 1] && currentDefKeys[i - 1].value))
    .map((d, i) => ({
      displayName: d,
      value: (currentDefKeys[i] && currentDefKeys[i].value) || defaultValueForDefKey,
    }));

export const isAlertStepComplete = (alerts: Alert[]) => {
  if (alerts && alerts.length > 0) {
    for (let i = 0, len = alerts.length; i < len; i += 1) {
      const alert = alerts[i];
      const alertType = alert.type;
      const providerType = alert.provider;
      // check that we have an alert type - if so, loop over its provider fields and check if any of them are required - do the same for alert fields
      if (alertType) {
        const alertDef = getAlertTypeByName(alertType);
        // alert step is incomplete if there isn't a provider selected for an alert type that has providers
        if (alertDef.providers && alertDef.providers.length > 0 && !providerType) {
          return false;
        }

        if (providerType) {
          const provider = getProviderTypeByName(providerType);
          if (!allRequiredFieldsHaveValues(provider.fields, alert.providerFields)) {
            return false;
          }
        }

        if (!allRequiredFieldsHaveValues(alertDef.fields, alert.alertFields)) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }
  return false;
};

const allRequiredFieldsHaveValues = (fieldDefinitions: TypedSettingDefinition[], fields: AnyMap) => {
  for (let i = 0, len = fieldDefinitions.length; i < len; i += 1) {
    if (
      fieldDefinitions[i].required &&
      (!fields[fieldDefinitions[i].name] || fields[fieldDefinitions[i].name] === '')
    ) {
      return false;
    }
  }
  return true;
};

const createNameForRuleEntity = (entityType: string, ruleBuilderName: string, ruleName: string) => {
  return `RB_${ruleBuilderName}_${ruleName}_${entityType}`;
};

export const createNameForRuleService = (ruleBuilderName: string, ruleName: string) => {
  return createNameForRuleEntity('Service', ruleBuilderName, ruleName);
};

export const createNameForRuleTrigger = (ruleBuilderName: string, ruleName: string) => {
  return createNameForRuleEntity('Trigger', ruleBuilderName, ruleName);
};

export const buildRule = (
  triggerModule: DefModuleOptions,
  actionType: DefNameOptions,
  _defKeys: ListOfDefKeys,
  queryCondition: RBCondition,
  alerts: Alert[],
  functionNameForRule: string,
  ruleName: string,
  options: ConditionOptions,
) => {
  const action = getActionType(triggerModule, actionType);
  // note: it's possible that trigger definitions are created on the backend before we can update our actions in the rule builder. therefore, if an action doesn't exist on the frontend, we just use a basic request parser so that the rule can still be created
  const actionRequestParser = action && action.requestParser ? action.requestParser : BasicRequestParser;
  const conditionCode =
    action && action.getConditionParser
      ? action.getConditionParser(queryCondition, options)
      : buildCondition(queryCondition, options, true);
  try {
    Function(conditionCode);
  } catch (e) {
    if (e instanceof SyntaxError) {
      throw new Error(`Syntax error found in ${functionNameForRule}`);
    }
  }

  const alertCode = createAlertCodeForRule(alerts, ruleName);

  return `function ${functionNameForRule} (req, resp) {
    ${actionRequestParser}
    ${conditionCode}
    ${alertCode}
    
    resp.success('Nothing to do');
  }
 
  function tryParse(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
}`;
};

export const createDefKeysObject = (defKeys: ListOfDefKeys) => {
  return defKeys.reduce((red, key) => {
    if (key.value) {
      return {
        ...red,
        [key.displayName as string]: key.value,
      };
      // backend expects a the key_value_pairs a specific way for user events...oh well
    } else if (key.displayName === 'userId') {
      return {
        ...red,
        [key.displayName as string]: '*',
      };
    } else {
      return red;
    }
  }, {});
};
