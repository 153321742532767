import { NATIVE_LIBRARIES, createAlertCaller } from '../serviceConstants';
import { TypedSettingDefinition } from 'utils/types';
import { ProviderCodeGenerator } from '../types';

export abstract class AlertProvider<AlertFields, ProviderFields> {
  name: string;
  label: string;
  serviceDependencies: NATIVE_LIBRARIES[];
  fields: TypedSettingDefinition[];
  constructor(name: string, label: string, serviceDependencies: NATIVE_LIBRARIES[], fields: TypedSettingDefinition[]) {
    this.name = name;
    this.label = label;
    this.serviceDependencies = serviceDependencies;
    this.fields = fields;
  }

  abstract createProviderCode(ruleName: string, gen: ProviderCodeGenerator<AlertFields, ProviderFields>): string;
  abstract getErrorCode(): string;
  abstract getSuccessCode(): string;
  abstract getSummary(alertFields: AlertFields, providerFields: ProviderFields): JSX.Element;

  callNextAlertOrError = (gen: ProviderCodeGenerator<AlertFields, ProviderFields>): string =>
    cbFactory<AlertFields, ProviderFields>(gen, this.getErrorCode);
  callNextAlertOrSuccess = (gen: ProviderCodeGenerator<AlertFields, ProviderFields>): string =>
    cbFactory<AlertFields, ProviderFields>(gen, this.getSuccessCode);
}

function cbFactory<AF, PF>(gen: ProviderCodeGenerator<AF, PF>, cb: () => string) {
  if (gen.idx === gen.array.length - 1) {
    return cb();
  } else {
    return `${createAlertCaller(gen.idx + 1)}();`;
  }
}
