import omit from 'lodash/omit';
import { PARSER_DIRECTIONS } from 'containers/Portal/constants';
import { Map } from '../../../console-entity-models';
import { PluginSettingDefinitions, DataSettingDefinition } from 'utils/types';
import { parserFnName, parserFnDeclaration, parserFnParams } from 'containers/Portal/pluginSettingUtils';

type ParserFunction = Function;
class Parsers {
  list: Map<ParserFunction> = {};

  add(id: string, func: ParserFunction) {
    try {
      this.list = {
        ...this.list,
        [id]: func,
      };
      return this;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw constructErrorMessage(e, id);
    }
  }

  get(id: string) {
    if (this.list[id]) {
      return this.list[id];
    } else {
      throw new Error(`Unable to find parser for '${id}'`);
    }
  }

  execute(id: string, valueForThis: object, ...params: object[]) {
    try {
      return this.get(id).call(valueForThis, ...params);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw constructErrorMessage(e, id);
    }
  }

  remove(id: string) {
    this.list = omit(this.list, id);
    return this;
  }

  clear() {
    this.list = {};
    return this;
  }

  count() {
    return Object.keys(this.list).length;
  }
}

export const constructErrorMessage = (err: Error, settingName: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rtn = new Error(err);
  rtn.message = `Setting name - '${settingName}' - ${err.name} - ${err.message}`;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  rtn.settingName = settingName;
  return rtn;
};

export const createParserFunction = (script: string, debug: boolean, id?: string) => {
  try {
    let newScript = script;
    if (debug) {
      newScript = `/* Hi! Welcome to debug mode.
       If your parser contains 'parser = (ctx) => {}' you can place a breakpoint inside of that function to debug further.
       Click on a line number within the function to add a breakpoint. 
       */
      debugger;
        ${newScript}`;
    }
    if (newScript && isV2Parser(newScript)) {
      newScript = `
        let ${parserFnName};
        ${newScript}
        return ${parserFnName}.call(this, ${parserFnParams.join(', ')})`;
    }
    return Function(parserFnParams[0], newScript);
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw constructErrorMessage(e, id);
  }
};

// note: this isn't perfect as weird formatting could easily cause parsers to be considered the old version (e.g., 'parser  =' is valid JS but would fail our test)
export function isV2Parser(script: string) {
  return (
    script.includes(parserFnDeclaration) ||
    script.includes('parser = ctx =>') ||
    script.includes('parser = () =>') ||
    script.includes('parser = function(') ||
    script.includes('parser = function parser(')
  );
}

export const getParserType = (settings: PluginSettingDefinitions, settingName: string) => {
  let parserType;
  for (const setting of settings) {
    if (setting.name === settingName) {
      parserType = (setting as DataSettingDefinition).incoming_parser
        ? PARSER_DIRECTIONS.INCOMING_PARSER
        : (setting as DataSettingDefinition).outgoing_parser
        ? PARSER_DIRECTIONS.OUTGOING_PARSER
        : undefined;
      return parserType;
    }
  }
};

export default Parsers;
