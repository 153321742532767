import { fetchTriggerDefs } from 'containers/CbPortal/actions';
import produce from 'immer';
import WidgetModel, { WidgetInfo } from './WidgetModel';

import {
  buildRule,
  createDefKeysObject,
  createNameForRuleService,
  createNameForRuleTrigger,
  formatTriggerDefinitions,
} from 'cb-utils/ruleBuilder/utils';
import { ServicePayload } from 'clearblade-js-client';
import { ANON_USER_EMAIL } from 'containers/CbPortal/constants';
import { RuleInfo } from 'plugins/widgets/RuleBuilder/RuleBuilderModal';
import { createStateDomainId } from 'plugins/widgets/RuleBuilder/utils';
import { AppStore } from 'reducers';
import { cb } from '../../cbLib';
import {
  ANONYMOUS_ROLE,
  AUTHENTICATED_ROLE,
  Map,
  TriggerEventDefinition,
  TriggerPayload,
} from '../../console-entity-models';
import { copyJsonObject } from '../../copyJsonObject';
import { SERVICE_NOT_FOUND } from '../../errorCodeConstants';
import { hasPerformedUpgrade } from '../../hasPerformedUpgrade';
import {
  createServiceForRule,
  createTriggerForRule,
  deleteServiceForRule,
  deleteTriggerForRule,
  updateServiceForRule,
} from '../../ruleBuilder/actions';
import { getProviderTypeByName } from '../../ruleBuilder/AlertProviders';
import { addServiceToRole } from '../../ruleBuilder/newActions';
import {
  Alert,
  AlertFields,
  ProviderFields,
  RuleBuilderRulesInfo,
  RuleBuilderSettings,
  V1Alert,
} from '../../ruleBuilder/types';

function safeUpdate<T extends RuleBuilderRulesInfo, K extends keyof T, ID extends keyof T[K], V extends T[K][ID]>(
  settings: T,
  key: K,
  id: ID,
  value: V,
) {
  if (!settings[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Clark did this
    settings[key] = {};
  }
  settings[key][id] = value;
}

const removeRuleFromSettings = (currentSettings: RuleBuilderSettings, ruleId: string) =>
  produce(currentSettings, (draftState) => {
    delete draftState.TRIGGER_SOURCE[ruleId];
    delete draftState.ACTION_TYPE[ruleId];
    delete draftState.QUERY_CONDITION[ruleId];
    delete draftState.ruleName[ruleId];
    delete draftState.CURRENT_DEF_KEYS[ruleId];
    delete draftState.ruleState[ruleId];
    delete draftState.ALERTS[ruleId];
    delete draftState.OPTIONS[ruleId];
  });

const createSettingsWithNewRule = (currentSettings: RuleBuilderSettings, ruleId: string, ruleInfo: RuleInfo) =>
  produce(currentSettings, (draftState) => {
    // I couldn't figure out how to do generic curried functions in TS so we're left with this verbosity
    safeUpdate(draftState, 'TRIGGER_SOURCE', ruleId, ruleInfo.triggerSource);
    safeUpdate(draftState, 'ACTION_TYPE', ruleId, ruleInfo.actionType);
    safeUpdate(draftState, 'QUERY_CONDITION', ruleId, ruleInfo.queryCondition);
    safeUpdate(draftState, 'ruleName', ruleId, ruleInfo.ruleName);
    safeUpdate(draftState, 'CURRENT_DEF_KEYS', ruleId, ruleInfo.defKeys);
    safeUpdate(draftState, 'ruleState', ruleId, true);
    safeUpdate(draftState, 'ALERTS', ruleId, ruleInfo.alerts);
    safeUpdate(draftState, 'OPTIONS', ruleId, ruleInfo.options);
  });

class RuleBuilderModel extends WidgetModel<RuleBuilderSettings> {
  static triggerDefs: TriggerEventDefinition[] = [];
  static hasFetchedTriggerDefs = false;
  static fetchTriggerDefs() {
    if (!RuleBuilderModel.hasFetchedTriggerDefs) {
      return fetchTriggerDefs().then((data) => {
        RuleBuilderModel.hasFetchedTriggerDefs = true;
        RuleBuilderModel.triggerDefs = formatTriggerDefinitions(data);
        return RuleBuilderModel.triggerDefs;
      });
    }
    return Promise.resolve(this.triggerDefs);
  }

  fetchTriggerDefs() {
    return RuleBuilderModel.fetchTriggerDefs();
  }

  async saveRule(ruleId: string, ruleInfo: RuleInfo, ruleBuilderName: string, isNewRule: boolean) {
    const functionNameForRule = createNameForRuleService(ruleBuilderName, ruleInfo.ruleName);
    const triggerNameForRule = createNameForRuleTrigger(ruleBuilderName, ruleInfo.ruleName);
    const rule = buildRule(
      ruleInfo.triggerSource,
      ruleInfo.actionType,
      ruleInfo.defKeys,
      ruleInfo.queryCondition,
      ruleInfo.alerts,
      functionNameForRule,
      ruleInfo.ruleName,
      ruleInfo.options,
    );
    const dependencies =
      ruleInfo.alerts.map((a) => getProviderTypeByName(a.provider).serviceDependencies).join(',') || '';

    const serviceDef: ServicePayload = {
      code: rule,
      parameters: [],
      name: functionNameForRule,
      dependencies,
    };
    if (isNewRule) {
      const role = cb.user.email === ANON_USER_EMAIL ? ANONYMOUS_ROLE : AUTHENTICATED_ROLE;
      await createServiceForRule(functionNameForRule, serviceDef);
      await addServiceToRole(functionNameForRule, role);
    } else {
      await updateServiceForRule(functionNameForRule, serviceDef);
    }

    const triggerDef: TriggerPayload = {
      system_key: 'gibberish', // looks like the backend doesn't actually check for this
      name: triggerNameForRule,
      def_module: ruleInfo.triggerSource,
      def_name: ruleInfo.actionType,
      service_name: functionNameForRule,
      key_value_pairs: createDefKeysObject(ruleInfo.defKeys),
    };
    if (isNewRule) {
      await createTriggerForRule(triggerNameForRule, triggerDef);
    } else {
      await deleteTriggerForRule(triggerNameForRule);
      await createTriggerForRule(triggerNameForRule, triggerDef);
    }
    this.settings(createSettingsWithNewRule(this.settings(), ruleId, ruleInfo));
    this.portalModel.dispatcher.savePortal();
  }

  async toggleRule(ruleId: string, ruleInfo: RuleInfo, ruleBuilderName: string) {
    const ruleState = this.getStateForRule(ruleId);
    const nameForRuleTrigger = createNameForRuleTrigger(ruleBuilderName, ruleInfo.ruleName);

    if (ruleState) {
      await deleteTriggerForRule(nameForRuleTrigger);
    } else {
      const triggerDef = {
        system_key: 'gibberish',
        name: nameForRuleTrigger,
        def_module: ruleInfo.triggerSource,
        def_name: ruleInfo.actionType,
        service_name: createNameForRuleService(ruleBuilderName, ruleInfo.ruleName),
        key_value_pairs: createDefKeysObject(ruleInfo.defKeys),
      };
      await createTriggerForRule(nameForRuleTrigger, triggerDef);
    }

    // toggle the ruleState in settings
    this.settings(
      produce(this.settings(), (draftState: RuleBuilderSettings) => {
        draftState.ruleState[ruleId] = !ruleState;
      }),
    );
    this.portalModel.dispatcher.savePortal();
  }

  async deleteRule(ruleId: string, ruleInfo: RuleInfo, ruleBuilderName: string) {
    try {
      // when we delete a service, the corresponding trigger will be deleted automatically on the backend
      await deleteServiceForRule(createNameForRuleService(ruleBuilderName, ruleInfo.ruleName));
    } catch (e) {
      handleDeleteRuleError(e);
    }

    this.settings(removeRuleFromSettings(this.settings(), ruleId));
    this.portalModel.dispatcher.savePortal();
  }

  getStateForRule(ruleId: string) {
    return this.settings().ruleState[ruleId];
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleDeleteRuleError = (e: any) => {
  try {
    const parsedErr = JSON.parse(e);
    // if the call failed because the service doesn't exist on the backend, remove the rule
    if (parsedErr.error.code === SERVICE_NOT_FOUND) {
      return;
    }
  } catch (parserError) {
    throw e;
  }

  throw e;
};

const migrateAlertInfo = (
  alerts: Map<V1Alert[]>,
  alertFields: Map<AlertFields[]>,
  providerFields: Map<ProviderFields[]>,
): Map<Alert[]> => {
  const copyAlerts = copyJsonObject(alerts);
  const rtn: Map<Alert[]> = {};
  for (const id in copyAlerts) {
    if (Object.prototype.hasOwnProperty.call(copyAlerts, id)) {
      rtn[id] = copyAlerts[id].map(
        (a, i): Alert => ({
          ...a,
          alertFields: alertFields[id][i],
          providerFields: providerFields[id][i],
        }),
      );
    }
  }
  return rtn;
};

export const getRuleBuilderInfo = (
  info: WidgetInfo<RuleBuilderSettings>,
  store: AppStore,
): WidgetInfo<RuleBuilderSettings> => {
  const oldConfig =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.getState().cbPortal.portalConfig.config[
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createStateDomainId(info.id)
    ];
  if (oldConfig && !hasPerformedUpgrade(info.props, 'one')) {
    return {
      ...info,
      props: {
        ...info.props,
        upgradesPerformed: {
          one: true,
        },
        ACTION_TYPE: oldConfig.ACTION_TYPE,
        ALERTS: migrateAlertInfo(oldConfig.ALERTS, oldConfig.alertFields, oldConfig.providerFields),
        CURRENT_DEF_KEYS: oldConfig.CURRENT_DEF_KEYS,
        QUERY_CONDITION: oldConfig.QUERY_CONDITION,
        TRIGGER_SOURCE: oldConfig.TRIGGER_SOURCE,
        ruleName: oldConfig.ruleName,
        ruleState: oldConfig.ruleState,
      },
    };
  }
  return produce(info, (draftState) => {
    draftState.props.upgradesPerformed = {
      one: true,
    };
    if (!info.id) {
      draftState.props.TRIGGER_SOURCE = {};
      draftState.props.ACTION_TYPE = {};
      draftState.props.QUERY_CONDITION = {};
      draftState.props.ruleName = {};
      draftState.props.CURRENT_DEF_KEYS = {};
      draftState.props.ruleState = {};
      draftState.props.ALERTS = {};
    }
  });
};

export default RuleBuilderModel;
