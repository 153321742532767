import React from 'react';
import { BasicRequestParser, getBasicConditionParser } from '../constants';
import { getSummaryForDeviceEvent, DeviceConditionVariables } from './utils';
import { TriggerSourceDefinition } from '../types';
import { DeviceOptions } from 'cb-utils/console-entity-models';

const DeviceDeleted: TriggerSourceDefinition = {
  name: DeviceOptions.DeviceDeleted,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSummary: (defKeyObject) => <div>{getSummaryForDeviceEvent(defKeyObject, 'Deleted')}</div>,
  requestParser: BasicRequestParser,
  conditionOptions: DeviceConditionVariables,
  getConditionParser: getBasicConditionParser,
};

export default DeviceDeleted;
