export enum ActionConstants {
  LOAD_PORTAL = 'app/Portal/LOAD_PORTAL',
  USER_LOGOUT_REQUEST = 'app/Portal/USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS = 'app/Portal/USER_LOGOUT_SUCCESS',
  USER_LOGOUT_ERROR = 'app/Portal/USER_LOGOUT_ERROR',
  FETCH_PORTAL_ERROR = 'app/Portal/FETCH_PORTAL_ERROR',
  INIT_PORTAL_ERROR = 'app/Portal/INIT_PORTAL_ERROR',
  SAVE_PORTAL_REQUEST = 'app/Portal/SAVE_PORTAL_REQUEST',
  ADD_ERROR_NOTIFICATION = 'app/Portal/ADD_ERROR_NOTIFICATION',
  USER_LOGOUT_REQUEST_CONFIRMED = 'app/Portal/USER_LOGOUT_REQUEST_CONFIRMED',
  BUILT_CONFIG_FOR_SAVE_PORTAL_REQUEST = 'app/Portal/BUILT_CONFIG_FOR_SAVE_PORTAL_REQUEST',
  MANAGE_EXTERNAL_SCRIPTS_REQUEST = 'app/Portal/MANAGE_EXTERNAL_SCRIPTS_REQUEST',
  MANAGE_EXTERNAL_SCRIPTS = 'app/Portal/MANAGE_EXTERNAL_SCRIPTS',
  UPDATE_EXTERNAL_SCRIPTS = 'app/Portal/UPDATE_EXTERNAL_SCRIPTS',
  UPDATED_EXTERNAL_SCRIPTS = 'app/Portal/UPDATED_EXTERNAL_SCRIPTS',
  ADD_SUCCESS_NOTIFICATION = 'app/Portal/ADD_SUCCESS_NOTIFICATION',
  ADD_PANE = 'app/Portal/ADD_PANE',
  ADD_WIDGET_TO_PANE_LAYOUT = 'app/Portal/ADD_WIDGET_TO_PANE_LAYOUT',
  ADD_PANE_TAB = 'app/Portal/ADD_PANE_TAB',
  CHANGE_TAB = 'app/Portal/CHANGE_TAB',
  RENAME_TAB = 'app/Portal/RENAME_TAB',
  REMOVE_PANE_TAB = 'app/Portal/REMOVE_PANE_TAB',
  REMOVE_PANE_TAB_REQUEST_CONFIRMED = 'app/Portal/REMOVE_PANE_TAB_REQUEST_CONFIRMED',
  REMOVE_PANE = 'app/Portal/REMOVE_PANE',
  REMOVE_PANE_REQUEST_CONFIRMED = 'app/Portal/REMOVE_PANE_REQUEST_CONFIRMED',
  ADD_WIDGET = 'app/Portal/ADD_WIDGET',
  ADD_WIDGET_REQUEST = 'app/Portal/ADD_WIDGET_REQUEST',
  ADD_WIDGET_REQUEST_CONFIRMED = 'app/Portal/ADD_WIDGET_REQUEST_CONFIRMED',
  ADD_WIDGET_TO_CURRENT_CONTEXT_LAYOUT = 'app/Portal/ADD_WIDGET_TO_CURRENT_CONTEXT_LAYOUT',
  ADD_WIDGET_TO_PANE_GRID_LAYOUT = 'app/Portal/ADD_WIDGET_TO_PANE_GRID_LAYOUT',
  MOVE_WIGET_BETWEEN_PANES = 'app/Portal/MOVE_WIGET_BETWEEN_PANES',
  ADD_WIDGET_TO_HEADER_LAYOUT = 'app/Portal/ADD_WIDGET_TO_HEADER_LAYOUT',
  OPEN_WIDGET_EDITOR_REQUEST = 'app/Portal/OPEN_WIDGET_EDITOR_REQUEST',
  OPEN_WIDGET_EDITOR_REQUEST_CONFIRMED = 'app/Portal/OPEN_WIDGET_EDITOR_REQUEST_CONFIRMED',
  EDIT_WIDGET_REQUEST_CONFIRMED = 'app/Portal/EDIT_WIDGET_REQUEST_CONFIRMED',
  EDIT_WIDGET = 'app/Portal/EDIT_WIDGET',
  UPDATE_WIDGET_BEING_MOVED = 'app/Portal/UPDATE_WIDGET_BEING_MOVED',
  CLOSE_ERROR_MODAL = 'app/Portal/CLOSE_ERROR_MODAL',
  SAVE_PORTAL_SUCCESS = 'app/Portal/SAVE_PORTAL_SUCCESS',
  SAVE_PORTAL_ERROR = 'app/Portal/SAVE_PORTAL_ERROR',
  TOGGLE_EDIT_MODE = 'app/Portal/TOGGLE_EDIT_MODE',
  HIDE_MODAL = 'app/Portal/HIDE_MODAL',
  UPDATE_PANE_SETTINGS = 'app/Portal/UPDATE_PANE_SETTINGS',
  ADD_DATASOURCE_REQUEST_CONFIRMED = 'app/Portal/ADD_DATASOURCE_REQUEST_CONFIRMED',
  ADD_DATASOURCE = 'app/Portal/ADD_DATASOURCE',
  DELETE_DATASOURCE = 'app/Portal/DELETE_DATASOURCE',
  DELETE_DATASOURCE_SUCCESS = 'app/Portal/DELETE_DATASOURCE_SUCCESS',
  EDIT_DATASOURCE_REQUEST = 'app/Portal/EDIT_DATASOURCE_REQUEST',
  EDIT_DATASOURCE = 'app/Portal/EDIT_DATASOURCE',
  DATASOURCES_AND_WIDGETS_LOADED = 'app/Portal/DATASOURCES_AND_WIDGETS_LOADED',
  ALERT_DISMISSED = 'app/Portal/ALERT_DISMISSED',
  ERROR_ALERT_DISMISSED = 'app/Portal/ERROR_ALERT_DISMISSED',
  ERROR_ALERT_CLICKED = 'app/Portal/ERROR_ALERT_CLICKED',
  CREATE_PARSER_ERROR = 'app/Portal/CREATE_PARSER_ERROR',
  EXECUTE_PARSER_ERROR = 'app/Portal/EXECUTE_PARSER_ERROR',
  FIX_PARSER_ERROR = 'app/Portal/FIX_PARSER_ERROR',
  DATASOURCE_DATA_UPDATED = 'app/Portal/DATASOURCE_DATA_UPDATED',
  WIDGET_UPDATED = 'app/Portal/WIDGET_UPDATED',
  VIEW_LATEST_DATA_FOR_DATASOURCE = 'app/Portal/VIEW_LATEST_DATA_FOR_DATASOURCE',
  HANDLE_DATASOURCE_ERROR = 'app/Portal/HANDLE_DATASOURCE_ERROR',
  CREATE_ERROR_MODAL = 'app/Portal/CREATE_ERROR_MODAL',
  TOGGLE_STYLE_MENU = 'app/Portal/TOGGLE_STYLE_MENU',
  TOGGLE_FLYOUT_PANE = 'app/Portal/TOGGLE_FLYOUT_PANE',
  GO_FULL_SCREEN_REQUEST = 'app/Portal/GO_FULL_SCREEN_REQUEST',
  REFRESH_DATASOURCE = 'app/Portal/REFRESH_DATASOURCE',
  HANDLE_SIMULATED_SCREEN_SIZE_CHANGE = 'app/Portal/HANDLE_SIMULATED_SCREEN_SIZE_CHANGE',
  SET_DIRTY_STATUS = 'app/Portal/SET_DIRTY_STATUS',
  SETUP_DEFAULT_HEADER_LAYOUT = 'app/Portal/SETUP_DEFAULT_HEADER_LAYOUT',
  ADD_PAGE = 'app/Portal/ADD_PAGE',
  EDIT_PAGE = 'app/Portal/EDIT_PAGE',
  GET_IDS_TO_REMOVE = 'app/Portal/GET_IDS_TO_REMOVE',
  DELETE_PAGE_AND_CHILDREN = 'app/Portal/DELETE_PAGE_AND_CHILDREN',
  SELECT_PAGE_BY_ID = 'app/Portal/SELECT_PAGE_BY_ID',
  SELECT_PAGE_BY_PATH_REQUEST = 'app/Portal/SELECT_PAGE_BY_PATH_REQUEST',
  SELECT_PAGE_WHILE_IN_DEV_MODE = 'app/Portal/SELECT_PAGE_WHILE_IN_DEV_MODE',
  OPEN_USER_MODAL = 'app/Portal/OPEN_USER_MODAL',
  CLOSE_USER_MODAL = 'app/Portal/CLOSE_USER_MODAL',
  ADD_USER_MODAL = 'app/Portal/ADD_USER_MODAL',
  EDIT_USER_MODAL = 'app/Portal/EDIT_USER_MODAL',
  DELETE_USER_MODAL = 'app/Portal/DELETE_USER_MODAL',
  ADD_DEFAULT_USER_MODAL = 'app/Portal/ADD_DEFAULT_USER_MODAL',
  RESET_DEFAULT_USER_MODAL = 'app/Portal/RESET_DEFAULT_USER_MODAL',
  CHANGE_THEME = 'app/Portal/CHANGE_THEME',
  GRAPH_SUMMARY_MODAL = 'app/Portal/GRAPH_SUMMARY_MODAL',
  CLEAN_UP_STUFF = 'app/Portal/CLEAN_UP_STUFF',
  SET_DEV_CONSOLE_OPEN_STATE = 'app/Portal/SET_DEV_CONSOLE_OPEN_STATE',
  SUBMIT_INTERNAL_RESOURCE = 'app/Portal/SUBMIT_INTERNAL_RESOURCE',
  DELETE_INTERNAL_RESOURCE = 'app/Portal/DELETE_INTERNAL_RESOURCE',
  REORDER_INTERNAL_RESOURCES = 'app/Portal/REORDER_INTERNAL_RESOURCES',
  UPDATED_INTERNAL_RESOURCES = 'app/Portal/UPDATED_INTERNAL_RESOURCES',
  SHOW_BASE_MODAL = 'app/PORTAL/SHOW_BASE_MODAL',
  ENABLE_HOT_RELOAD_REQUEST = 'app/PORTAL/ENABLE_HOT_RELOAD_REQUEST',
  ENABLE_HOT_RELOAD_SUCCESS = 'app/PORTAL/ENABLE_HOT_RELOAD_SUCCESS',
  DISABLE_HOT_RELOAD_REQUEST = 'app/PORTAL/DISABLE_HOT_RELOAD_REQUEST',
  DISABLE_HOT_RELOAD_SUCCESS = 'app/PORTAL/DISABLE_HOT_RELOAD_SUCCESS',
  TOGGLE_QUICK_ADD_OPEN = 'app/PORTAL/TOGGLE_QUICK_ADD_OPEN',
}
