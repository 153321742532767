import { QueryObj } from 'clearblade-js-client';
import { SettingTypes } from 'utils/types';
import { ColumnType } from './columns';

export interface Map<T> {
  [id: string]: T;
  [idx: number]: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyMap = Map<any>;
// 'extends StringKeyed' was a fix for lodash map complaining about IndexSignature
// it didn't understand these hard coded keys were all strings
export interface Entities<T> extends AnyMap {
  isFetching?: boolean; // the loading key should be used over 'isFetching'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loading?: { [key: string]: any };
  hasFetched: boolean;
  entities: Map<T>;
  rows?: string[];
  currentQuery?: QueryObj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectorReturn<T extends (...args: any[]) => any> = ReturnType<ReturnType<T>>;

type GetPropertyType<T, K extends keyof T> = T[K];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PayloadType<T extends (...args: any[]) => any> = GetPropertyType<ReturnType<T>, 'payload'>;

export interface OneEntity<T> {
  isFetching: boolean;
  hasFetched: boolean;
  entity: T | null;
}

export type PrimitiveType = string | number | boolean;

export interface CreateColumn {
  system_key?: string;
  collectionID?: string; // needed for ajax
  collectionName?: string; // needed for ducks
  column_name: string;
  type: ColumnType;
}

export interface DeleteColumn {
  system_key?: string;
  collectionID?: string; // needed for ajax
  collectionName?: string; // needed for ducks
  column_name: string;
}

export const ColumnToInputType: Map<SettingTypes> = {
  [ColumnType.string]: SettingTypes.TEXT_TYPE,
  [ColumnType.int]: SettingTypes.NUMBER_TYPE,
  [ColumnType.float]: SettingTypes.NUMBER_TYPE,
  [ColumnType.bool]: SettingTypes.BOOLEAN_TYPE,
};

export interface PaginatedResponse<T> {
  ITEMS: T[];
  TOTAL_ITEMS: number;
  QUERY: string;
}
