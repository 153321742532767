/*
 *
 * App actions
 *
 */
import { BaseAction } from 'cb-utils/baseAction';
import { AllowedMethods, cbFetch } from 'cb-utils/cbFetch';
import { PlatformInfo, getPlatformInfo } from 'cb-utils/platformInfo';
import { ActionConstants } from './actionConstants';

export interface FetchPlatformConfigRequest extends BaseAction {
  type: ActionConstants.FETCH_PLATFORM_CONFIG_REQUEST;
}

export const createFetchPlatformConfigRequest = (): FetchPlatformConfigRequest => ({
  type: ActionConstants.FETCH_PLATFORM_CONFIG_REQUEST,
});

export interface FetchPlatformConfigSuccess extends BaseAction {
  type: ActionConstants.FETCH_PLATFORM_CONFIG_SUCCESS;
  payload: PlatformInfo;
}

export const createFetchPlatformConfigSuccess = (config: PlatformInfo): FetchPlatformConfigSuccess => ({
  type: ActionConstants.FETCH_PLATFORM_CONFIG_SUCCESS,
  payload: config,
});

export interface FetchPlatformConfigError extends BaseAction {
  type: ActionConstants.FETCH_PLATFORM_CONFIG_ERROR;
  payload: string;
}

export const createFetchPlatformConfigError = (e: string) => ({
  type: ActionConstants.FETCH_PLATFORM_CONFIG_ERROR,
  payload: e,
});

export interface PlatformConfig {
  value: PlatformInfo;
  promise: Promise<PlatformInfo>;
}

export function fetchPlatformConfig(): PlatformConfig {
  const fetcher = cbFetch('/console-api/platformURL', {
    method: AllowedMethods.GET,
  });
  return {
    value: getPlatformInfo(),
    promise: fetcher,
  };
}
