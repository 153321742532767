import ReactDOM from 'react-dom';
import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';

const LOADER_ID = 'cb-js-controlled-loader';

export interface LoaderInterface extends PropertyDescriptor {
  show: (id?: string) => void;
  hide: (id: string) => void;
  waitFor: (prom: Promise<{}>, id: string) => void;
}

const Loader: LoaderInterface = {
  show: (htmlId) => {
    let $elem = $(`#${htmlId}`);
    let forPage = false;
    if ($elem.length === 0) {
      $elem = $('body');
      forPage = true;
    }

    // add loader (only using React so share html/css snipit)
    const $loader = $(`<span id="${htmlId}${LOADER_ID}" />`).insertAfter($elem);
    ReactDOM.render(<LoadingIndicator forPage={forPage} overlay />, $loader[0]); // jquery returns array like and react expects single node
  },

  hide: (htmlId) => {
    const $loader = $(`#${htmlId}${LOADER_ID}`);
    if ($loader.length === 0) {
      console.warn(`Loader.hide(htmlId) did not find a loader on element ${htmlId || 'body'}`);
      return; // not bad enough to throw error, just warn and return
    }

    ReactDOM.unmountComponentAtNode($loader[0]); // to avoid leaking memory
    $loader.remove();
  },

  waitFor: (promise, htmlId) => {
    if (typeof promise.then !== 'function') {
      throw Error('waitFor(promise, htmlId) requires a promise to wait for');
    }
    Loader.show(htmlId);
    // IE doesn't support .finally and I don't want to add a shim https://stackoverflow.com/a/35999141/1870949
    // we do the same thing in .then and .catch because of the order that promise callbacks are executed in our loader unit test
    promise
      .then(() => {
        Loader.hide(htmlId);
      })
      .catch(() => {
        Loader.hide(htmlId);
      });
  },
};

export default Loader;
