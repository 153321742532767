/**
 *
 * TwoFactorAuthValidationPage
 *
 */

import { verifyTwoFactorAuthMethod } from 'cb-utils/services/twoFactorAuth';
import ErrorModal from 'components/ErrorModal';
import { CheckCircleIcon } from 'components/Icons';
import Logo from 'components/Logo';
import ConsoleRoutes from 'containers/Console/routes';
import styleVars from 'cssOverrides/styleVars';
import React, { useEffect, useState } from 'react';
import { useAsyncTask } from 'react-hooks-async';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import messages from './messages';

const SuccessContainer = styled.div`
  background-color: #3aba6f;
  height: 50vh;
  max-height: 300px;
  text-align: center;
  padding: 2rem;
  color: white;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  font-size: 12rem;
  margin-bottom: 2rem;
`;

const VerifySuccessTextContainer = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
`;

const ActionButtonContainer = styled.div`
  text-align: center;
  padding: 2rem;
`;

const TwoFactorAuthValidationPage: React.FC<{}> = () => {
  const [noQuery, setNoQuery] = useState(false);
  const verifyTwoFactorAuthMethodTask = useAsyncTask(verifyTwoFactorAuthMethod);
  useEffect(() => {
    const query = window.location.search;
    if (query) {
      verifyTwoFactorAuthMethodTask.start(query).catch(() => {
        // empty catch so that unit tests run without 'unhandled promise rejection' error
        return;
      });
    } else {
      setNoQuery(true);
    }
    // disabling this for now since I just turned on the eslint rule and don't want to break things by refactoring :(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (noQuery) {
    return (
      <div>
        <FormattedMessage {...messages.noQueryMessage} />
      </div>
    );
  }
  if (verifyTwoFactorAuthMethodTask.pending) {
    return (
      <div>
        <FormattedMessage {...messages.validatingMessage} />
      </div>
    );
  }
  if (verifyTwoFactorAuthMethodTask.error) {
    return (
      <ErrorModal title={<FormattedMessage {...messages.verifyError} />} body={verifyTwoFactorAuthMethodTask.error} />
    );
  }
  if (verifyTwoFactorAuthMethodTask.result) {
    return (
      <div>
        <SuccessContainer>
          <div>
            <StyledCheckCircleIcon />
          </div>
          <VerifySuccessTextContainer>
            <FormattedMessage {...messages.verifySuccess} />
          </VerifySuccessTextContainer>
        </SuccessContainer>
        <ActionButtonContainer>
          <div>
            <Logo url={styleVars.brandLogoUrl} backgroundPosition="center" />
          </div>
          <a href={ConsoleRoutes.SYSTEM_OVERVIEW}>
            <FormattedMessage {...messages.actionButtonMessage} values={{ productName: styleVars.productName }} />
          </a>
        </ActionButtonContainer>
      </div>
    );
  }
  return null;
};

export default TwoFactorAuthValidationPage;
