/*
 *
 * RuleBuilder actions
 *
 */

import { cb } from 'cb-utils/cbLib';

import { TriggerPayload } from 'cb-utils/console-entity-models';
import { ServicePayload } from 'clearblade-js-client';

export function createServiceForRule(name: string, body: ServicePayload) {
  return new Promise((resolve, reject) => {
    cb.Code().create(name, body, (err, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
}

export function updateServiceForRule(name: string, body: ServicePayload) {
  return new Promise((resolve, reject) => {
    cb.Code().update(name, body, (err, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
}

export function deleteServiceForRule(name: string) {
  return new Promise((resolve, reject) => {
    cb.Code().delete(name, (err, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
}

export function createTriggerForRule(name: string, body: TriggerPayload) {
  return new Promise((resolve, reject) => {
    cb.Triggers().create(name, body, (err, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
}

export function updateTriggerForRule(name: string, body: TriggerPayload) {
  return new Promise((resolve, reject) => {
    cb.Triggers().update(name, body, (err, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
}

export function deleteTriggerForRule(name: string) {
  return new Promise((resolve, reject) => {
    cb.Triggers().delete(name, (err, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
}
