import { createGlobalStyle } from 'styled-components';
import styleVars from './styleVars';
import shadeColor, { hexToRGB } from 'cb-utils/shadeColor';

export const AntDGlobalStyle = createGlobalStyle`
  /* to make it slightly more specific and override defaults */
  #app, .modal {

    /* tree */
    .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
      border-color: ${styleVars.darkPrimary};
    }
    .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
      border-color: ${styleVars.darkPrimary};
    }
    .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper:before,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper:before {
      background: ${styleVars.darkPrimary};
    }
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
      border-color: ${styleVars.darkPrimary};
    }
    .ant-tree-checkbox-checked:after {
      border: 1px solid ${styleVars.darkPrimary};
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background-color: ${styleVars.darkPrimary};
      border-color: ${styleVars.darkPrimary};
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
      background-color: ${styleVars.darkPrimary};
    }
    .ant-tree li.drag-over > span[draggable] {
      background-color: ${styleVars.darkPrimary};
    }
    .ant-tree li.drag-over-gap-top > span[draggable] {
      border-top-color: ${styleVars.darkPrimary};
    }
    .ant-tree li.drag-over-gap-bottom > span[draggable] {
      border-bottom-color: ${styleVars.darkPrimary};
    }
    .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
    .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
      color: ${styleVars.darkPrimary};
    }
    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: ${styleVars.lightPrimary};
    }
    .ant-tree li .ant-tree-node-content-wrapper:hover {
      background-color: ${shadeColor(styleVars.primary, 0.8)};
    }
  }

  /* date picker */
  /* Would like to move this to CbDatePicker like Pagination is, but the way the overlay is not a child of the wrapper made it more difficult */
  .ant-calendar-picker-container {
    .ant-time-picker-input:hover {
      border-color: ${shadeColor(styleVars.primary, 0.8)};
    }
    .ant-time-picker-input:focus {
      border-color: ${styleVars.primary};
      -webkit-box-shadow: 0 0 0 2px ${hexToRGB(styleVars.primary, 0.2)};
              box-shadow: 0 0 0 2px ${hexToRGB(styleVars.primary, 0.2)};
    }
    .ant-calendar-date:hover, .ant-calendar-time-picker-select li:hover {
      background: ${styleVars.lightPrimary};
    }
    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: ${styleVars.darkPrimary};
    }
    .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: ${styleVars.primary};
    }
    .ant-calendar-header a:hover {
      color: ${styleVars.primary};
    }
    .ant-calendar-date:active {
      background: ${styleVars.primary};
    }
    .ant-calendar-today .ant-calendar-date {
      border-color: ${styleVars.darkPrimary};
      color: ${styleVars.darkPrimary};
    }
    .ant-calendar-selected-date .ant-calendar-date,
    .ant-calendar-selected-start-date .ant-calendar-date,
    .ant-calendar-selected-end-date .ant-calendar-date {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar-selected-date .ant-calendar-date:hover,
    .ant-calendar-selected-start-date .ant-calendar-date:hover,
    .ant-calendar-selected-end-date .ant-calendar-date:hover {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar .ant-calendar-ok-btn {
      background-color: ${styleVars.darkPrimary};
      border-color: ${styleVars.darkPrimary};
    }
    .ant-calendar .ant-calendar-ok-btn:hover,
    .ant-calendar .ant-calendar-ok-btn:focus {
      background-color: ${styleVars.primary};
      border-color: ${styleVars.primary};
    }
    .ant-calendar .ant-calendar-ok-btn:active,
    .ant-calendar .ant-calendar-ok-btn.active {
      background-color: ${shadeColor(styleVars.primary, -0.3)};
      border-color: ${shadeColor(styleVars.primary, -0.3)};
    }
    .ant-calendar-range .ant-calendar-input:hover,
    .ant-calendar-range .ant-calendar-time-picker-input:hover {
      border-color: ${styleVars.primary};
    }
    .ant-calendar-range .ant-calendar-input:focus,
    .ant-calendar-range .ant-calendar-time-picker-input:focus {
      border-color: ${styleVars.primary};
    }
    .ant-calendar-month-panel-header a:hover {
      color: ${styleVars.primary};
    }
    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar-year-panel-header a:hover {
      color: ${styleVars.primary};
    }
    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar-decade-panel-header a:hover {
      color: ${styleVars.primary};
    }
    .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
      background: ${styleVars.darkPrimary};
    }
    .ant-calendar-selected-date .ant-calendar-date, 
    .ant-calendar-selected-start-date .ant-calendar-date, 
    .ant-calendar-selected-end-date .ant-calendar-date {
      color: #fff;
    }
    .ant-calendar-selected-day .ant-calendar-date {
      background: ${shadeColor(styleVars.primary, 0.5)};
    }
  }
`;
