/*
 *
 * NewEntityModal reducer
 *
 */

import produce from 'immer';
import { ActionTypes } from './actions';
import { ActionConstants } from './actionConstants';

export enum ImportTypes {
  FROM_TEMPLATE = 'template',
  ADVANCED = 'advanced',
}

export enum NewEntityTabs {
  FROM_SCRATCH,
  FROM_TEMPLATE,
  FROM_FILE,
  FROM_ADVANCED,
}

export interface State {
  currentTab: NewEntityTabs;
  currentPackage: string; // represents the id for the IPM package the user is viewing
}

export const initialState: State = {
  currentTab: NewEntityTabs.FROM_TEMPLATE,
  currentPackage: '',
};

const newEntityModalReducer = produce((state: State, action: ActionTypes) => {
  switch (action.type) {
    case ActionConstants.CHANGE_TAB:
      state.currentTab = action.payload.tab;
      return;
    case ActionConstants.SELECT_PACKAGE:
      state.currentPackage = action.payload.id;
      return;
    case ActionConstants.ON_DISMISS_MODAL:
      // todo: test this
      return initialState;
    default:
      return state;
  }
}, initialState);

export default newEntityModalReducer;
