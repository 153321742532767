import { createGlobalStyle } from 'styled-components';
export const HIDE_ELEM_ID = 'hide-element';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }
  
  .cb-notification-container {
    z-index: 100000 !important;
    font-size: .8rem !important;
  }
  
  .cb-notification-container.success .notification-bar-action {
    color: green !important;
  }
  
  .popover {
    z-index: 106000 !important;
  }
  
  .modal-body {
    word-wrap: break-word;
  }
  
  .btn-secondary, .btn-secondary.disabled:hover {
    color: #292b2c !important;
    background-color: #fff !important;
    border-color: #ccc !important;
  }
  
  .btn-secondary:hover {
    color: #292b2c !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}

  .c-pointer {
    cursor: pointer;
  }

  #${HIDE_ELEM_ID} {
    display: none!important
  }

  .popover {
    max-width: 50vw !important;
  }

  @font-face {
    font-family: 'anticon';
    /* fragile, but hopefully antd will fix so their icons work without extra hacks */
    src: url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff');
  }

  body > .rbt-menu {
    /* if typeahead is attached to body make it show over top of settings modal 
    ({bodyContainer: true} is only used in form widget right now) */
    z-index: 2000;
  }

  body.cb-is-dragging .my-custom-modal-backdrop, body.cb-is-dragging .user-modal {
    pointer-events: none; // turn off pointer-events for modal when dragging widgets; this allows us to drag elements from within a grid modal to a grid outside the modal
  }

  .react-draggable-dragging {
    pointer-events: none;
  }
`;
