import { setDevTokenToStorage } from 'cb-utils/devToken';
import ConsoleRoutes from 'containers/Console/routes';
import React from 'react';
import { Redirect, useLocation } from 'react-router';

export const OIDCTokenPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const devToken = searchParams.get('dev_token');
  // might need some sort of message to tell user dev token wasn't found and to try again?
  if (!devToken) {
    return <Redirect to={'/'} />;
  }

  setDevTokenToStorage(devToken);
  return <Redirect to={ConsoleRoutes.SYSTEM_OVERVIEW} />;
};
