import React from 'react';
import { getTextForSummary, UpdateItemConditionOptions, ItemConditionParser } from './utils';
import { BasicRequestParser } from '../constants';
import { DataOptions } from 'cb-utils/console-entity-models';
import { TriggerSourceDefinition } from '../types';

const ItemUpdated: TriggerSourceDefinition = {
  name: DataOptions.ItemUpdated,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSummary: (defKeyObject) => <div>{getTextForSummary(defKeyObject, 'updated', 'in')}</div>,
  requestParser: BasicRequestParser,
  conditionOptions: UpdateItemConditionOptions,
  getConditionParser: ItemConditionParser,
};

export default ItemUpdated;
