import queryString from 'query-string';
import { localStorageLocation } from './constants';
import { PortalAuthConfig, PortalLocalStorageInfo } from './types';

export function addAuthInfo(portalsMeta: PortalLocalStorageInfo, config: AuthConfig) {
  const existingData = portalsMeta[config.systemKey] && {
    ...portalsMeta[config.systemKey][config.name],
  };
  return {
    ...portalsMeta,
    [config.systemKey]: {
      ...portalsMeta[config.systemKey],
      [config.name]: {
        ...existingData,
        ...config,
      },
    },
  };
}

type AuthConfig = { name: string; systemKey: string } & Partial<PortalAuthConfig>;

export function setAuthLocalStorage(config: AuthConfig) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const portalsMeta = JSON.parse(localStorage.getItem(localStorageLocation)) || {};
  const newMeta = addAuthInfo(portalsMeta, config);

  localStorage.setItem(localStorageLocation, JSON.stringify(newMeta));
}

export function getAuthLocalStorage() {
  try {
    const { name, systemKey } = queryString.parse(window.location.search);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const portalsMeta: PortalLocalStorageInfo = JSON.parse(localStorage.getItem(localStorageLocation));
    const authInfo = portalsMeta[systemKey as string][name as string];

    authInfo.refresh_token; // force this to error out if it's not an object, so it will return fallback
    // will happen if loaded other portals on this system, but first open of this portal

    return authInfo;
  } catch {
    return {} as Partial<AuthConfig>;
    // could be first load and not saved
  }
}
