// these are constants that are used in the service that is created by the ruleBuilder
// their initial use is for variable names, function names, etc.

export const REQ_OBJECT = 'reqObject';
export const CALL_ALERT_PROVIDER_FUNCTION_NAME = 'callAlertProvider';
export const QUOTE_TYPE = '"';

export enum NATIVE_LIBRARIES {
  CLEARBLADE_LIBRARY = 'clearblade',
  LOG_LIBRARY = 'log',
  HTTP_LIBRARY = 'http',
}

export function createAlertCaller(idx: number) {
  return `${CALL_ALERT_PROVIDER_FUNCTION_NAME}${idx}`;
}
