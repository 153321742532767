import { ConfiguredStore } from 'configureStore';
import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { ReactReduxContext, ReactReduxContextValue } from 'react-redux';
import { AnyAction, Reducer } from 'redux';
import getInjectors from './reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }: { key: string; reducer: Reducer<{}> }) =>
  (WrappedComponent: React.ComponentType) => {
    class ReducerInjector extends React.Component {
      static WrappedComponent = WrappedComponent;

      static contextType = ReactReduxContext;

      static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      constructor(props: any, context: ReactReduxContextValue<any, AnyAction>) {
        super(props, context);

        getInjectors(context.store as ConfiguredStore<{}>).injectReducer(key, reducer);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };

const useInjectReducer = ({ key, reducer }: { key: string; reducer: Reducer<{}> }) => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    getInjectors(context.store as ConfiguredStore<{}>).injectReducer(key, reducer);
    // disabling this for now since I just turned on the eslint rule and don't want to break things by refactoring :(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useInjectReducer };
