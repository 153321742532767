import { ImportTypes } from '../../NewEntityModal/reducer';

export const newRoot = '/new';
export const ROUTE_PARAMS = {
  type: 'type',
  id: 'id',
};
export const importTypeRoute = `/:${ROUTE_PARAMS.type}/:${ROUTE_PARAMS.id}`;

const createPath = (type: ImportTypes, id: string) =>
  importTypeRoute.replace(`:${ROUTE_PARAMS.type}`, type).replace(`:${ROUTE_PARAMS.id}`, id);
export const createTemplatePath = (id: string) => createPath(ImportTypes.FROM_TEMPLATE, id);
// note: we encode the ID because it's a repo URL
export const createAdvancedPath = (id: string) => createPath(ImportTypes.ADVANCED, encodeURIComponent(id));
