/**
 *
 * WithLoadable
 *
 */

import React, { lazy } from 'react';

function withLoadable<Props = {}>(
  factory: Parameters<typeof lazy>[0],
): React.LazyExoticComponent<React.ComponentType<Props>> {
  return lazy(factory);
}

export default withLoadable;
