import moment from 'moment';

export function friendlyTime(time: string | number, includeYear?: boolean): string {
  const localDate = new Date(time);
  return moment(localDate).isSame(new Date(), 'day')
    ? `Today, ${format(localDate, 'h:mm:ss a')}`
    : includeYear
    ? format(localDate, 'MMM DD YYYY, hh:mm a')
    : format(localDate, 'MMM D, hh:mm a');
}

export function format(date: string | Date, formatStr = 'MM/DD/YY HH:mm:ss') {
  return moment(date).format(formatStr);
}

export function isFuture(date: string | Date): boolean {
  return moment(date).isAfter();
}

export function distanceInWordsToNow(date: string | Date): string {
  return moment(date).fromNow();
}

export function validIsoDate(date: string): boolean {
  return moment(date, moment.ISO_8601).isValid();
}
