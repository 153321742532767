import { FetchingData } from 'containers/Console/state';
import { AnyMap, DeploymentModel, Map } from '.';

export interface PopularTag {
  count: number;
  name: string;
}
export interface FetchMostPopularTagsResponse {
  results: PopularTag[];
  success: boolean;
}

export interface FetchPackagesResponse {
  results: {
    CURRENTPAGE: number;
    DATA: IpmPackage[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FILTERS: any[];
    NEXTPAGEURL: string;
    PREVPAGEURL: string;
    SEARCHTERMS: string;
    SORTINGTYPE: string;
    QUERYMATCHCOUNT: number;
    TOTAL: number;
  };
  success: boolean;
}

interface PackageActivity {
  message: string;
  'send-date': number;
  'user-id': string;
}

interface PackageAuthor {
  email: string;
  first_name: string;
  last_name: string;
  name: string;
}

export type IPackageAuthorTypes = PackageAuthor | string;

interface PackageStats {
  lastweek: string;
  overall: string;
}

export interface GetPackageDetailsResponse {
  results: PackageDetails;
  success: boolean;
}

export interface PackageDetails {
  activityFeed: PackageActivity[];
  author: IPackageAuthorTypes;
  clouds: string[];
  comments: string;
  createdTime: string;
  description: string;
  domains: string[];
  gateways: string[];
  github: string;
  image_url: string;
  keywords: string[];
  license: string;
  name: string;
  packageId: string;
  private: boolean;
  prog_lang: string[];
  protocols: string[];
  readme: string;
  stats: PackageStats;
  things: string[];
  updatedTime: string;
  version: string;
}

export interface IpmPackage {
  content_asset_types: string;
  description: string;
  image_path: string;
  item_id: string;
  name: string;
  total_installs_count: number;
  updated_time: string;
}

export const emptyPackageSlice: MostPopularPackagesSlice = {
  data: {
    results: {
      DATA: [],
      CURRENTPAGE: 1,
      FILTERS: [],
      NEXTPAGEURL: '',
      PREVPAGEURL: '',
      SEARCHTERMS: '',
      SORTINGTYPE: '',
      QUERYMATCHCOUNT: 0,
      TOTAL: 0,
    },
    success: false,
  },
  loading: {},
  isFetching: false,
  hasFetched: false,
};

export interface MostPopularPackagesSlice extends FetchingData {
  data: FetchPackagesResponse;
}

interface MostPopularTagsSlice extends FetchingData {
  data: FetchMostPopularTagsResponse;
}

export interface PackageWithDetailsInfo extends FetchingData {
  data: PackageDetails;
}

export interface PackageWithStructureInfo extends FetchingData {
  data: GetPackageStructureResponse;
}

export interface PackageAssetNode {
  sha: string;
  path: string;
  mode: string;
  type: NodeTypes;
  size?: number; // size is only used when type === NodeTypes.BLOB
  url: string;
}

export enum NodeTypes {
  BLOB = 'blob',
  TREE = 'tree',
}

export interface GetPackageStructureResponse {
  sha: string;
  tree: PackageAssetNode[];
}

export const getRepoInfoFromGitHubUrl = (url: string) => {
  const urlContent = getUrlContent(url);
  return {
    userName: getUserName(urlContent),
    repoName: getRepoName(urlContent),
    branch: getBranchName(urlContent),
  };
};

const getUrlContent = (url: string) => {
  return url.split('https://github.com/')[1];
};

const getUserName = (url: string) => {
  const urlContents = url.split('/');
  return urlContents[0];
};

const getRepoName = (url: string) => {
  const urlContents = url.split('/');
  return urlContents[1];
};

const getBranchName = (url: string) => {
  const urlContents = url.split('/');
  if (urlContents.length > 3 && urlContents[2] === 'tree') {
    return urlContents[3];
  }
  return 'master';
};

export interface FileNode {
  title: string;
  key: string;
  children?: FileNode[];
  isLeaf?: boolean;
}

interface ConvertNodesResponse {
  totalDescendents: number;
  structure: FileNode[];
}

export const getListOfAssetKeys = (n: PackageAssetNode[]): string[] => n.map((v) => v.path);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const convertNodes = (n: PackageAssetNode[] = [], parentPath: string = null): ConvertNodesResponse => {
  const rtn: ConvertNodesResponse = {
    totalDescendents: 0,
    structure: [],
  };
  for (let i = 0, len = n.length; i < len; i += 1) {
    if (parentPath && n[i].path.indexOf(parentPath) === -1) {
      break;
    }

    const fullpath = n[i].path;
    const splitPath = fullpath.split('/');
    const fn: FileNode = {
      title: splitPath[splitPath.length - 1],
      key: fullpath,
    };

    if (n[i].type === NodeTypes.TREE) {
      const childConversion = convertNodes(n.slice(i + 1), fullpath);
      fn.children = childConversion.structure;
      rtn.totalDescendents += childConversion.totalDescendents;
      i += childConversion.totalDescendents;
    }

    rtn.structure.push(fn);
  }
  rtn.totalDescendents += rtn.structure.length;
  return rtn;
};

export const convertGithubStructureToTreeView = (s: GetPackageStructureResponse) => convertNodes(s.tree).structure;

// as of now we want to disallow users from unchecking any of the files in the 'code' directory
// if they want to remove or select a specific service/library they need to select the parent folder
// we do this because the backend requires that we send both myService.js and myService.json when exporting/importing a service (same goes for libraries)
export const getDisabledFiles = (s: GetPackageStructureResponse) =>
  s.tree.filter((d) => d.path.startsWith(CODE_DIRECTORY) && d.type === NodeTypes.BLOB).map((d) => d.path);

export const getKeysForTree = (n: FileNode): string[] => {
  const rtn: string[] = [];
  rtn.push(n.key);
  if (n.children) {
    n.children.forEach((c) => rtn.push(...getKeysForTree(c)));
  }
  return rtn;
};

export interface IpmSlice {
  mostPopularPackages: MostPopularPackagesSlice;
  mostPopularTags: MostPopularTagsSlice;
  searchPackages: Map<MostPopularPackagesSlice>;
  packagesWithDetails: Map<PackageWithDetailsInfo>;
  packagesWithStructure: Map<PackageWithStructureInfo>;
  packagesWithAssetSelections: Map<string[]>;
}

export enum IpmAssetTypes {
  SYSTEM = 'System',
  COLLECTIONS = 'Collections',
  CODE_SERVICES = 'Code Services',
  CODE_LIBRARIES = 'Code Libraries',
  TIMERS = 'Timers',
  TRIGGERS = 'Triggers',
  USERS = 'Users',
  DEVICES = 'Devices',
  ROLES = 'Roles',
  PORTALS = 'Portals',
  PLUGINS = 'Plugins',
  EDGES = 'Edges',
  ADAPTERS = 'Adapters',
}

export enum DocsAssetTypes {
  SYSTEM = 'System',
  CODE_SERVICES = 'Code Services',
  CODE_LIBRARIES = 'Code Libraries',
  SERVICE_CACHE = 'Shared Caches',
  PORTALS = 'Portals',
  COLLECTIONS = 'Collections',
  TRIGGERS = 'Triggers',
  ADAPTERS = 'Adapters',
  ROLES = 'Roles',
  TIMERS = 'Timers',
  PLUGINS = 'Plugins',
  USERS = 'Users',
  EDGES = 'Edges',
  DEVICES = 'Devices',
  MESSAGES = 'Messaging',
  DEPLOYMENTS = 'Deployments',
  EXTERNAL_DATABASES = 'External Databases',
  FILES = 'File Management',
}

export const getAllAssetTypes = (except: IpmAssetTypes[] = []): IpmAssetTypes[] =>
  Object.values(IpmAssetTypes).filter((a) => except.indexOf(a as IpmAssetTypes) === -1);

export const CODE_DIRECTORY = 'code';
const SERVICES_DIRECTORY = `${CODE_DIRECTORY}/services`;
const LIBRARIES_DIRECTORY = `${CODE_DIRECTORY}/libraries`;
export const AssetTypeToDirectoryMap: AnyMap = {
  [IpmAssetTypes.CODE_SERVICES]: SERVICES_DIRECTORY,
  [IpmAssetTypes.CODE_LIBRARIES]: LIBRARIES_DIRECTORY,
  [IpmAssetTypes.PORTALS]: 'portals',
  [IpmAssetTypes.COLLECTIONS]: 'data',
  [IpmAssetTypes.ADAPTERS]: 'adapters',
  [IpmAssetTypes.ROLES]: 'roles',
  [IpmAssetTypes.TIMERS]: 'timers',
  [IpmAssetTypes.TRIGGERS]: 'triggers',
  // deployments?
  [IpmAssetTypes.PLUGINS]: 'plugins',
  [IpmAssetTypes.USERS]: 'users',
  [IpmAssetTypes.EDGES]: 'edges',
  [IpmAssetTypes.DEVICES]: 'devices',
};

export interface ImportIntoExistingSystemRequest extends ImportFromIpmBaseRequest {
  system_key: string;
  system_secret: string;
}

export interface ImportFromIpmBaseRequest {
  repoURL: string;
  developerEmail: string;
  importFullSystem: boolean;
  importIntoExistingSystem: boolean;
  listOfFilesToImport: string[];
  systemName: string;
}

export interface ImportFromCbLibResponse {
  importIntoExistingSystem?: boolean;
  auth: boolean;
  deployments: DeploymentModel[];
  description: string;
  messaging_url: string;
  name: string;
  platform_url: string;
  systemKey: string; // this is the newly generated systemKey
  systemSecret: string; // this is the newly generated systemSecret
  system_key: string; // not sure what this is for...assuming it's created by cblib. perhaps it's the key of the template system?
  system_secret: string; // ^ samesies
}

export const formatListOfAssetsForImportOrExport = (structure: PackageAssetNode[], selected: string[]) => {
  return selected.filter((s) => {
    for (let i = 0, len = structure.length; i < len; i += 1) {
      if (structure[i].path === s && structure[i].type === NodeTypes.TREE) {
        return false;
      }
    }
    return true;
  });
};

export const formatAssetName = (repoName: string, branchName: string, assetPath: string) =>
  `${repoName}-${branchName}/${assetPath}`;

export const createGithubZipUrl = (githubRoot: string, branchName: string) => `${githubRoot}/archive/${branchName}.zip`;

// list of assets that shouldn't be displayed in the UI (psst, it's because they aren't imported into ClearBlade)
const HIDDEN_ASSET_BLACKLIST = ['.gitignore', 'Readme.md', 'README.md', 'package.json'];
export const filterHiddenAssets = (list: PackageAssetNode[]) =>
  list.filter((a) => HIDDEN_ASSET_BLACKLIST.indexOf(a.path) === -1);
// list of assets that shouldn't be unchecked in the UI
export const DISABLED_ASSET_BLACKLIST = ['system.json'];

const createNode = (path: string, type: NodeTypes): PackageAssetNode => ({
  path,
  type,
  sha: '',
  mode: '',
  url: '',
});

export const convertPathListToPackageList = (list: string[]): PackageAssetNode[] => {
  const rtn: PackageAssetNode[] = [];
  const createdDirectories: string[] = [];
  list.forEach((p) => {
    if (p.indexOf('/')) {
      // we got a folder or a file within a folder
      const split = p.split('/').filter((_v, i, arr) => i < arr.length - 1);
      for (let i = 0, len = split.length; i < len; i += 1) {
        const end = i + 1;
        const directoryToCheck = split.slice(0, end).join('/');
        if (createdDirectories.indexOf(directoryToCheck) === -1) {
          rtn.push(createNode(directoryToCheck, NodeTypes.TREE));
          createdDirectories.push(directoryToCheck);
        }
      }
    }
    rtn.push(createNode(p, NodeTypes.BLOB));
  });
  return rtn;
};

export const PUBLISH_PACKAGE_TO_IPM_URL =
  'https://ipm.clearblade.com/portal/?systemKey=AAAAAAAAAAAAAAAAAAAAAJ55Gat-2m1A4tBaDIkip1xGMgStbd803vkm9Czquw==&systemSecret=AAAAAAAAAAAAAAAAAAAAAF5AzcvPIP5pafDqgFXa9fZvMWiKiB8aZcfMRnU=&name=CommonsPortal&allowAnon=true&githubUrl=https://github.com/sh3nan1gans/splunk-service&targetPage=PublishPackage#/Auth';
export const BROWSE_PACKAGES_ON_IPM_URL =
  'https://ipm.clearblade.com/portal/?systemKey=AAAAAAAAAAAAAAAAAAAAAJ55Gat-2m1A4tBaDIkip1xGMgStbd803vkm9Czquw==&systemSecret=AAAAAAAAAAAAAAAAAAAAAF5AzcvPIP5pafDqgFXa9fZvMWiKiB8aZcfMRnU=&name=CommonsPortal&allowAnon=true';

const codeUrlParam = '?code=';
export const urlContainsCodeParam = () => location.href.indexOf(codeUrlParam) > -1;
export const getCodeFromUrl = () => location.search.split(codeUrlParam)[1];

export const formatAssetTypes = (types: IpmAssetTypes[] = []) => types.map((name) => ({ name }));

export const filterUsersFromAssets = (list: PackageAssetNode[]) =>
  list.filter((v) => {
    const regex = /(^(users\/schema\.json$))|((^((?!(users)).*)$))/gm;
    return regex.test(v.path);
  });
