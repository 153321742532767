import { AndOr } from 'react-querybuilder';
import { ConditionOptions } from 'cb-utils/ruleBuilder/TriggerSources/types';
import { RuleField, RBRule, RBCondition } from '../ruleBuilder/utils';

export interface Condition {
  id: string;
  rules: Rule[];
  combinator: AndOr;
}

export const createBlankCondition = (): Condition => ({
  id: 'fakeId',
  rules: [],
  combinator: 'and',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createEqualToCondition = (field: string, value: any): Condition => {
  const cond = createBlankCondition();
  return {
    ...cond,
    rules: [
      {
        field,
        id: 'equalToId',
        operator: '==',
        value,
      },
    ],
  };
};

export interface Rule {
  field: string;
  id: string;
  operator: string;
  value: string;
}

export const isRule = (model: Rule | Condition): model is Rule => {
  return (model as Rule).field !== undefined;
};

export const isCondition = (model: Rule | Condition): model is Condition => {
  return (model as Condition).rules !== undefined;
};

export const isRBCondition = (cond: RBCondition | RBRule | Condition | Rule): cond is RBCondition => {
  return (cond as RBCondition).rules !== undefined;
};

export const isRBRule = (rule: RBCondition | RBRule | Condition | Rule): rule is RBRule => {
  return (rule as RBRule).field.base !== undefined;
};

// Fetches the ConditionOption in variables that corrosponds with value.
export const getFieldDefinitionForRule = (value: string, options: ConditionOptions) => {
  let parts: string[];
  let option = options.find((o) => {
    if (fieldDefCheck(value, o.value)) {
      parts = value.split(o.value);
      return true;
    } else if (fieldDefCheck(value, o.name)) {
      parts = value.split(o.name);
      return true;
    }
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  option = option ? option : { name: value, value, type: undefined };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const remainder = parts && parts[1] ? `${parts.slice(1).join('.')}` : '';
  const field: RuleField = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    base: option,
    remainder,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value: `${option.value}${remainder}`,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    name: `${option.name}${remainder}`,
  };
  return field;
};

// Checks necessary cases for searching for a ConditionOption
const fieldDefCheck = (value: string, optionVal: string) => {
  const endOfOption = value.charAt(optionVal.length);
  return value.startsWith(optionVal) && (endOfOption === '' || endOfOption === '.' || endOfOption === '[');
};

export const getSummary = (rule: Rule | RBRule, variables: ConditionOptions): string => {
  if (isRBRule(rule)) {
    let value: string;
    if (rule.value.isArithmetic) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value = rule.value.operands
        .map((v) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (v.operator === '') {
            return v.option.name;
          }
          return `${v.option.name} ${v.operator}`;
        })
        .join(' ');
    } else {
      value = rule.value.content;
    }
    return `${rule.field.base.name}${rule.field.remainder} ${rule.operator} ${value}`;
  } else {
    const parts = rule.value.split(' ');
    return parts.map((v) => getFieldDefinitionForRule(v, variables).name).join(' ');
  }
};

export const AND_OPERATOR = 'and';
export const OR_OPERATOR = 'or';

export const combinatorMap = {
  [AND_OPERATOR]: '&&',
  [OR_OPERATOR]: '||',
};
