/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';
import { DEFAULT_LOCALE } from '../App/constants';
import { ActionConstants } from './actionConstants';
import { ActionTypes } from './actions';
import { State } from './state';

const initialState = {
  locale: DEFAULT_LOCALE,
};

const languageProviderReducer = produce((state: State, action: ActionTypes) => {
  switch (action.type) {
    case ActionConstants.CHANGE_LOCALE:
      state.locale = action.locale;
      return;
    default:
      return state;
  }
}, initialState);

export default languageProviderReducer;
