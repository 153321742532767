import { history } from 'appHistory';
import ConsoleRoutes, { IConsoleRoutes } from 'containers/Console/routes';
import { newRoot } from 'containers/Console/routes/importModal';
import { matchPath } from 'react-router-dom';
import { SystemRouteParams } from './systemRouteProps';

history.listen(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cachedParams = null;
});

interface PathParams {
  path: keyof IConsoleRoutes;
  systemKey?: string;
  edgeName?: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  matchParams?: { systemKey?: string; edgeName?: string; [k: string]: string };
}

let cachedParams: PathParams;

export const getPathParams = (pathName?: string): PathParams => {
  if (cachedParams && !pathName) {
    return cachedParams;
  }

  let path: keyof IConsoleRoutes;
  let systemKey;

  const currentPathName = pathName || location.pathname;
  // TODO: remove and actually use react route edgeName param
  const sys = currentPathName.split('/')[3];
  let edgeName = sys && sys.split(':')[1];
  let matchParams: Partial<SystemRouteParams> = {};
  for (const key in ConsoleRoutes) {
    if (Object.prototype.hasOwnProperty.call(ConsoleRoutes, key)) {
      const matchInfo = getMatchInfo(currentPathName, key as keyof IConsoleRoutes);
      if (matchInfo) {
        matchParams = matchInfo.params;
        if (matchInfo.params && matchInfo.params.edgeName) {
          edgeName = matchInfo.params.edgeName.substring(1); // remove ':' in :myEdge - couldn't find right regex to remove in route
        }
        if (
          matchInfo.isExact ||
          (currentPathName.includes(newRoot) && isExactMatchWithNewRoute(currentPathName, key as keyof IConsoleRoutes))
        ) {
          path = key as keyof IConsoleRoutes;
          systemKey = matchInfo.params.systemKey;
          break;
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cachedParams = { path, systemKey, edgeName, matchParams };
  return cachedParams;
};

export const getMatchInfo = (pathName: string, key: keyof IConsoleRoutes) => {
  return matchPath<SystemRouteParams>(pathName, {
    path: ConsoleRoutes[key],
  });
};

const isExactMatchWithNewRoute = (pathName: string, key: keyof IConsoleRoutes): boolean => {
  const info = getMatchInfo(removeNewRouteFromPath(pathName), key);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return info && info.isExact;
};

/**
 * @deprecated - should use getSystemKeySafe instead
 */
export const getSystemKey = () => {
  return getPathParams().systemKey;
};

/**
 * supercedes getSystemKey because getSystemKey returns string | undefined which is annoying to deal with.
 * Instead, getSystemKeySafe throws an error if no system key is found in the path which means the developer is trying to use it outside of a system route
 */
export const getSystemKeySafe = () => {
  const maybeSystemKey = getPathParams().systemKey;
  if (!maybeSystemKey) {
    throw new Error('No system key found in path');
  }

  return maybeSystemKey;
};

export const getPathToNewModalRoute = () => `${ConsoleRoutes[getPathParams().path]}${newRoot}`;

export const removeNewRouteFromPath = (path: string) => path.slice(0, path.indexOf(newRoot));
