import { RGLBreakpointsExt } from './types';

/*
 *
 * Portal constants
 *
 */

export const STORE_KEY = 'portal';

export const USER_LOGIN_MODAL = 'USER_LOGIN_MODAL';
export const ERROR_MODAL = 'ERROR_MODAL';
export const WARNING_MODAL = 'WARNING_MODAL';
export const PANE_SETTINGS_MODAL = 'PANE_SETTINGS_MODAL';
export const TEXT_WIDGET_COMPONENT = 'TEXT_WIDGET_COMPONENT';
export const HTML_WIDGET_COMPONENT = 'HTML_WIDGET_COMPONENT';
export const BUTTON_WIDGET_COMPONENT = 'BUTTON_WIDGET_COMPONENT';
export const DATASOURCE_SETTINGS_MODAL = 'DATASOURCE_SETTINGS_MODAL';
export const TOPIC_DATASOURCE = 'CB_MESSAGE_TOPIC';
export const EXPECTED_FORMAT = 'expected_format';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const DATASOURCE_AGGREGATOR_TYPE = 'DATASOURCE_AGGREGATOR';
export const LOCAL_DATASOURCE_PLUGIN = 'LOCAL_DATASOURCE_PLUGIN';
export const LATEST_DATA_MODAL = 'LATEST_DATA_MODAL';
export const DATA_SETTING_TYPE = 'DATA_SETTING_TYPE';
export const MULTI_OBJECT_SETTING_TYPE = 'MULTI_OBJECT_SETTING_TYPE';
export const BAR_CHART_WIDGET_COMPONENT = 'BAR_CHART_WIDGET_COMPONENT';
export const LINE_CHART_WIDGET_COMPONENT = 'LINE_CHART_WIDGET_COMPONENT';
export const PIE_CHART_WIDGET_COMPONENT = 'PIE_CHART_WIDGET_COMPONENT';
export const GOOGLE_MAP_WIDGET_COMPONENT = 'GOOGLE_MAP_WIDGET_COMPONENT';
export const LIST_WIDGET_COMPONENT = 'LIST_WIDGET_COMPONENT';
export const GROUPING_LIST_WIDGET_COMPONENT = 'GROUPING_LIST_WIDGET_COMPONENT';
export const FILTERABLE_LIST_WIDGET_COMPONENT = 'FILTERABLE_LIST_WIDGET_COMPONENT';
export const FORM_WIDGET_COMPONENT = 'FORM_WIDGET_COMPONENT';
export const RADIAL_GAUGE_WIDGET_COMPONENT = 'RADIAL_GAUGE_WIDGET_COMPONENT';
export const TOGGLE_WIDGET = 'TOGGLE_WIDGET';
export const COLOR_PICKER_WIDGET = 'COLOR_PICKER_WIDGET';
export const SPEEDOMETER_WIDGET = 'SPEEDOMETER_WIDGET';
export const CLEARBLADE_COLLECTION_INSERT_METHOD = 'INSERT';
export const CLEARBLADE_COLLECTION_UPDATE_METHOD = 'UPDATE';
export const CLEARBLADE_COLLECTION_DELETE_METHOD = 'DELETE';
export const CLEARBLADE_COLLECTION_PAGE_METHOD = 'PAGE';
export const DEVICE_UPDATE_METHOD = 'UPDATE';
export const DEVICE_DELETE_METHOD = 'DELETE';
export const DEVICE_INSERT_METHOD = 'INSERT';
export const EDGE_UPDATE_METHOD = 'UPDATE';
export const EDGE_DELETE_METHOD = 'DELETE';
export const EDGE_INSERT_METHOD = 'INSERT';
export const USER_UPDATE_METHOD = 'UPDATE';
export const USER_DELETE_METHOD = 'DELETE';
export const USER_INSERT_METHOD = 'INSERT';
export const HAS_UNIQUE_STYLE = 'HAS_UNIQUE_STYLE';
export const STANDARD_THEME = 'STANDARD_THEME';
export const NIGHT_THEME = 'NIGHT_THEME';
export const COLOR_TYPE = 'color';
export const TEXT_TYPE = 'text';
export const TEXT_AREA_TYPE = 'textArea';
export const READ_ONLY_TEXT_TYPE = 'readOnly';
export const NUMBER_TYPE = 'number';
export const BOOLEAN_TYPE = 'boolean';
export const DATA_KEY_TYPE = 'dataKey';
export const INPUT_TYPE = 'input';
export const OPTION_TYPE = 'option';
export const PASSWORD_TYPE = 'password';
export const COPY_TYPE = 'copy';
export const OPTIONS_OR_TEXT_TYPE = 'optionORtext';
export const TYPE_AHEAD_TYPE = 'typeAhead';
export const MULTI_DATA_KEY_CHECK_TYPE = 'multiDataKeyCheck';
export const PARAMETERIZED_TEXT_AREA_TYPE = 'parameterizedTextArea';
export const ARRAY_BUILDER_TYPE = 'arrayBuilder';
export const JSON_BUILDER_TYPE = 'jsonBuilder';
export const FULL_SCREEN_WIDGET_MODAL = 'FULL_SCREEN_WIDGET_MODAL';
export const SIMULATED_SCREEN_SIZE_BREAKPOINT = 'SIMULATED_SCREEN_SIZE_BREAKPOINT';
export const PAGE_GRID = 'PAGE_GRID';
export const PANE_GRID = 'PANE_GRID';
export const FLYOUT_PANE = 'FLYOUT_PANE';
export const USER_MODAL = 'USER_MODAL';
export const HEADER = 'HEADER';
export const HAS_UNSAVED_CHANGES = 'HAS_UNSAVED_CHANGES';
export const RULE_BUILDER_WIDGET = 'RULE_BUILDER_WIDGET';
export const HAS_FETCHED_TRIGGER_DEFS = 'HAS_FETCHED_TRIGGER_DEFS';
export const TRIGGER_DEFS = 'TRIGGER_DEFS';
export const HOME_PAGE_NAME = 'Home';
export const CURRENT_PAGE_ID = 'currentPageID';
export const PAGE_SETTINGS_MODAL = 'PAGE_SETTINGS_MODAL';
export const USER_MODAL_SETTINGS_MODAL = 'USER_MODAL_SETTINGS_MODAL';
export const PAGE_HASH_DELIMITER = '/';
export const SUPPRESS_DATASOURCE_ERRORS = 'SUPPRESS_DATASOURCE_ERRORS';
export const REFRESH_INTERVAL = 'refresh_interval';
export const CALL_ON_LOAD = 'CALL_ON_LOAD';
export const USE_PARSER = 'USE_PARSER';
export const DATASOURCE_PARSER = 'DATASOURCE_PARSER';
export const KEY_VALUE_BUILDER_TYPE = 'KEY_VALUE_BUILDER_TYPE';
export const DEFAULT_WIDGET_HEIGHT = 12;
export const DEFAULT_PANE_HEIGHT = 15;
export const GRID_SIZE_X_LARGE = 'xl';
export const GRID_SIZE_LARGE = 'lg';
export const GRID_SIZE_MEDIUM = 'md';
export const GRID_SIZE_SMALL = 'sm';
export const GRID_SIZE_X_SMALL = 'xs';
export const GRID_SIZE_XX_SMALL = 'xxs';

export const BREAKPOINT_ORDER: RGLBreakpointsExt[] = [
  GRID_SIZE_XX_SMALL,
  GRID_SIZE_X_SMALL,
  GRID_SIZE_SMALL,
  GRID_SIZE_MEDIUM,
  GRID_SIZE_LARGE,
  GRID_SIZE_X_LARGE,
];

export const breakpointsToWidth = {
  [GRID_SIZE_X_LARGE]: 1800,
  [GRID_SIZE_LARGE]: 1200,
  [GRID_SIZE_MEDIUM]: 996,
  [GRID_SIZE_SMALL]: 768,
  [GRID_SIZE_X_SMALL]: 480,
  [GRID_SIZE_XX_SMALL]: 330,
};
// 50px less than pane breakpoints, react grid layout has pixel inconsistencies
export const breakpointsForWidgetGrid = {
  [GRID_SIZE_X_LARGE]: 1750,
  [GRID_SIZE_LARGE]: 1150,
  [GRID_SIZE_MEDIUM]: 946,
  [GRID_SIZE_SMALL]: 718,
  [GRID_SIZE_X_SMALL]: 430,
  [GRID_SIZE_XX_SMALL]: 280,
};

export const colsForNestedWidgetGrid = {
  [GRID_SIZE_X_LARGE]: 36,
  [GRID_SIZE_LARGE]: 30,
  [GRID_SIZE_MEDIUM]: 24,
  [GRID_SIZE_SMALL]: 18,
  [GRID_SIZE_X_SMALL]: 12,
  [GRID_SIZE_XX_SMALL]: 6,
};
export const EXTERNAL_SCRIPT_MANAGER_MODAL = 'EXTERNAL_SCRIPT_MANAGER_MODAL';
export const FILE_EDITOR_MODAL = 'FILE_EDITOR_MODAL';
export enum PARSER_DIRECTIONS {
  INCOMING_PARSER = 'incoming_parser',
  OUTGOING_PARSER = 'outgoing_parser',
}

export enum DRAG_ITEM_TYPES {
  WIDGET = 'WIDGET',
  PANE = 'PANE',
}
