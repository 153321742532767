/*
 *
 * App reducer
 *
 */

import produce from 'immer';

import { ActionConstants } from './actionConstants';
import { BaseAction } from 'cb-utils/baseAction';

export interface State {
  hasFetchedPlatformConfig: boolean;
}

export const initialState = {
  hasFetchedPlatformConfig: false,
};

const appReducer = produce((state: State, action: BaseAction) => {
  switch (action.type) {
    case ActionConstants.FETCH_PLATFORM_CONFIG_SUCCESS:
      state.hasFetchedPlatformConfig = true;
      return;
    default:
      return state;
  }
}, initialState);

export default appReducer;
