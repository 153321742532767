import React from 'react';
import { AnyMap, ColumnType } from 'cb-utils/console-entity-models';

export const getSummaryForDeviceEvent = (defKeyObject: AnyMap, verb: string) => {
  return (
    <div>
      {defKeyObject.deviceName ? (
        <span>
          <em>Device {verb}</em> where
          <em> name</em> equals
          <em> {defKeyObject.deviceName}</em>
        </span>
      ) : (
        <em>Device {verb}</em>
      )}
    </div>
  );
};

export const DeviceConditionVariables = [
  {
    name: '<device_name>',
    value: 'deviceName',
    type: ColumnType.string,
    default: true,
  },
];
