import Plugin, { ThisForOutgoingParser, SetupOptions } from '../plugin/PluginModel';
import { AnyMap } from '../../console-entity-models';
import DatasourceModel from '../datasource/DatasourceModel';
import { ExternalResource } from '../../externalResourceUtils';
import PluginPortalModel from '../PluginPortalModel';
import Parsers from '../plugin/utils/Parsers';
import { DATA_SETTING_TYPE } from 'containers/Portal/constants';
import { PARSER_TYPES } from 'containers/Portal/parserTypes';
import { DataSettingInstanceTypes, Omit, SettingTypes } from 'utils/types';

const selectExternalScripts = (myExternalScripts: string[] = [], globalScripts: ExternalResource[] = []): string[] => {
  return globalScripts.reduce((acc, globalScript) => {
    if (globalScript.shouldBlockAllWidgets || myExternalScripts.includes(globalScript.url)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc.push(globalScript.url);
    }
    return acc;
  }, []);
};

export interface WidgetSettingsInfo extends Omit<WidgetInfo<{}>, 'externalScripts'> {
  externalScripts: ExternalResource[];
}

export interface WidgetSettingsReturn {
  tabId: string;
  widgetInfo: WidgetSettingsInfo;
  changedSettingType?: SettingTypes;
}

export interface DataForParserError {
  settingName: string;
  dataType: PARSER_TYPES;
  parserType: string;
}

export interface WidgetInfo<Settings> {
  id: string;
  props: Settings;
  tab: string;
  type: string;
  externalScripts: string[];
  name: string;
}

class WidgetModel<Settings extends AnyMap> extends Plugin<Settings> {
  tab: string; // DEPRECATED: widgets shouldn't store information about where they live. that job should be left up to the layout
  constructor(id: string, info: WidgetInfo<Settings>, portalModel: PluginPortalModel) {
    // default to type_id for widgets created before this feature came online
    super(id, info.name || `${info.type}_${id}`, info.props, portalModel);
    this.tab = info.tab;
    this.setExternalScripts(info.externalScripts);
  }

  setName = (name: string) => {
    this.name = name;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getThisForOutgoingParser(widgetValue: any): ThisForOutgoingParser {
    return {
      model: this,
      widget: widgetValue,
    } as ThisForOutgoingParser;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getThisForParser(newData: any, prevData: any, datasource: DatasourceModel) {
    return {
      ...super.getThisForParser(newData, prevData, datasource),
      setCustomDimensions: this.setCustomDimensions.bind(this),
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateCallback(data: any, settingName: string) {
    return new Promise((resolve, reject) => {
      if (!settingName) {
        reject(`No setting name given to updateCallback for widget of type '${this.type}'`);
      } else {
        const setting = this.getSettingByName(settingName);
        const datasourceId = setting.id;
        const ds = this.portalModel.getDatasourceInstanceById(datasourceId);

        if (setting.dataType === PARSER_TYPES.CALCULATED) {
          resolve(this.executeParser(settingName, this.getThisForOutgoingParser(data)));
        } else if (ds) {
          // todo: figure out if we still need lastSent
          // this.lastSent[ds.id] = data;
          const processedData = this.executeParser(settingName, this.getThisForOutgoingParser(data));
          resolve(ds.sendData(processedData));
        } else {
          reject(`No datasource with id of '${datasourceId}'`);
        }
      }
    });
  }

  // should be run anytime a user changes the settings for a plugin (either through the UI or programmatically)
  async processUpdateToSettings(newSettings: Settings, changedSettingType?: SettingTypes) {
    // we only want to loop through settings and update latestData if it's a data setting that's updated or if it's an operation like "Discard Changes" where we want to revert everything
    if (!changedSettingType || changedSettingType === DATA_SETTING_TYPE) {
      this.clearSubscriptions();
      this.parsers = new Parsers(); // we re-create all of our parsers every time the settings change. maybe we could be smarter about this in the future
      let rtnValue: AnyMap = {};
      let currentSetting: DataSettingInstanceTypes;

      const settingDefinitions = this.getSettingsForDefinition();
      if (settingDefinitions && newSettings) {
        for (const def of settingDefinitions) {
          currentSetting = newSettings[def.name];
          if (typeof currentSetting !== 'undefined') {
            if (def.type === DATA_SETTING_TYPE) {
              try {
                let setting = this.processSetting(def, currentSetting);
                if (setting instanceof Promise) {
                  setting = await setting;
                }
                rtnValue = {
                  ...rtnValue,
                  [def.name]: setting,
                };
              } catch (e) {
                console.warn(`Failed to process setting for '${def.name}'`, e, this, settingDefinitions, newSettings);
              }
            }
          }
        }
      } else {
        console.warn('No settings for plugin', this, settingDefinitions, newSettings);
      }
      this.latestData(rtnValue);
      return rtnValue;
    }
  }

  async editSettings(settings: AnyMap, externalScripts: string[], changedSettingType?: SettingTypes) {
    this.setExternalScripts(externalScripts);
    await super.edit(settings, changedSettingType);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleDatasourceUpdate(settingName: string, newData: any, prevData: any) {
    await super.handleDatasourceUpdate(settingName, newData, prevData);
    this.dispatchUpdate(this.lastUpdated.getTime());
  }

  settingsChanged() {
    this.dispatchUpdate(Date.now());
  }

  dispatchUpdate(time: number) {
    this.portalModel.dispatcher.widgetUpdated(this.id, time);
  }

  setCustomDimensions(width: number, height: number) {
    this.portalModel.setCustomWidgetLayout(this.id, width, height);
  }

  setExternalScripts(myExternalScripts: string[] = []) {
    this.externalScripts = selectExternalScripts(myExternalScripts, this.portalModel.getExternalScripts());
  }

  dependsOnScript(url: string) {
    return this.externalScripts.includes(url);
  }

  addExternalScript(scriptUrl: string) {
    this.externalScripts = [...this.externalScripts, scriptUrl];
    return this;
  }

  removeExternalScript(scriptUrl: string) {
    if (this.dependsOnScript(scriptUrl)) {
      const idx = this.externalScripts.indexOf(scriptUrl);
      this.externalScripts = [...this.externalScripts.slice(0, idx), ...this.externalScripts.slice(idx + 1)];
    }
    return this;
  }

  setUpComplete(options: SetupOptions) {
    if (!options.calledFromPortalLoad) {
      this.dispatchUpdate(Date.now());
    }
  }
}

export default WidgetModel;
