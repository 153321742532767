import React from 'react';
import { createSummaryForUserEvent, UserConditionOptions, UserRequestParser } from './utils';
import { TriggerSourceDefinition } from '../types';
import { UserOptions } from 'cb-utils/console-entity-models';

const UserDeleted: TriggerSourceDefinition = {
  name: UserOptions.UserDeleted,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSummary: (defKeyObject) => <div>{createSummaryForUserEvent(defKeyObject, 'Deleted')}</div>,
  requestParser: UserRequestParser,
  conditionOptions: UserConditionOptions,
};

export default UserDeleted;
