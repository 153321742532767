import Axios, { AxiosRequestConfig } from 'axios';
import { getDevTokenFromStorage } from 'cb-utils/devToken';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = getDevTokenFromStorage();
  if (!config.headers) {
    config.headers = {};
  }
  if (token) {
    config.headers['ClearBlade-DevToken'] = token;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export let axios = createAxios('');

function createAxios(url: string) {
  const myAxios = Axios.create({
    baseURL: url,
  });
  myAxios.interceptors.request.use(authRequestInterceptor);
  return myAxios;
}

export const setAxiosBaseURL = (url: string) => {
  axios = createAxios(url);
};
