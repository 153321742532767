import React from 'react';
import { AnyMap, ColumnType } from 'cb-utils/console-entity-models';
import { REQ_OBJECT } from '../../serviceConstants';
import { REQ_PARAMS_PATH } from '../constants';

export const createSummaryForUserEvent = (defKeyObject: AnyMap, verb: string) => {
  return (
    <span>
      {defKeyObject.userId ? (
        <span>
          <em>User {verb}</em> where <em>ID</em> equals <em>{defKeyObject.userId}</em>
        </span>
      ) : (
        <em>User {verb}</em>
      )}
    </span>
  );
};

export const UserRequestParser = `
var ${REQ_OBJECT} = ${REQ_PARAMS_PATH}.user;
`;

export const UserConditionOptions = [
  { name: '<email>', value: 'email', type: ColumnType.string, default: true },
  { name: '<user_id>', value: 'user_id', type: ColumnType.uuid, default: true },
  {
    name: '<creation_date>',
    value: 'creation_date',
    type: ColumnType.bigint,
    default: true,
  },
];
