export interface PlatformInfo {
  url: string;
  messageUrl: string;
  messagePort: string; // <= Shouldn't we make this a number?
  messageTls: boolean;
  isEdge: boolean;
  logConsoleJSErrors: boolean;
  logPortalJSErrors: boolean;
  docsUrl: string;
}

export const CB_PLATFORM_URL = 'cb_platformURL';
export const CB_MESSAGE_URL = 'cb_messageUrl';
export const CB_MESSAGE_PORT = 'cb_messagePort';
export const CB_MESSAGE_TLS = 'cb_messageTls';
export const CB_IS_EDGE = 'cb_isEdge';
export const CB_DOCS_URL = 'cb_docsUrl';
const CB_LOG_CONSOLE_JS_ERRORS = 'cb_logConsoleJSErrors';
const CB_LOG_PORTAL_JS_ERRORS = 'cb_logPortalJSErrors';

export const getPlatformInfo = (): PlatformInfo => {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: localStorage.getItem(CB_PLATFORM_URL),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    messageUrl: localStorage.getItem(CB_MESSAGE_URL),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    messagePort: localStorage.getItem(CB_MESSAGE_PORT),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    messageTls: JSON.parse(localStorage.getItem(CB_MESSAGE_TLS)),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isEdge: JSON.parse(localStorage.getItem(CB_IS_EDGE)),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    logConsoleJSErrors: JSON.parse(localStorage.getItem(CB_LOG_CONSOLE_JS_ERRORS)),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    logPortalJSErrors: JSON.parse(localStorage.getItem(CB_LOG_PORTAL_JS_ERRORS)),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    docsUrl: localStorage.getItem(CB_DOCS_URL),
  };
};

export const storePlatformConfig = (i: PlatformInfo) => {
  localStorage.setItem(CB_PLATFORM_URL, i.url);
  localStorage.setItem(CB_MESSAGE_URL, i.messageUrl);
  localStorage.setItem(CB_MESSAGE_PORT, i.messagePort);
  localStorage.setItem(CB_MESSAGE_TLS, JSON.stringify(i.messageTls));
  localStorage.setItem(CB_IS_EDGE, JSON.stringify(i.isEdge));
  localStorage.setItem(CB_LOG_CONSOLE_JS_ERRORS, JSON.stringify(i.logConsoleJSErrors));
  localStorage.setItem(CB_LOG_PORTAL_JS_ERRORS, JSON.stringify(i.logPortalJSErrors));
  localStorage.setItem(CB_DOCS_URL, i.docsUrl);
};
