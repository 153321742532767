// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Observe<T = any> {
  (newData?: T): T;
  subscribe: (cb: ObservableCallback) => void;
  unsubscribe: (cb: ObservableCallback) => void;
  getPreviousValue: () => T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InProgressObserve<T = any> {
  (newData?: T): T;
  subscribe?: (cb: (newData: T) => void) => void;
  unsubscribe?: (cb: (newData: T) => void) => void;
  getPreviousValue?: () => T;
}

// todo: make unknown?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ObservableCallback = (newData: any, prevData: any) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function cbObservable<T = any>(initialValue?: T): Observe<T> {
  const subscribers = new WeakMap<object, ObservableCallback[]>();
  let _value = initialValue;
  let _previousValue = initialValue;
  const self = {};

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _observable: InProgressObserve<T> = function (this: {}, newVal?: any) {
    if (arguments.length) {
      _value = newVal;

      if (subscribers.has(this)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        subscribers.get(this).forEach((sub) => {
          sub(_value, _previousValue);
        });
      }
      _previousValue = _value;

      // allows for chaining methods
      return this;
    } else {
      return _value;
    }
  }.bind(self);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  _observable.getPreviousValue = () => {
    return _previousValue;
  };

  _observable.subscribe = function (this: {}, callback: ObservableCallback) {
    const currentSubs = subscribers.get(this);
    if (currentSubs) {
      subscribers.set(this, [...currentSubs, callback]);
    } else {
      subscribers.set(this, [callback]);
    }
  }.bind(self);

  _observable.unsubscribe = function (this: {}, callback: ObservableCallback) {
    const currentSubs = subscribers.get(this);
    subscribers.set(
      this,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentSubs.filter((cb) => cb !== callback),
    );
  }.bind(self);

  return _observable as Observe<T>;
}

export default cbObservable;
