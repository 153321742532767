import { AxiosError } from 'axios';

// Note: this parsing sucks
// I'm sure there's stuff that I'm not accounting for
// Basically we're not guaranteed that we're receiving a 'PlatformError' so we need to handle strings
export type PossibleErrors = string | PlatformError | Error | object | AxiosError<PlatformError>;
const parseCbError = (error: PossibleErrors | unknown): string => {
  let errMsg = '';
  // check if error is from axios
  if (error && typeof error === 'object' && 'response' in error) {
    const response = (error as AxiosError).response;
    if (typeof response !== 'undefined') {
      error = response.data;
    }
  }
  if (typeof error === 'string') {
    try {
      const errorBody = JSON.parse(error);
      if (errorBody && errorBody.error && errorBody.error.detail) {
        errMsg = errorBody.error.detail;
      } else if (errorBody && errorBody.error && errorBody.error.message) {
        errMsg = errorBody.error.message;
      }
    } catch (e) {
      errMsg = error;
    }
  } else {
    if (isPlatformError(error)) {
      if (error.error.detail) {
        errMsg = error.error.detail;
      } else if (error.error.message) {
        errMsg = error.error.message;
      } else if (error.error) {
        if (typeof error.error === 'string') {
          errMsg = error.error;
        } else {
          errMsg = JSON.stringify(error.error);
        }
      }
    } else if (error && (error as Error).message) {
      // must have been a JS error.
      errMsg = (error as Error).message;
    }
  }

  // welp we found an error that we didn't expect, just throw a generic message and report it so we can improve
  if (errMsg === '') {
    errMsg = 'Unknown error';
  }
  return errMsg;
};

function isPlatformError(error: PlatformError | Error | object | unknown): error is PlatformError {
  return typeof error !== 'undefined' && typeof (error as PlatformError).error !== 'undefined';
}

// This is the structure that the platform returns when an error occurs (invalid password, invalid permissions, improper query, etc.)
export interface PlatformError {
  error: {
    category: string;
    code: number;
    detail: string;
    id: string;
    level: number;
    line: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lowLevelError: any;
    message: string;
  };
  statusCode: number;
}

export default parseCbError;
