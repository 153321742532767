/*
 *
 * CbPaneGridContainer actions
 *
 */

import { ActionConstants } from './actionConstants';
import { BaseAction } from 'cb-utils/baseAction';
import { Breakpoints, Layout } from 'react-grid-layout';
import { RGLBreakpointsExt, RGLLayoutsExt } from 'containers/Portal/types';

// todo: comb through these types

interface UpdateWidgetBreakpoints extends BaseAction {
  type: ActionConstants.WIDGET_BREAKPOINT_CHANGED;
  payload: {
    paneId: string;
    tabs: string[];
    size: Breakpoints;
    cols: number;
  };
}

export function updateWidgetBreakpoints(
  paneId: string,
  tabs: string[],
  size: Breakpoints,
  cols: number,
): UpdateWidgetBreakpoints {
  return {
    type: ActionConstants.WIDGET_BREAKPOINT_CHANGED,
    payload: {
      paneId,
      tabs,
      size,
      cols,
    },
  };
}

export interface UpdateWidgetLayoutRequest extends BaseAction {
  type: ActionConstants.WIDGET_LAYOUT_CHANGE_REQUEST;
  payload: {
    paneId: string;
    tabId: string;
    currentBreakpoint: RGLBreakpointsExt;
    layout: Layout[];
    layouts: RGLLayoutsExt;
  };
}

export function updateWidgetLayoutRequest(
  paneId: string,
  tabId: string,
  currentBreakpoint: RGLBreakpointsExt,
  layout: Layout[],
  layouts: RGLLayoutsExt,
): UpdateWidgetLayoutRequest {
  return {
    type: ActionConstants.WIDGET_LAYOUT_CHANGE_REQUEST,
    payload: {
      paneId,
      tabId,
      currentBreakpoint,
      layout,
      layouts,
    },
  };
}

interface UpdateWidgetLayout extends BaseAction {
  type: ActionConstants.WIDGET_LAYOUT_CHANGED;
  payload: {
    paneId: string;
    tabId: string;
    layout: Layout[];
    layouts: RGLLayoutsExt;
  };
}

export function nestedWidgetLayoutChange() {
  return {
    type: ActionConstants.NESTED_WIDGET_LAYOUT_CHANGED,
  };
}

export function updateWidgetLayout(
  paneId: string,
  tabId: string,
  layout: Layout[],
  layouts: RGLLayoutsExt,
): UpdateWidgetLayout {
  return {
    type: ActionConstants.WIDGET_LAYOUT_CHANGED,
    payload: {
      paneId,
      tabId,
      layout,
      layouts,
    },
  };
}

interface UpdatePaneBreakpoints extends BaseAction {
  type: ActionConstants.PANE_BREAKPOINT_CHANGED;
  payload: {
    size: Breakpoints;
    cols: number;
  };
}

export function updatePaneBreakpoints(size: Breakpoints, cols: number): UpdatePaneBreakpoints {
  return {
    type: ActionConstants.PANE_BREAKPOINT_CHANGED,
    payload: {
      size,
      cols,
    },
  };
}

interface UpdatePaneLayout extends BaseAction {
  type: ActionConstants.PANE_LAYOUT_CHANGED;
  payload: {
    pageId: string;
    layout: Layout[];
    layouts: RGLLayoutsExt;
  };
}

export function updatePaneLayout(pageId: string, layout: Layout[], layouts: RGLLayoutsExt): UpdatePaneLayout {
  return {
    type: ActionConstants.PANE_LAYOUT_CHANGED,
    payload: {
      pageId,
      layout,
      layouts,
    },
  };
}

export function removeWidgetFromPaneLayout(payload: { id: string; tabId: string }) {
  // only remove from layout, don't delete
  return {
    type: ActionConstants.REMOVE_WIDGET_FROM_PANE_GRID_LAYOUT as ActionConstants.REMOVE_WIDGET_FROM_PANE_GRID_LAYOUT,
    payload,
  };
}

export type RemoveWidgetFromPaneLayout = ReturnType<typeof removeWidgetFromPaneLayout>;

export interface DeleteWidgetFromPaneGridLayout extends BaseAction {
  type: ActionConstants.DELETE_WIDGET_FROM_PANE_GRID_LAYOUT;
}

export interface DeleteWidgetFromPageGridLayout extends BaseAction {
  type: ActionConstants.DELETE_WIDGET_FROM_PAGE_GRID_LAYOUT;
}

export type ActionTypes =
  | UpdateWidgetBreakpoints
  | UpdateWidgetLayoutRequest
  | UpdateWidgetLayout
  | UpdatePaneBreakpoints
  | UpdatePaneLayout
  | RemoveWidgetFromPaneLayout
  | DeleteWidgetFromPaneGridLayout
  | DeleteWidgetFromPageGridLayout;
