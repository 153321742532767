import React from 'react';

import { BasicRequestParser } from '../constants';
import { AssetOptions } from 'cb-utils/console-entity-models';
import { TriggerSourceDefinition } from '../types';

const AssetUpdated: TriggerSourceDefinition = {
  name: AssetOptions.AssetUpdated,
  getSummary: () => (
    <div>
      <em>Asset Updated</em>
    </div>
  ),
  requestParser: BasicRequestParser,
  conditionOptions: [],
};

export default AssetUpdated;
