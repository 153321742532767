import { DatasourceInfo } from 'cb-utils/models/datasource/DatasourceModel';

export const isDatasourceFile = (ds: DatasourceInfo) => {
  if (ds && ds.id && ds.name && ds.settings && ds.type) {
    return true;
  }
  return false;
};

export enum DatasourceSettingsModalTabs {
  settings = 'settings',
  data = 'data',
}
