/* eslint-disable @typescript-eslint/explicit-function-return-type */
import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import checkStore from './checkStore';
import createReducer from '../reducers';
import { ConfiguredStore } from 'configureStore';
import { Reducer } from 'redux';

export function injectReducerFactory(store: ConfiguredStore<{}>, isValid?: boolean) {
  return function injectReducer(key: string, reducer: Reducer<{}>) {
    if (!isValid) checkStore(store);

    invariant(
      isString(key) && !isEmpty(key) && isFunction(reducer),
      '(app/utils...) injectReducer: Expected `reducer` to be a reducer function',
    );

    // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
    if (Reflect.has(store.injectedReducers, key) && store.injectedReducers[key] === reducer) {
      return;
    }

    store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.replaceReducer(createReducer(store.injectedReducers));
  };
}

export default function getInjectors(store: ConfiguredStore<{}>) {
  checkStore(store);

  return {
    injectReducer: injectReducerFactory(store, true),
  };
}
