import { detailPageSuffix } from 'cb-utils/consoleRouteUtils';
import { adaptersDetailRoute, adaptersOverviewRoute } from './adapters';
import { codePaths } from './code';
import { collectionsDetailRoute, collectionsOverviewRoute } from './collection';
import { deployBaseRoute, deployDetailRoute, DEPLOYMENT_ROUTE_PARAMS } from './deployments';
import { devicePublicKeysRoute, devicesOverviewRoute } from './devices';
import { edgeNameRouteParam, edgesBaseRoute } from './edge';
import { exportSystemModalChooseOptionRoute, exportSystemModalDestinationTypeRoute } from './exportSystemModal';
import { rolesDetailRoute, rolesOverviewRoute } from './roles';
import { usersOverviewRoute } from './users';

import { IpmAssetTypes } from 'cb-utils/console-entity-models/ipm';
import { adminDevManageRoute, adminSystemManageRoute } from './admin';
import { auditTrailBaseRoute } from './auditTrail';
import { certificatesOverviewRoute } from './certificates';
import { databaseConsoleOverviewRoute } from './databaseConsole';
import { devAccountDetailRoute } from './devAccount';
import { externalDatabasesDetailRoute, externalDatabasesOverviewRoute } from './externalDatabase';
import { filesBaseRoute, filesDetailRoute } from './files';
import { logsOverviewRoute } from './logs';
import { messagesDetailRoute, messagesOverviewRoute } from './messaging';
import { notificationHistoryRoute } from './notificationHistory';
import { pluginsTabRoute, portalsOverviewRoute } from './portals';
import { secretsDetailRoute, secretsOverviewRoute } from './secrets';
import { serviceCacheBaseRoute } from './serviceCache';
import { sessionsDevicesTabRoute, sessionsOverviewRoute } from './sessions';
import { systemDetailRoute, systemOverviewRoute, systemSettingsRoute } from './system';
import { timersBaseRoute } from './timers';

// note: if you want to add a new route to the app, you should probably add it here so that sidenav and getPathParams know about it
const ConsoleRoutes = {
  SYSTEM_OVERVIEW: systemOverviewRoute,
  SYSTEM_DETAIL: systemDetailRoute,
  EDGE_OVERVIEW: edgesBaseRoute,
  EDGE_DETAIL: `${edgesBaseRoute}/:${edgeNameRouteParam}${detailPageSuffix}`,
  DEPLOY_OVERVIEW: deployBaseRoute,
  DEPLOY_DETAIL: deployDetailRoute,
  DEPLOY_ITEM_LEVEL: `${deployDetailRoute}/:${DEPLOYMENT_ROUTE_PARAMS.ASSET_CLASS_NAME}`,
  FILES_OVERVIEW: filesBaseRoute,
  FILES_DETAIL: filesDetailRoute,
  FILES_ITEM_LEVEL: `${filesDetailRoute}/:${DEPLOYMENT_ROUTE_PARAMS.ASSET_CLASS_NAME}`,
  ROLES_OVERVIEW: rolesOverviewRoute,
  ROLE_DETAIL: rolesDetailRoute,
  DEVICES_OVERVIEW: devicesOverviewRoute,
  USERS_OVERVIEW: usersOverviewRoute,
  ADAPTERS_OVERVIEW: adaptersOverviewRoute,
  ADAPTERS_DETAIL: adaptersDetailRoute,
  COLLECTION_OVERVIEW: collectionsOverviewRoute,
  COLLECTION_DETAIL: collectionsDetailRoute,
  EXTERNAL_DATABASE_OVERVIEW: externalDatabasesOverviewRoute,
  EXTERNAL_DATABASE_DETAIL: externalDatabasesDetailRoute,
  EXPORT_SYSTEM_MODAL_CHOOSE_DESTINATION_TYPE: exportSystemModalChooseOptionRoute,
  EXPORT_SYSTEM_MODAL_DESTINATION_TYPE: exportSystemModalDestinationTypeRoute,
  MESSAGES_OVERVIEW: messagesOverviewRoute,
  MESSAGES_DETAIL: messagesDetailRoute,
  DEV_ACCOUNT_DETAIL: devAccountDetailRoute,
  ADMIN_DEV_PANEL: adminDevManageRoute,
  ADMIN_SYSTEMS_PANEL: adminSystemManageRoute,
  PORTALS_OVERVIEW: portalsOverviewRoute,
  PLUGINS_TAB: pluginsTabRoute,
  SESSIONS_OVERVIEW: sessionsOverviewRoute,
  SESSIONS_DEVICES_TAB: sessionsDevicesTabRoute,
  SERVICE_CACHE_OVERVIEW: serviceCacheBaseRoute,
  AUDIT_TRAIL: auditTrailBaseRoute,
  TIMERS_OVERVIEW: timersBaseRoute,
  NOTIFICATION_HISTORY_TABLE: notificationHistoryRoute,
  DATABASE_CONSOLE: databaseConsoleOverviewRoute,
  LOGS: logsOverviewRoute,
  SECRETS_OVERVIEW: secretsOverviewRoute,
  SECRETS_DETAIL: secretsDetailRoute,
  DEVICE_PUBLIC_KEYS: devicePublicKeysRoute,
  CERTIFICATES: certificatesOverviewRoute,
  SYSTEM_SETTINGS: systemSettingsRoute,
  SYSTEM_SETTINGS_PROPERTIES: `${systemSettingsRoute}/properties`,
  SYSTEM_SETTINGS_ACCESS: `${systemSettingsRoute}/access`,
  SYSTEM_SETTINGS_REGISTRY_INFO: `${systemSettingsRoute}/registryInfo`,
  ...codePaths,
};

export const ConsoleRoutesToIpmAssetTypes: {
  [K in keyof IConsoleRoutes]?: IpmAssetTypes[];
} = {
  ROLES_OVERVIEW: [IpmAssetTypes.ROLES],
  ROLE_DETAIL: [IpmAssetTypes.ROLES],
  USERS_OVERVIEW: [IpmAssetTypes.USERS],
  EDGE_OVERVIEW: [IpmAssetTypes.EDGES],
  DEVICES_OVERVIEW: [IpmAssetTypes.DEVICES],
  CODE_OVERVIEW: [IpmAssetTypes.CODE_LIBRARIES],
  LIBRARY_DETAIL: [IpmAssetTypes.CODE_LIBRARIES],
  SERVICE_DETAIL: [IpmAssetTypes.CODE_SERVICES],
  COLLECTION_OVERVIEW: [IpmAssetTypes.COLLECTIONS],
  COLLECTION_DETAIL: [IpmAssetTypes.COLLECTIONS],
  ADAPTERS_OVERVIEW: [IpmAssetTypes.ADAPTERS],
  ADAPTERS_DETAIL: [IpmAssetTypes.ADAPTERS],
  PORTALS_OVERVIEW: [IpmAssetTypes.PORTALS],
  SYSTEM_DETAIL: [],
};

export default ConsoleRoutes;
export type IConsoleRoutes = typeof ConsoleRoutes;

export * from './adapters';
export * from './code';
export * from './collection';
export * from './deployments';
export * from './devices';
export * from './edge';
export * from './messaging';
export * from './portals';
export * from './roles';
export * from './serviceCache';
export * from './users';
