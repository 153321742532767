/**
 * Create the store with dynamic reducers
 */

import { Map } from 'cb-utils/console-entity-models';
import { Reducer, Store, applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { SagaMiddleware, Task } from 'redux-saga';
import { SagaDescriptor } from 'utils/injectSaga';
import createReducer from './reducers';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const sagaMiddleware = createSagaMiddleware();

export interface ConfiguredStore<U> extends Store<U> {
  runSaga: SagaMiddleware<{}>['run'];
  injectedReducers: Map<Reducer<U>>;
  injectedSagas: Map<SagaDescriptor & { task: Task }>;
  asyncReducers: object;
}

export default function configureStore<U>(initialState = {}) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
          trace: true,
        })
      : compose;

  const store = createStore(createReducer({}), initialState, composeEnhancers(...enhancers)) as ConfiguredStore<U>;

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}
