/**
 *
 * CbCodeEditor - A CB implementation of the Monaco editor.
 *
 */

import React from 'react';

import { ThisForParser } from 'cb-utils/models/plugin/PluginModel';
import AbstractEditorModel from 'components/CbCodeEditor/editorModels/AbstractEditorModel';
import MonacoEditor from 'react-monaco-editor';
import { CLEARBLADE_CODE_EDITOR } from 'utils/constants';

const INITIAL_EDITOR_MODEL = 'InitialEditorModel';

export enum CbEditorLanguages {
  JAVASCRIPT = 'javascript',
  CSS = 'css',
  HTML = 'html',
  JSON = 'json',
  NONE = 'none',
}

export function mapTypeToExtension(type: CbEditorLanguages) {
  switch (type) {
    case CbEditorLanguages.JAVASCRIPT:
      return '.js';
    default:
      return `.${type}`;
  }
}

export enum CbEditorTheme {
  LIGHT = 'vs',
  DARK = 'vs-dark',
  HIGH_CONTRAST = 'hc-black',
}

// URL of loader.js is currently http://localhost:3000/portal/loader.js
// The monaco editor implements an internal AMD loader. The editor is hard-coded
// to look for many files within a "vs" folder. The webpack build results in
// the editor files being under the root context.
//
// Users of this component can override the requireConfiguration by passing in
// an appropriate coniguration object in the requireConfig prop.
//
// See https://github.com/superRaytin/react-monaco-editor
//

interface TsDef {
  name: string;
  contents: string;
}

export interface CbCodeEditorProps {
  cbModel?: AbstractEditorModel;
  tsDefs?: TsDef[];
  key?: string;
  height?: string | number;
  width?: string | number;
  value?: string;
  defaultValue?: string;
  language?: CbEditorLanguages;
  theme?: CbEditorTheme;
  options?: monaco.editor.IEditorOptions;
  onChange?(val: string): void;
  onSaveShortcut?: () => void;
  onTestShortcut?: () => void;
  sideBarWidth?: number; // only way found to fix flex+monaco weirdness with resizing for service detail
  wrappingId?: string;
  editorWillMount?(monacoModule: typeof monaco): void;
  editorDidMount?(editor: monaco.editor.ICodeEditor, monacoModule: typeof monaco): void;
  context?: object;
  autoSize?: boolean; // keep editor as long as content
  // use instead of automaticLayout since it depends on a set timeout
  thisForParser?: ThisForParser;
}

interface DisposableModels {
  [key: string]: monaco.IDisposable;
}

class CbCodeEditor extends React.PureComponent<CbCodeEditorProps, { theme: CbEditorTheme }> {
  static defaultProps: Partial<CbCodeEditorProps> = {
    theme: CbEditorTheme.LIGHT,
    width: '100%',
    height: '100%',
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state = {
    theme: this.props.theme,
  };

  // This probably should be static because there is a single global monaco available to the cb-console
  modelsToDispose: DisposableModels = {};
  editorIsLoaded = false;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private editor: monaco.editor.ICodeEditor;

  static defaultEditorOptions = {
    selectOnLineNumbers: true,
    scrollBeyondLastLine: false,
    folding: true,
    ariaLabel: CLEARBLADE_CODE_EDITOR,
  };

  private editorWillMount = (monacoInstance: typeof monaco) => {
    // Set compiler options
    monacoInstance.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES2015,
      allowNonTsExtensions: true,
      allowJs: true,
    });

    if (this.props.editorWillMount) {
      this.props.editorWillMount(monacoInstance);
    }
  };

  private editorDidMount = (editor: monaco.editor.ICodeEditor, monacoInstance: typeof monaco) => {
    this.editor = editor;
    this.editorIsLoaded = true;
    this.addEditorModels();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - yells this command doesn't exist but it works
    editor.addCommand(monaco.KeyMod.CtrlCmd + monaco.KeyCode.KEY_S, () => {
      if (this.props.onSaveShortcut) {
        this.props.onSaveShortcut();
      }
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - yells this command doesn't exist but it works
    editor.addCommand(monaco.KeyMod.CtrlCmd + monaco.KeyCode.KEY_R, () => {
      if (this.props.onTestShortcut) {
        this.props.onTestShortcut();
      }
    });

    if (this.props.autoSize) {
      this.sizeToContent(editor);
    }

    window.addEventListener('resize', this.resizeAllowingSideBar);

    if (this.props.editorDidMount) {
      this.props.editorDidMount(editor, monacoInstance);
    }
  };

  resizeAllowingSideBar = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const elem = document.getElementById(this.props.wrappingId);
    if (elem) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const width = elem.offsetWidth - this.props.sideBarWidth;
      const height = elem.offsetHeight;
      this.editor.layout({ width, height });
    }
  };

  addLibraryToMonaco(name: string, contents: string): monaco.IDisposable {
    return monaco.languages.typescript.javascriptDefaults.addExtraLib(contents, name);
  }

  private addEditorModels = () => {
    if (this.props.language === CbEditorLanguages.JAVASCRIPT) {
      // Add any d.ts file contents that were passed in to the monaco editor.
      if (this.props.tsDefs && this.props.tsDefs.length > 0) {
        this.props.tsDefs.forEach((def) => {
          if (!this.modelsToDispose[def.name]) {
            this.modelsToDispose[def.name] = this.addLibraryToMonaco(def.name, def.contents);
          }
        });
      }
    }
  };

  private onChange = (value: string): void => {
    this.props.onChange && this.props.onChange(value);
  };

  private sizeToContent = (editor: monaco.editor.ICodeEditor): void => {
    if (this.props.autoSize) {
      const countLines = this.props.value && (this.props.value.match(/\r?\n/g) || []).length + 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      editor.layout({ height: 20 * countLines, width: 100 });
    } else {
      editor.layout();
    }
  };

  // Before we unmount, we need to remove the models that we created so that they can be added
  // the next time an editor is rendered. Otherwise, changes to the model may not be reflected.
  componentWillUnmount() {
    Object.keys(this.modelsToDispose).forEach((key) => {
      this.modelsToDispose[key].dispose();
    });
    window.removeEventListener('resize', this.resizeAllowingSideBar);
  }

  componentDidUpdate() {
    // This logic will only need to be executed when libraries
    // have already been added to the editor, ie. when we know
    // the editor was already successfully instantiated and loaded
    if (this.editorIsLoaded && this.props.language === CbEditorLanguages.JAVASCRIPT) {
      this.disposeRemovedLibs(this.props);
      this.addNewLibs(this.props);
    }
  }

  private disposeRemovedLibs(newProps: CbCodeEditorProps) {
    // Check if any libraries have been removed
    if (Object.keys(this.modelsToDispose).length > 0) {
      const oldKeys = Object.keys(this.modelsToDispose);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newKeys = newProps.tsDefs.map((model) => model.name);

      if (!!newProps.cbModel && !!newProps.cbModel.getModels) {
        newKeys.push(INITIAL_EDITOR_MODEL);
      }

      // Get the TS definitions that were removed
      const removedKeys = oldKeys.filter((x) => !newKeys.includes(x));

      // call dispose method for each definition that was removed
      removedKeys.forEach((key) => {
        this.modelsToDispose[key].dispose();
        delete this.modelsToDispose[key];
      });
    }
  }

  private addNewLibs(newProps: CbCodeEditorProps) {
    // Check if any new libraries have been added
    const oldKeys = Object.keys(this.modelsToDispose);

    if (!newProps.tsDefs) return;

    // Get the TS definitions that were added
    const addedlibs = newProps.tsDefs.filter((model) => !oldKeys.includes(model.name));

    if (!!newProps.cbModel && !!newProps.cbModel.getModels && !oldKeys.includes(INITIAL_EDITOR_MODEL)) {
      addedlibs.push({
        name: INITIAL_EDITOR_MODEL,
        contents: newProps.cbModel.getModels(this.props.thisForParser),
      });
    }

    // Add each new lib to monaco
    addedlibs.forEach((lib) => {
      this.modelsToDispose[lib.name] = this.addLibraryToMonaco(lib.name, lib.contents);
    });
  }

  render() {
    const editorOptions = {
      ...CbCodeEditor.defaultEditorOptions,
      ...this.props.options,
    };
    return (
      <MonacoEditor
        key={this.props.key}
        width={this.props.width}
        height={this.props.height}
        language={this.props.language}
        theme={this.props.theme}
        options={editorOptions}
        value={this.props.value}
        onChange={this.onChange}
        editorWillMount={this.editorWillMount}
        editorDidMount={this.editorDidMount}
        requireConfig={{
          url: `/vs/loader.js`,
          paths: {
            vs: '/vs',
          },
        }}
      />
    );
  }
}

export default CbCodeEditor;
