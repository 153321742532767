import configureCbPortalInterface from '../../cb-utils/setupPortalModel';
import WithLoadable from '../../components/WithLoadable';
import { Props } from '.';
import store from 'appStore';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default WithLoadable<Props>(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  configureCbPortalInterface(store);
  return import(/* webpackChunkName: "cb-portal" */ './index');
});
