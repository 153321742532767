import DeviceCreated from './DeviceCreated';
import DeviceDeleted from './DeviceDeleted';
import DeviceUpdated from './DeviceUpdated';

const DeviceModule = {
  DeviceCreated,
  DeviceDeleted,
  DeviceUpdated,
};

export default DeviceModule;
