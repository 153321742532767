/* eslint-disable @typescript-eslint/explicit-function-return-type */
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import invariant from 'invariant';
import conformsTo from 'lodash/conformsTo';

import checkStore from './checkStore';
import { DAEMON, ONCE_TILL_UNMOUNT, RESTART_ON_REMOUNT } from './constants';
import { ConfiguredStore } from 'configureStore';
import { SagaDescriptor } from './injectSaga';
import { Map } from 'cb-utils/console-entity-models';
import { Task } from 'redux-saga';

const allowedModes = [RESTART_ON_REMOUNT, DAEMON, ONCE_TILL_UNMOUNT];

const checkKey = (key: string) =>
  invariant(isString(key) && !isEmpty(key), '(app/utils...) injectSaga: Expected `key` to be a non empty string');

const checkDescriptor = (descriptor: SagaDescriptor) => {
  const shape = {
    saga: isFunction,
    mode: (mode: string) => isString(mode) && allowedModes.includes(mode),
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  invariant(conformsTo(descriptor, shape), '(app/utils...) injectSaga: Expected a valid saga descriptor');
};

export function injectSagaFactory(store: ConfiguredStore<{}>, tasks: Map<Task> = {}, isValid?: boolean) {
  return function injectSaga(
    key: string,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    descriptor: SagaDescriptor = { saga: null },
    args: object,
    componentId: string,
  ) {
    if (!isValid) checkStore(store);
    const newDescriptor = {
      ...descriptor,
      mode: descriptor.mode || RESTART_ON_REMOUNT,
    };
    const { saga, mode } = newDescriptor;

    checkKey(key);
    checkDescriptor(newDescriptor);

    let hasSaga = Reflect.has(store.injectedSagas, key);
    if (process.env.NODE_ENV !== 'production') {
      const oldDescriptor = store.injectedSagas[key];
      // enable hot reloading of daemon and once-till-unmount sagas
      if (hasSaga && oldDescriptor.saga !== saga && oldDescriptor.task) {
        oldDescriptor.task.cancel();
        hasSaga = false;
      }
    }

    if (!hasSaga || (hasSaga && mode !== DAEMON && mode !== ONCE_TILL_UNMOUNT)) {
      // ts is being very strange complaining about the second arg
      // if we can, change back to: store.runSaga(saga, args)
      const task = (store.runSaga as (...parms: Parameters<typeof store.runSaga>) => ReturnType<typeof store.runSaga>)(
        saga,
        args,
      );
      tasks[componentId] = task;
      store.injectedSagas[key] = {
        ...newDescriptor,
        task,
      };
    }
  };
}

export function ejectSagaFactory(store: ConfiguredStore<{}>, tasks: Map<Task> = {}, isValid?: boolean) {
  return function ejectSaga(key: string, componentId: string) {
    if (!isValid) checkStore(store);
    checkKey(key);

    if (Reflect.has(store.injectedSagas, key)) {
      const descriptor = store.injectedSagas[key];
      if (descriptor.mode !== DAEMON) {
        tasks[componentId].cancel(); // cancel the saga that is running for this instance of the component
        delete tasks[componentId];

        // Clean up in production; in development we need `descriptor.saga` for hot reloading
        if (process.env.NODE_ENV === 'production') {
          // Need some value to be able to detect `ONCE_TILL_UNMOUNT` sagas in `injectSaga`
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - this code is from the boilerplate. seems like a hack?
          store.injectedSagas[key] = 'done';
        }
      }
    }
  };
}

export default function getInjectors(store: ConfiguredStore<{}>) {
  checkStore(store);

  const tasks = {};
  return {
    injectSaga: injectSagaFactory(store, tasks, true),
    ejectSaga: ejectSagaFactory(store, tasks, true),
  };
}
