import AssetCreated from './AssetCreated';
import AssetUpdated from './AssetUpdated';
import AssetDeleted from './AssetDeleted';

const AssetModule = {
  AssetCreated,
  AssetUpdated,
  AssetDeleted,
};

export default AssetModule;
