import React from 'react';
import { BasicRequestParser } from '../constants';
import { getSummaryForDeviceEvent, DeviceConditionVariables } from './utils';
import { TriggerSourceDefinition, ConditionOptions } from '../types';
import { DeviceOptions } from 'cb-utils/console-entity-models';
import { RBCondition } from '../../utils';
import buildCondition from '../../ConditionBuilder';

const DeviceUpdated: TriggerSourceDefinition = {
  name: DeviceOptions.DeviceUpdated,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getSummary: (defKeyObject) => <div>{getSummaryForDeviceEvent(defKeyObject, 'Updated')}</div>,
  requestParser: BasicRequestParser,
  conditionOptions: DeviceConditionVariables,
  getConditionParser: (condition: RBCondition, options: ConditionOptions) => {
    return `
    ClearBlade.init(req);
    var query = ClearBlade.Query().equalTo("name", reqObject.deviceName);
    ClearBlade.Device().fetch(query, callBack);

    function callBack (err, data) {
        if(!err) {
            log("Successfully retrieved device");
            ${buildCondition(condition, options, false, true)}
        } else {
            log("Failure while retrieving device; " + JSON.stringify(err));
        }
    }
    `;
  },
};

export default DeviceUpdated;
