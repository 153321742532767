export enum PARSER_TYPES {
  STATIC = 'STATIC_DATA_TYPE',
  CALCULATED = 'CALCULATED_DATA_TYPE',
  DYNAMIC = 'DYNAMIC_DATA_TYPE',
  INTERNAL_RESOURCE = 'INTERNAL_RESOURCE_TYPE',
}

export const StaticParserSettingOption = {
  value: PARSER_TYPES.STATIC,
  name: 'Static',
};
export const CalculatedParserSettingOption = {
  value: PARSER_TYPES.CALCULATED,
  name: 'Calculated',
};
export const ParserSettingOptions = [
  StaticParserSettingOption,
  CalculatedParserSettingOption,
  {
    value: PARSER_TYPES.DYNAMIC,
    name: 'Dynamic',
  },
];

export type FORCE_DATA = PARSER_TYPES[];
