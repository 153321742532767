import { REQ_OBJECT } from '../serviceConstants';
import buildCondition from '../ConditionBuilder';
import { RBCondition } from '../utils';
import { ConditionOptions } from './types';

export const REQ_PARAMS_PATH = 'req.params';

export const BasicRequestParser = `
    var ${REQ_OBJECT};

    try {
        ${REQ_OBJECT} = JSON.parse(${REQ_PARAMS_PATH});
    } catch(e) {
        ${REQ_OBJECT} = ${REQ_PARAMS_PATH};
    }
  `;

export const getBasicConditionParser = (condition: RBCondition, options: ConditionOptions) => {
  return `
        try {
            ${buildCondition(condition, options, false)}
        } catch (e) {
            ${buildCondition(condition, options, true)}
        }`;
};
