import React from 'react';
import { Field } from 'formik';
import { ICustomSettingComponent } from 'utils/types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ShowIf =
  (
    settingName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
  ): ICustomSettingComponent =>
  // @ts-ignore
  ({ settings, settingProps }) => {
    if (settings[settingName] === value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <Field {...settingProps} />;
    } else {
      return null;
    }
  };
