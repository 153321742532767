import React from 'react';

import { createTypeAhead } from 'containers/Portal/pluginSettingUtils';

import { NATIVE_LIBRARIES } from '../../../serviceConstants';
import { AlertProvider } from '../../types';
import { ProviderCodeGenerator } from '../../../types';

interface MyProviderFields {
  serviceName: string;
}

class ClearBladeCodeServiceProvider extends AlertProvider<{}, MyProviderFields> {
  createProviderCode(ruleName: string, gen: ProviderCodeGenerator<{}, MyProviderFields>) {
    return `
        ClearBlade.init({request:req});
        ClearBlade.Code().execute("${
          gen.providerFields.serviceName
        }", Object.assign({ruleName: '${ruleName}'}, req.params), true, function (err, body){
            if(err) {
                log("Failure while executing ${gen.providerFields.serviceName}; " + JSON.stringify(err));
                ${this.callNextAlertOrError(gen)}
            } else {
                log("Successfully executed ${gen.providerFields.serviceName}");
                ${this.callNextAlertOrSuccess(gen)}
            }
        })
    `;
  }
  getErrorCode() {
    return 'resp.error(body);';
  }
  getSuccessCode() {
    return 'resp.success(body);';
  }
  getSummary(_: {}, providerFields: MyProviderFields) {
    return <em>Execute {providerFields.serviceName}</em>;
  }
}

export default new ClearBladeCodeServiceProvider(
  'clearblade',
  'ClearBlade',
  [NATIVE_LIBRARIES.CLEARBLADE_LIBRARY, NATIVE_LIBRARIES.LOG_LIBRARY],
  [
    {
      ...createTypeAhead<MyProviderFields>('serviceName', 'Service Name'),
      required: true,
    },
  ],
);
