import React from 'react';
import { withRouter } from 'react-router';
import ProtectedRoute, { BaseProtectedRouteProps } from './ProtectedRoute';
import { checkAuthToken } from 'cb-utils/services/currentUser';
import { deleteDevTokenFromStorage } from 'cb-utils/devToken';

class CbProtectedRoute extends React.PureComponent<BaseProtectedRouteProps, {}> {
  render() {
    return <ProtectedRoute {...this.props} checkAuth={checkAuthToken} onCheckAuthError={deleteDevTokenFromStorage} />;
  }
}

export default withRouter(CbProtectedRoute);
