import { all, call, put, takeEvery } from 'redux-saga/effects';

import { PlatformInfo, storePlatformConfig } from 'cb-utils/platformInfo';

import { setAxiosBaseURL } from 'lib/axios';
import { getObjectDiff } from 'utils/getDiff';
import { ActionConstants } from './actionConstants';
import {
  createFetchPlatformConfigError,
  createFetchPlatformConfigSuccess,
  fetchPlatformConfig,
  PlatformConfig,
} from './actions';

function* callFetchPlatformConfig() {
  try {
    const platformConfig: PlatformConfig = yield call(fetchPlatformConfig);
    if (platformConfig.value) {
      storePlatformConfig(platformConfig.value);
      setAxiosBaseURL(platformConfig.value.url);
    }
    yield put(createFetchPlatformConfigSuccess(platformConfig.value));
    const currentInfo: PlatformInfo = yield platformConfig.promise;
    const diff = getObjectDiff<PlatformInfo>({
      oldObj: platformConfig.value,
      newObj: currentInfo,
      keysToIgnore: [],
    });
    if (Object.keys(diff).length) {
      storePlatformConfig(currentInfo);
      setAxiosBaseURL(currentInfo.url);
      console.log('Found diff between stored platform config and fetched platform config. Reloading');
      location.reload();
    }
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(createFetchPlatformConfigError(e));
  }
}

function* fetchPlatformConfigSaga() {
  yield takeEvery(ActionConstants.FETCH_PLATFORM_CONFIG_REQUEST, callFetchPlatformConfig);
}

export default function* () {
  yield all([fetchPlatformConfigSaga()]);
}
