/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { translationMessages } from 'i18n';
import { createStructuredSelector } from 'reselect';
import { makeSelectLocale } from './selectors';

interface Props {
  messages: typeof translationMessages;
  children?: React.ReactNode;
}

export class LanguageProvider extends React.PureComponent<Props & StateToProps, {}> {
  render() {
    return (
      // @ts-ignore - error is Property 'children' does not exist on type but this error only appeared because we updated react's types but not the library's types
      <IntlProvider
        locale={this.props.locale}
        key={this.props.locale}
        messages={this.props.messages[this.props.locale as keyof typeof translationMessages]}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  locale: makeSelectLocale(),
});

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect<StateToProps, {}, Props>(mapStateToProps)(LanguageProvider);
