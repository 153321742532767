import React from 'react';

import { BasicRequestParser } from '../constants';
import { DataOptions } from 'cb-utils/console-entity-models';
import { TriggerSourceDefinition } from '../types';
import { CollectionConditionOptions } from './utils';

const CollectionCreated: TriggerSourceDefinition = {
  name: DataOptions.CollectionCreated,
  getSummary: () => (
    <div>
      <em>Collection Created</em>
    </div>
  ),
  requestParser: BasicRequestParser,
  conditionOptions: CollectionConditionOptions,
};

export default CollectionCreated;
