import ClearBladeService from './codeService/ClearBlade';
import Twilio from './sms/Twilio';
import SendGrid from './email/SendGrid';
import { AlertProvider } from './types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ProviderTypes: Array<AlertProvider<{}, {}>> = [ClearBladeService, Twilio, SendGrid];

export const getProviderTypeByName = (name: string) => ProviderTypes.filter((provider) => provider.name === name)[0];
