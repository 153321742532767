import CollectionCreated from './CollectionCreated';
import CollectionDeleted from './CollectionDeleted';
import CollectionUpdated from './CollectionUpdated';
import ItemCreated from './ItemCreated';
import ItemDeleted from './ItemDeleted';
import ItemUpdated from './ItemUpdated';

const DataModule = {
  CollectionCreated,
  CollectionDeleted,
  CollectionUpdated,
  ItemCreated,
  ItemDeleted,
  ItemUpdated,
};

export default DataModule;
