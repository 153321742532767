import { DB_TYPES } from 'cb-utils/services/externalDatabase';
import { CollectionTableEntityModel } from 'containers/Console/state';
import { AssetColumnMetadata } from '.';
import { Map } from './entities';

// todo: remove this?
export interface ConnectionModel {
  isConnect: true;
  dbtype: DB_TYPES;
  dbname: string;
  tablename: string;
  address: string;
  port: number;
  user: string;
  library?: string;
  encryption?: boolean;
  catolog?: string;
  schema?: string;
}

export interface CollectionModel extends Partial<ConnectionModel> {
  collectionID: string;
  name: string;
  is_hypertable?: boolean;
  hypertable_properties?: HyperTableProperties;
}

export interface HyperTableProperties {
  time_column: string;
  chunk_time_interval: { interval_string: string };
  migrate_data: boolean;
  data_retention_policy: { interval_string: string };
}

export interface CollectionRow {
  item_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [other: string]: any;
}

export interface CollectionRowsResponse {
  CURRENTPAGE: number;
  DATA: CollectionRow[];
  NEXTPAGEURL: string;
  PREVPAGEURL: string;
  TOTAL: number;
}

interface CollectionResponse {
  appID: string;
  name: string;
}

export type AllCollectionsResponse = CollectionResponse[];

export function formatCollections(unformatted: CollectionModel[]): Map<CollectionTableEntityModel> {
  return unformatted.reduce((acc, cur) => {
    const coll: CollectionTableEntityModel = {
      ...cur,
      entities: {},
      loading: {},
      rows: [],
      isFetching: false,
      hasFetched: false,
      currentPage: 1,
      pageSize: 25,
      columns: {
        isFetching: false,
        hasFetched: false,
        loading: {},
        entities: {},
      },
      count: {
        total: {
          isFetching: false,
          hasFetched: false,
          loading: {},
          value: 0,
        },
        filtered: {
          isFetching: false,
          hasFetched: false,
          loading: {},
          value: 0,
        },
      },
      PKColumnName: 'item_id',
    };
    acc[cur.name] = coll;
    return acc;
  }, {} as Map<CollectionTableEntityModel>);
}

export enum COLLECTION_COLUMNS {
  item_id = 'item_id',
}

const showInCreateModal: COLLECTION_COLUMNS[] = [];
const requiredInCreateModal: COLLECTION_COLUMNS[] = [];
const noUpdateAllowed: COLLECTION_COLUMNS[] = [COLLECTION_COLUMNS.item_id];
const displayName: COLLECTION_COLUMNS = COLLECTION_COLUMNS.item_id;

export const COLLECTIONS_COLUMN_METADATA: AssetColumnMetadata<COLLECTION_COLUMNS> = {
  defaultColumns: Object.values(COLLECTION_COLUMNS) as COLLECTION_COLUMNS[],
  showInCreateModal,
  requiredInCreateModal,
  noUpdateAllowed,
  displayName,
};
