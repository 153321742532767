import { pickBy, isEqual } from 'lodash';

interface Parm<T extends object> {
  oldObj: T;
  newObj: T;
  keysToIgnore?: string[];
}

export function getObjectDiff<T extends object>(
  { oldObj = {} as T, newObj, keysToIgnore = [] }: Parm<T>,
  eqFunc = (newVal: T[keyof T], oldVal: T[keyof T]) => newVal !== oldVal,
): Partial<T> {
  return pickBy(newObj, (v, k: string) => {
    if (keysToIgnore.includes(k)) return false;
    return eqFunc(v, oldObj[k as keyof T]);
  });
}

export function getObjectDiffDeepEqual<T extends object>(p: Parm<T>): Partial<T> {
  return getObjectDiff(p, (newVal, oldVal) => {
    return !isEqual(newVal, oldVal);
  });
}

export function hasNoChanges(arg: Parm<object>): boolean {
  const diffObj = getObjectDiff(arg);
  return Object.keys(diffObj).length === 0;
}
