import { PAGE_HASH_DELIMITER } from '../constants';
import { Map } from 'cb-utils/console-entity-models';
import { PageConfig } from '../types';
import { Omit } from 'utils/types';

interface PageLinks {
  href: string;
  path: string;
}

const createPageLinks = (
  currentHref: string,
  currentPath: string,
  hrefSuffix: string,
  pathSuffix: string,
): PageLinks => {
  return {
    href: `${currentHref}${PAGE_HASH_DELIMITER}${hrefSuffix}`,
    path: `${currentPath}${PAGE_HASH_DELIMITER}${pathSuffix}`,
  };
};

export interface NestedPageConfig extends Omit<PageConfig, 'children'> {
  children: NestedPageConfig[];
}

const mapChildren = (
  pages: Map<PageConfig>,
  currentHref: string,
  currentPath: string,
  childId: string,
): NestedPageConfig => {
  let info: PageLinks = {
    href: '',
    path: '',
  };
  const childPage = pages[childId];
  if (childPage.isParam) {
    info = createPageLinks(currentHref, currentPath, childPage.defaultValue, `:${childPage.name}`);
  } else {
    info = createPageLinks(currentHref, currentPath, childPage.name, childPage.name);
  }
  const rtn: NestedPageConfig = {
    ...childPage,
    ...info,
    children: childPage.children.map(mapChildren.bind(null, pages, info.href, info.path)),
  };
  return rtn;
};

const createPageHierarchy = (pages: Map<PageConfig>): Map<NestedPageConfig> => {
  const rtn: Map<NestedPageConfig> = {};
  for (const id in pages) {
    if (Object.prototype.hasOwnProperty.call(pages, id)) {
      if (!pages[id].isChild) {
        rtn[id] = mapChildren(pages, '', '', id);
      }
    }
  }
  return rtn;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const findNestedPage = (pages: Map<NestedPageConfig>, id: string): NestedPageConfig => {
  for (const i in pages) {
    if (Object.prototype.hasOwnProperty.call(pages, i)) {
      if (i === id) {
        return pages[i];
      }
      if (pages[i].children && pages[i].children.length > 0) {
        return findNestedPage(
          pages[i].children.reduce((prev, curr) => {
            prev[curr.id] = curr;
            return prev;
          }, {} as Map<NestedPageConfig>),
          id,
        );
      }
    }
  }
};

export const getHrefForPage = (pages: Map<NestedPageConfig>, id: string): string => {
  const p = findNestedPage(pages, id);
  if (p) {
    return p.href;
  }
  return '';
};

export default createPageHierarchy;
