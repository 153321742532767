import { matchPath, match } from 'react-router-dom';

import { PAGE_HASH_DELIMITER } from '../containers/Portal/constants';
import createPageHierarchy, { NestedPageConfig } from '../containers/Portal/utils/createPageHierarchy';
import { PageConfig } from 'containers/Portal/types';
import { Map } from './console-entity-models';

export const getURLHash = () => {
  return window.location.hash.substr(1);
};

const lookForPathMatch = (
  pages: Map<NestedPageConfig> | NestedPageConfig[],
  hash: string,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { page: any; match: match<{}> } => {
  let myMatch;
  for (const id in pages) {
    if (Object.prototype.hasOwnProperty.call(pages, id)) {
      myMatch = callMatchPath(hash, pages[id].path);
      if (myMatch) {
        return {
          page: pages[id],
          match: myMatch,
        };
      } else if (pages[id].children.length > 0) {
        const descendantMatch = lookForPathMatch(pages[id].children, hash);
        if (descendantMatch) {
          return descendantMatch;
        } else {
          continue;
        }
      }
    }
  }
};

const callMatchPath = (hash: string, path: string) => {
  return matchPath(hash, { path, exact: true });
};

// this function will only return a page ID if there is a match
// the caller should be responsible for handle an undefined return value
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getCurrentPage = (pages: Map<PageConfig>, hash = getURLHash()): { page: PageConfig; match: match<{}> } => {
  const parts = hash.split(PAGE_HASH_DELIMITER).slice(1);
  if (parts.length > 0) {
    const pageHierarchy = createPageHierarchy(pages);
    const pageMatch = lookForPathMatch(pageHierarchy, hash);
    if (pageMatch) {
      return pageMatch;
    }
  }
};

export default getCurrentPage;
