/*
 * ErrorModal Messages
 *
 * This contains all the text for the ErrorModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  more: {
    id: 'app.components.ErrorModal.more',
    defaultMessage: 'More',
  },
  less: {
    id: 'app.components.ErrorModal.less',
    defaultMessage: 'Less',
  },
  dismiss: {
    id: 'app.components.ErrorModal.dismiss',
    defaultMessage: 'Dismiss',
  },
  seeFullError: {
    id: 'app.components.ErrorModal.seeFullError',
    defaultMessage: 'See Full Error',
  },
  acknowledgeError: {
    id: 'app.components.ErrorModal.acknowledgeError',
    defaultMessage: 'Do not show me errors for this item again',
  },
});
