import { getPathParams } from 'cb-utils/getPathParams';
import { getDevTokenFromStorage, getDevTokens } from './devToken';
import { getPlatformInfo } from './platformInfo';

export enum AllowedMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export function checkStatus(response: Response) {
  if (isSuccessfulStatusCode(response.status)) {
    return response;
  } else {
    throw response;
  }
}

function isSuccessfulStatusCode(statusCode: number) {
  return statusCode >= 200 && statusCode < 300;
}

interface FetchConfig extends RequestInit {
  method: AllowedMethods;
}

const attachDevTokenToHeaders = (fetchConfig: FetchConfig, token: string = getDevTokenFromStorage()) => {
  fetchConfig.headers = {
    ...fetchConfig.headers,
    'ClearBlade-DevToken': token,
  };
};
// error option not to retry
export function platformFetch(
  endpoint: string,
  fetchConfig: FetchConfig,
  token: string = getDevTokenFromStorage(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  edgeName: string = getPathParams().edgeName,
  // I added the explicit Promise<any> to maintain backwards compatibility with the codebase. This should return Promise<unknown> but that would require handling the return type in all the places where this function is used.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  attachDevTokenToHeaders(fetchConfig, token);
  const devTokens = getDevTokens(edgeName);
  if (edgeName && devTokens) {
    fetchConfig.headers = {
      'ClearBlade-Edge': devTokens.currentEdge.name,
      'Clearblade-SystemKey': devTokens.currentEdge.systemKey,
      'ClearBlade-DevToken': devTokens.currentEdge.devToken,
    };
  }
  return cbFetch(`${getPlatformInfo().url}${endpoint}`, fetchConfig);
}

export function consoleFetch(url: string, fetchConfig: FetchConfig) {
  attachDevTokenToHeaders(fetchConfig);
  return cbFetch(url, fetchConfig);
}

function getRespDataFromText(text: string): unknown {
  let respData = text;
  try {
    respData = JSON.parse(text);
  } catch (e) {
    // do nothing
  }
  return respData;
}

// I added the explicit Promise<any> to maintain backwards compatibility with the codebase. This should return Promise<unknown> but that would require handling the return type in all the places where this function is used.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function cbFetch(url: string, fetchConfig: FetchConfig): Promise<any> {
  const resp = await fetch(url, fetchConfig);
  const text = await resp.text();

  if (!isSuccessfulStatusCode(resp.status)) {
    throw getRespDataFromText(text);
  }

  return getRespDataFromText(text);
}

export async function cbRedirectFetch(url: string, fetchConfig: RequestInit) {
  try {
    const response = await fetch(url, fetchConfig);
    console.log('cbRedirectFetch response:', response);
    if (response.status === 302 || response.status === 200) {
      const location = response.url;
      if (location) {
        window.location.href = location;
      } else {
        console.error('Redirect URL is missing');
      }
    } else {
      throw new Error('Unexpected response status');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}
