/*
 *
 * CbPortal actions
 *
 */

import { cb } from '../../cb-utils/cbLib';

import { BaseAction } from 'cb-utils/baseAction';
import { AllowedMethods, consoleFetch } from 'cb-utils/cbFetch';
import { TriggerEventDefinition } from 'cb-utils/console-entity-models';
import { PlatformInfo } from 'cb-utils/platformInfo';
import queryString from 'query-string';
import { Omit } from 'utils/types';
import { MasterPortalConfig } from '../Portal/types';
import { ActionConstants } from './actionConstants';
import { BaseInitInfo, InitWithCredsInfo, PortalUserInfo, UserWithAuthToken } from './types';
import { getAuthLocalStorage, setAuthLocalStorage } from './utils';

interface FetchPortalSuccess extends BaseAction {
  type: ActionConstants.FETCH_PORTAL_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any; // todo: type this
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchPortalSuccess(portalInfo: any): FetchPortalSuccess {
  return {
    type: ActionConstants.FETCH_PORTAL_SUCCESS,
    payload: portalInfo,
  };
}

export interface InitPortalRequest extends BaseAction {
  type: ActionConstants.INIT_PORTAL_REQUEST;
  payload: {
    systemKey: string;
    systemSecret: string;
    name: string;
    allowAnon?: string;
  };
}

export function initPortalRequest(
  systemKey: string,
  systemSecret: string,
  name: string,
  allowAnon?: string,
): InitPortalRequest {
  return {
    type: ActionConstants.INIT_PORTAL_REQUEST,
    payload: {
      systemKey,
      systemSecret,
      name,
      allowAnon,
    },
  };
}

interface InitPortalSuccess extends BaseAction {
  type: ActionConstants.INIT_PORTAL_SUCCESS;
  payload: { user: UserWithAuthToken };
}

export function initPortalSuccess(email: string, authToken: string): InitPortalSuccess {
  return {
    type: ActionConstants.INIT_PORTAL_SUCCESS,
    payload: {
      user: {
        email,
        authToken,
      },
    },
  };
}

interface FetchPortalRequest extends BaseAction {
  type: ActionConstants.FETCH_PORTAL_REQUEST;
  payload: {
    name?: string;
  };
}

export function fetchPortalRequest(name?: string): FetchPortalRequest {
  return {
    type: ActionConstants.FETCH_PORTAL_REQUEST,
    payload: {
      name,
    },
  };
}

export function getAnonToken({ systemKey, systemSecret }: BaseInitInfo) {
  return new Promise((resolve, reject) => {
    consoleFetch('/console-api/portal/init/anon', {
      method: AllowedMethods.POST,
      body: JSON.stringify({
        systemKey,
        systemSecret,
      }),
    })
      .then((resp) =>
        resolve({
          systemKey: resp.systemKey,
          authToken: resp.userToken,
        }),
      )
      .catch((e) => {
        reject(e);
      });
  });
}

function createURLConfigForInit(
  url: string,
  messageUrl: string,
  messagePort: string,
  systemKey: string,
  systemSecret: string,
) {
  return {
    URI: url,
    messagingURI: messageUrl,
    messagingPort: parseInt(messagePort, 0),
    systemKey,
    systemSecret,
  };
}

// can be used with anonymous or user creds
export function initWithCreds({
  systemKey,
  systemSecret,
  user: { email, authToken },
  platformConfig: { url, messageUrl, messagePort },
}: InitWithCredsInfo) {
  return new Promise((resolve, reject) => {
    consoleFetch(`/console-api/portal/decodeURL?systemKey=${systemKey}`, {
      method: AllowedMethods.GET,
    })
      .then((resp) => {
        cb.init({
          ...createURLConfigForInit(url, messageUrl, messagePort, resp.systemKey, systemSecret),
          useUser: {
            email,
            authToken,
          },
        });
        resolve(resp);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function fetchPortal(name: string) {
  return new Promise((resolve, reject) => {
    const p = cb.Portal(name);

    p.fetch((err, body) => {
      if (err) {
        reject(body);
      } else {
        resolve(body);
      }
    });
  });
}

export function savePortal(name: string, portalMeta: MasterPortalConfig) {
  return new Promise((resolve, reject) => {
    const p = cb.Portal(name);

    const data = {
      config: JSON.stringify(portalMeta),
    };

    p.update(data, (err, body) => {
      if (err) {
        reject(body);
      } else {
        resolve(body);
      }
    });
  });
}

export function fetchTriggerDefs() {
  return new Promise<TriggerEventDefinition[]>((resolve, reject) => {
    const t = cb.Triggers();
    t.fetchDefinitions((err, body) => {
      if (err) {
        reject(body);
      } else {
        resolve(body as TriggerEventDefinition[]);
      }
    });
  });
}

export function logOutUser() {
  return new Promise((resolve, reject) => {
    cb.logoutUser((err, resp) => {
      if (err) {
        reject(resp);
      } else {
        resolve(resp);
      }
    });
  });
}

function refreshLogin({
  refresh_token,
  userToken,
  systemKey,
  systemSecret,
}: {
  refresh_token: string;
  userToken: string;
  // the encrypted ones
  systemKey: string;
  systemSecret: string;
}): Promise<LoginResponse> {
  return consoleFetch('/console-api/portal/refreshUserLogin', {
    method: AllowedMethods.POST,
    body: JSON.stringify({
      access_token: userToken,
      refresh_token,
      systemKey,
      systemSecret,
    }),
  });
}
/**
 * to automatically keep user logged in, uses the refresh_token provided on initial login in
 * (or the value in local storage if on refresh) and gets a new devToken
 */
export function continuouslyRefreshLoginPortal({
  expires_at = getAuthLocalStorage().expires_at,
  refresh_token = getAuthLocalStorage().refresh_token,
  userToken = getAuthLocalStorage().authToken,
  systemKey = queryString.parse(window.location.search).systemKey as string,
  systemSecret = queryString.parse(window.location.search).systemSecret as string,
  name = queryString.parse(window.location.search).name as string,
}: {
  expires_at?: number;
  refresh_token?: string;
  userToken?: string;
  /** encrypted key from url */
  systemKey?: string;
  /** encrypted key from url */
  systemSecret?: string;
  name?: string;
} = {}) {
  if (!refresh_token) return;
  setAuthLocalStorage({
    expires_at,
    refresh_token,
    authToken: userToken,
    name,
    systemKey,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const timeToExpiration = expires_at * 1000 - Date.now();
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    refreshLogin({ refresh_token, systemKey, systemSecret, userToken }).then((resp) => {
      continuouslyRefreshLoginPortal({
        expires_at: resp.expires_at,
        refresh_token: resp.refresh_token,
        userToken: resp.userToken,
        systemKey,
        systemSecret,
        name,
      });
    });
    // start refresh a minute before it expires
  }, timeToExpiration - 60 * 1000);
}

interface LoginResponse {
  systemKey: string;
  userToken: string;
  refresh_token: string;
  expires_at: number;
}

export function loginUser(
  { email, password }: PortalUserInfo,
  { url, messageUrl, messagePort }: PlatformInfo,
  // the encrypted ones
  systemKey: string,
  systemSecret: string,
): Promise<UserWithAuthToken> {
  return new Promise((resolve, reject) => {
    consoleFetch('/console-api/portal/init/user', {
      method: AllowedMethods.POST,
      body: JSON.stringify({
        systemKey,
        systemSecret,
        email,
        password,
      }),
    })
      .then((resp: LoginResponse) => {
        cb.init({
          ...createURLConfigForInit(url, messageUrl, messagePort, resp.systemKey, systemSecret),
          useUser: {
            email,
            authToken: resp.userToken,
          },
        });
        continuouslyRefreshLoginPortal({
          userToken: resp.userToken,
          refresh_token: resp.refresh_token,
          expires_at: resp.expires_at,
        });
        resolve({
          email,
          authToken: resp.userToken,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

interface RegisterUserInfo extends Omit<InitWithCredsInfo, 'user'> {
  user: PortalUserInfo;
}

export function registerUser({
  systemKey,
  systemSecret,
  user: { email, password },
  platformConfig: { url, messageUrl, messagePort },
}: RegisterUserInfo): Promise<UserWithAuthToken> {
  return new Promise((resolve, reject) => {
    consoleFetch('/console-api/portal/init/register', {
      method: AllowedMethods.POST,
      body: JSON.stringify({
        systemKey,
        systemSecret,
        email,
        password,
        token: cb.user.authToken,
      }),
    })
      .then((resp: LoginResponse) => {
        cb.init({
          ...createURLConfigForInit(url, messageUrl, messagePort, resp.systemKey, systemSecret),
          useUser: {
            email,
            authToken: resp.userToken,
          },
        });
        continuouslyRefreshLoginPortal({
          userToken: resp.userToken,
          refresh_token: resp.refresh_token,
          expires_at: resp.expires_at,
        });
        resolve({
          email,
          authToken: resp.userToken,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type ActionTypes = InitPortalRequest | InitPortalSuccess | FetchPortalRequest | FetchPortalSuccess;
