export enum AppRoutes {
  CONSOLE_BASE_ROUTE = '/console',
  SYSTEM_PARAM = ':systemKey([a-z|0-9]+):edgeName?', // cant `${}` but keep near for changes
  CONSOLE_SYSTEM_ROUTE = '/console/system/:systemKey([a-z|0-9]+):edgeName?',
  PORTAL_ROUTE = '/portal',
  LEGACY_ROUTE = '/',
  DEV_2FA_VALIDATION_ROUTE = '/console/validate/verify',
  OIDC_CALLBACK_ROUTE = '/console/oidc/callback',
  OIDC_TOKEN_ROUTE = '/console/oidc/token',
  LOGIN_ROUTE = '/login',
  REGISTER_ROUTE = '/register',
}
