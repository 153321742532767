import React from 'react';

import { BasicRequestParser } from '../constants';
import { TriggerSourceDefinition } from '../types';
import { MessagingOptions } from 'cb-utils/console-entity-models';
import { TopicSubscribeConditionOptions } from './constants';

const Unsubscribe: TriggerSourceDefinition = {
  name: MessagingOptions.Unsubscribe,
  getSummary: (defKeyObject) => (
    <div>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore */}
      <em>{MessagingOptions.Unsubscribe}</em> from <em>{defKeyObject.topic ? defKeyObject.topic : 'ANY topic'}</em>
    </div>
  ),
  requestParser: BasicRequestParser,
  conditionOptions: TopicSubscribeConditionOptions,
};

export default Unsubscribe;
