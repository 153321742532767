import { EntityModels } from '../state';
import { AppRoutes } from '../../App/routes';
import { systemBaseRoute, detailPageSuffix } from 'cb-utils/consoleRouteUtils';

export const deployRoot = 'deploy';
export const deployBaseRoute = `${AppRoutes.CONSOLE_BASE_ROUTE}${systemBaseRoute}/${deployRoot}`;
export const DEPLOYMENT_ROUTE_PARAMS = {
  DEPLOYMENT_NAME: 'deploymentName',
  ASSET_CLASS_NAME: 'assetClass',
};
export const deployDetailRoute = `${deployBaseRoute}/:${DEPLOYMENT_ROUTE_PARAMS.DEPLOYMENT_NAME}${detailPageSuffix}`;

export interface DeploymentDetailRouteProps {
  deploymentName: string;
}

export interface DeploymentItemLevelRouteProps extends DeploymentDetailRouteProps {
  assetClass: keyof EntityModels;
  collectionName: string;
}
