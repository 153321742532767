export enum ActionConstants {
  PANE_LAYOUT_CHANGED = 'app/CbPaneGridContainer/PANE_LAYOUT_CHANGED',
  PANE_BREAKPOINT_CHANGED = 'app/CbPaneGridContainer/PANE_BREAKPOINT_CHANGED',
  WIDGET_LAYOUT_CHANGED = 'app/CbPaneGridContainer/WIDGET_LAYOUT_CHANGED',
  WIDGET_LAYOUT_CHANGE_REQUEST = 'app/CbPaneGridContainer/WIDGET_LAYOUT_CHANGE_REQUEST',
  WIDGET_BREAKPOINT_CHANGED = 'app/CbPaneGridContainer/WIDGET_BREAKPOINT_CHANGED',
  DELETE_WIDGET_FROM_PANE_GRID_LAYOUT = 'DELETE_WIDGET_FROM_PANE_GRID_LAYOUT',
  DELETE_WIDGET_FROM_PAGE_GRID_LAYOUT = 'DELETE_WIDGET_FROM_PAGE_GRID_LAYOUT',
  REMOVE_WIDGET_FROM_PANE_GRID_LAYOUT = 'REMOVE_WIDGET_FROM_PANE_GRID_LAYOUT',
  NESTED_WIDGET_LAYOUT_CHANGED = 'app/CbPaneGridContainer/NESTED_WIDGET_LAYOUT_CHANGED',
}
