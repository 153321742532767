import { ColumnType } from 'cb-utils/console-entity-models';

const TOPIC_OPTION = {
  name: '<topic>',
  value: 'topic',
  type: ColumnType.string,
  default: true,
};
const BODY_OPTION = {
  name: '<message_body>',
  value: 'body',
  type: ColumnType.string,
  default: true,
};
const ID_OPTION = {
  name: '<user_id>',
  value: 'userId',
  type: ColumnType.string,
  default: true,
};

export const PublishConditionOptions = [TOPIC_OPTION, BODY_OPTION, ID_OPTION];
export const TopicSubscribeConditionOptions = [TOPIC_OPTION, ID_OPTION];
