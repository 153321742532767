import { Map, DefNameOptions, AnyMap, ColumnType } from 'cb-utils/console-entity-models';
import { SettingOption } from 'utils/types';
import { RBCondition } from '../utils';

export enum ArithmeticTypes {
  int = ColumnType.int,
  bigint = ColumnType.bigint,
  double = ColumnType.double,
  float = ColumnType.float,
}

export interface ConditionOption extends SettingOption {
  type: ColumnType;
  default?: boolean;
  name: string; // overrides the string | Element, in this case string only operations are preformed on it
}

export const getPrototypeOfOption = (option: ConditionOption) => {
  switch (option.type) {
    case ColumnType.int:
    case ColumnType.bigint:
    case ColumnType.double:
    case ColumnType.float:
      return Object.getPrototypeOf(0);
    case ColumnType.bool:
      return Object.getPrototypeOf(false);
    case ColumnType.string:
      return Object.getPrototypeOf('');
    default:
      return {};
  }
};

export type ConditionOptions = ConditionOption[];

export interface TriggerSourceDefinition {
  name: DefNameOptions;
  getSummary: (defKeyObj?: AnyMap) => JSX.Element;
  requestParser: string;
  conditionOptions: ConditionOptions;
  getConditionParser?: (condition: RBCondition, options: ConditionOptions) => string;
}

export type TriggerModule = Map<TriggerSourceDefinition>;
