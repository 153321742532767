/*
 * TwoFactorAuthValidationPage Messages
 *
 * This contains all the text for the TwoFactorAuthValidationPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  verifyError: {
    id: 'app.components.TwoFactorAuthValidationPage.verifyError',
    defaultMessage: 'Failed to verify',
  },
  verifySuccess: {
    id: 'app.components.TwoFactorAuthValidationPage.verifySuccess',
    defaultMessage: 'Verified!',
  },
  actionButtonMessage: {
    id: 'app.components.TwoFactorAuthValidationPage.actionButtonMessage',
    defaultMessage: 'Go to {productName} Console',
  },
  validatingMessage: {
    id: 'app.components.TwoFactorAuthValidationPage.validatingMessage',
    defaultMessage: 'Validating...',
  },
  noQueryMessage: {
    id: 'app.components.TwoFactorAuthValidationPage.noQueryMessage',
    defaultMessage: 'No query parameters in URL. Please check that the link you received is correct',
  },
});
