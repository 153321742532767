import ClearBladeCodeService from '../../AlertProviders/codeService/ClearBlade';
import { AvailableAlertTypes, AlertDefinition } from '../types';

const CodeServiceAlert: AlertDefinition<{}> = {
  name: AvailableAlertTypes.CODE_SERVICE,
  label: 'Code Service',

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  providers: [ClearBladeCodeService],

  fields: [],
};

export default CodeServiceAlert;
