/**
 *
 * ErrorBoundary
 *
 */

import * as Sentry from '@sentry/react';
import React from 'react';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  render() {
    return <Sentry.ErrorBoundary showDialog>{this.props.children}</Sentry.ErrorBoundary>;
  }
}

export default ErrorBoundary;
