import { AssetColumnMetadata } from 'cb-utils/console-entity-models/columns';
import { TwoFactorAuthMethods } from './currentUser';

export interface UserModel {
  creation_date: string;
  email: string;
  user_id: string;
  cb_service_account?: boolean;
  cb_ttl_override?: number;
  cb_token?: string;
  email_validated: boolean;
  phone: string;
  phone_validated: boolean;
  two_factor_method: TwoFactorAuthMethods;
  two_factor_enabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [other: string]: any;
}

export interface UsersResponse {
  Data: UserModel[];
  Total: number;
}

export enum USER_COLUMNS {
  creation_date = 'creation_date',
  email = 'email',
  user_id = 'user_id',
  cb_service_account = 'cb_service_account',
  cb_ttl_override = 'cb_ttl_override',
  cb_token = 'cb_token',
  email_validated = 'email_validated',
  phone = 'phone', // todo: will this cause issues with IA systems?
  phone_validated = 'phone_validated',
  two_factor_method = 'two_factor_method',
  two_factor_enabled = 'two_factor_enabled',
}

const showInCreateModal: USER_COLUMNS[] = [
  USER_COLUMNS.email,
  USER_COLUMNS.cb_service_account,
  USER_COLUMNS.cb_ttl_override,
];
const requiredInCreateModal: USER_COLUMNS[] = [USER_COLUMNS.email];
const noUpdateAllowed: USER_COLUMNS[] = [
  USER_COLUMNS.email,
  USER_COLUMNS.creation_date,
  USER_COLUMNS.user_id,
  USER_COLUMNS.cb_token,
];
const displayName: USER_COLUMNS = USER_COLUMNS.email;

export const USER_COLUMN_METADATA: AssetColumnMetadata<USER_COLUMNS> = {
  defaultColumns: Object.values(USER_COLUMNS) as USER_COLUMNS[],
  showInCreateModal,
  requiredInCreateModal,
  noUpdateAllowed,
  displayName,
};
