import React from 'react';

import { REQ_PARAMS_PATH } from '../constants';
import { REQ_OBJECT } from '../../serviceConstants';
import { TriggerSourceDefinition } from '../types';
import { MessagingOptions } from 'cb-utils/console-entity-models';

const MQTTUserDisconnected: TriggerSourceDefinition = {
  name: MessagingOptions.MQTTUserDisconnected,
  getSummary: () => (
    <div>
      <em>User Disconnected</em>
    </div>
  ),
  requestParser: `
    var ${REQ_OBJECT};

    try {
        ${REQ_OBJECT} = JSON.parse(${REQ_PARAMS_PATH});
    } catch(e) {
        ${REQ_OBJECT} = ${REQ_PARAMS_PATH};
    }
  `,
  conditionOptions: [],
};

export default MQTTUserDisconnected;
