import { AlertProvider } from '../AlertProviders/types';
import { PluginSettingDefinitionTypes } from 'utils/types';

export enum AvailableAlertTypes {
  CODE_SERVICE = 'codeService',
  EMAIL = 'email',
  SMS = 'sms',
}

export interface AlertDefinition<AlertFields> {
  name: AvailableAlertTypes;
  label: string;
  providers: Array<AlertProvider<AlertFields, {}>>;
  fields: PluginSettingDefinitionTypes[];
}
