// what does this file need to keep track of?
// instances of widgets
// instances of datasources

import { State as PaneGridState } from 'containers/CbPaneGridContainer/reducer';
import { closeErrorModal } from 'containers/Portal/actions';
import {
  DATASOURCE_AGGREGATOR_TYPE,
  FLYOUT_PANE,
  HEADER,
  HTML_WIDGET_COMPONENT,
  LOCAL_DATASOURCE_PLUGIN,
  PAGE_GRID,
  PANE_GRID,
  PARSER_DIRECTIONS,
  RULE_BUILDER_WIDGET,
  USER_MODAL,
} from 'containers/Portal/constants';
import { State as PortalState } from 'containers/Portal/reducer';
import { LayoutsMap, PaneConfig, RGLBreakpointsExt, RGLLayoutsExt, WidgetContainers } from 'containers/Portal/types';
import { getWidgetIdsForPage } from 'containers/Portal/utils/layoutIterators';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import { DatasourceTypes } from 'plugins/datasources/types';
import { HTMLWidgetSettings } from 'plugins/widgets/HTMLWidget/def';
import { NotificationObject } from 'react-notification';
import { AppStore } from 'reducers';
import {
  DataSettingDefinition,
  DatasourceDefinition,
  DatasourceSuggestions,
  ParserInstanceTypes,
  PluginDefinition,
  PluginSettingDefinitions,
  SettingTypes,
  SettingsInstance,
  WidgetDefinition,
} from 'utils/types';
import { updatePaneLayout, updateWidgetLayoutRequest } from '../../containers/CbPaneGridContainer/actions';
import {
  addErrorNotification,
  addSuccessNotification,
  changeTab,
  closeUserModal,
  createErrorModal,
  datasourceDataUpdated,
  openUserModal,
  widgetUpdated as portalWidgetUpdated,
  selectPageByPathRequest,
  toggleFlyoutPane,
} from '../../containers/Portal/actions';
import PluginWidgetWrapper, { PluginWidgetProps } from '../../plugins/widgets/PluginWidget';
import { AnyMap, Map, createNotification } from '../console-entity-models';
import { ExternalResource, ExternalResourceWithPromise } from '../externalResourceUtils';
import getCurrentPage, { getURLHash } from '../getCurrentPage';
import { InternalResource } from '../internalResourceUtils';
import { RuleBuilderSettings } from '../ruleBuilder/types';
import { CBDispatch } from '../typeUtils';
import PluginPortalModel, { createPluginApi } from './PluginPortalModel';
import { tempDsName } from './constants';
import DatasourceAggregator from './datasource/DatasourceAggregator';
import DatasourceModel, { DatasourceInfo, SerializedDatasourceInfo } from './datasource/DatasourceModel';
import LocalVariableModel from './datasource/LocalVariableModel';
import PreferredDatasource from './datasource/PreferredDatasource';
import PortalPluginModel, { SetupOptions } from './plugin/PluginModel';
import HTMLWidget from './widget/HTMLWidget';
import RuleBuilderModel, { getRuleBuilderInfo } from './widget/RuleBuilderModel';
import WidgetModel, { WidgetInfo } from './widget/WidgetModel';
import defaultWidgetSettings from './widget/defaultSettings';

let hasUnsavedChanges = false;
let permissions: PortalPermissions = {
  create: false,
  read: false,
  update: false,
  delete: false,
};
let store: AppStore;
export interface PortalPermissions {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

const createPromiseForExternalScript = (url: string) =>
  new Promise((resolve) => {
    head.load(url, resolve);
  });

export const transformWidgetModelToJsObject = (rawWidget: WidgetModel<{}>) => {
  return {
    id: rawWidget.id,
    type: rawWidget.type,
    tab: rawWidget.tab,
    props: rawWidget.settings(),
    externalScripts: rawWidget.externalScripts,
    name: rawWidget.name,
  };
};

export const getScriptUrl = (script: ExternalResource) => {
  return typeof script === 'string' ? script : script && script.url ? script.url : '';
};

const processExternalScripts = (scripts: ExternalResource[] = []): ExternalResourceWithPromise[] =>
  scripts.map((script) => ({
    ...script,
    promise: createPromiseForExternalScript(
      // for some reason externalScripts for some old widgets were stored as strings
      getScriptUrl(script),
    ),
  }));

const createNewWidget = (
  id: string,
  info: WidgetInfo<{}>,
  pluginApi: PluginPortalModel,
  source = {},
): WidgetModel<{}> => {
  let newWidgetType;
  switch (info.type) {
    case RULE_BUILDER_WIDGET:
      newWidgetType = new RuleBuilderModel(
        id,
        getRuleBuilderInfo(info as WidgetInfo<RuleBuilderSettings>, store),
        pluginApi,
      );
      break;
    case HTML_WIDGET_COMPONENT:
      newWidgetType = new HTMLWidget(id, info as WidgetInfo<HTMLWidgetSettings>, pluginApi);
      break;
    default:
      newWidgetType = new WidgetModel(id, info, pluginApi);
  }
  // Note: we use object.assign in order to keep a reference to certain member variables (e.g., by keeping a reference to this.latestData, any subscribers are able to hold onto that reference)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.assign(newWidgetType, source);
};

const createNewDatasource = (id: string, info: DatasourceInfo, pluginApi: PluginPortalModel, source = {}) => {
  let NewDatasourceType;
  switch (info.type) {
    case DATASOURCE_AGGREGATOR_TYPE:
      NewDatasourceType = DatasourceAggregator;
      break;
    case LOCAL_DATASOURCE_PLUGIN:
      NewDatasourceType = LocalVariableModel;
      break;
    default:
      NewDatasourceType = DatasourceModel;
  }
  // Note: we use object.assign in order to keep a reference to certain member variables (e.g., by keeping a reference to this.latestData, any subscribers are able to hold onto that reference)
  const newDs = Object.assign(new NewDatasourceType(id, info, pluginApi), source);
  newDs.name = info.name;
  return newDs;
};

const createHandler = (domain: string) => {
  return {
    get: (target: AnyMap, name: string | symbol) => {
      if (name in target) {
        return target[name as string];
      } else if (name === Symbol.toStringTag) {
        return target.toString();
      } else if (name === 'length') {
        return Object.keys(target).length;
      } else {
        throw new Error(`No ${domain} with name '${name as string}'`);
      }
    },
  };
};
const datasourceHandler = createHandler('datasource');
export const paneHandler = createHandler('pane');

const safeProxy = (target: object, handler: ProxyHandler<{}>) => {
  // IE and babel don't support Proxy
  if (typeof Proxy !== 'undefined') {
    return new Proxy(target, handler);
  }
  return target;
};

class PortalModel {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dispatch: CBDispatch;
  datasourceTypes: Map<DatasourceDefinition> = {};
  datasourceInstances: Map<DatasourceModel> = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  tempDatasource: DatasourceModel;
  widgetTypes: Map<WidgetDefinition> = {};
  widgetInstances: Map<WidgetModel<{}>> = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  tempWidget: WidgetModel<{}>;
  plugins: Map<(props: PluginWidgetProps) => JSX.Element> = {};
  upgradesPerformed = {};
  externalPluginsToSetUp: Map<Array<(def: PluginDefinition) => {}>> = {}; // keys will be plugin type, value will be an array of plugin ids
  externalScripts: ExternalResourceWithPromise[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  customPaneNameObject: ProxyHandler<typeof paneHandler>;
  internalResources: Map<InternalResource> = {};
  internalResourceOrder: string[] = [];

  private static _instance: PortalModel;

  private constructor() {
    return;
  }

  static getInstance() {
    if (!PortalModel._instance) {
      PortalModel._instance = new PortalModel();
    }
    return PortalModel._instance;
  }

  static create() {
    return new PortalModel();
  }

  registerStore(storeRef: AppStore) {
    store = storeRef;
    this.dispatch = storeRef.dispatch;
    const NO_INTERNET_ERROR_ID = 'NO_INTERNET_ERROR_ID';
    window.addEventListener('offline', () => {
      // show one error about this (other datasource errors will be blocked with shouldSuppressError = !navigator.onLine )
      this.dispatch(
        createErrorModal({
          id: NO_INTERNET_ERROR_ID,
          title: 'No internet connection',
          error: 'Please reconnect',
        }),
      );
    });
    window.addEventListener('online', () => {
      this.dispatch(closeErrorModal(NO_INTERNET_ERROR_ID, {}));
    });
  }

  getStore() {
    return store;
  }

  getUserPermissions() {
    return permissions;
  }

  setUserPermissions(codes: number) {
    permissions = {
      delete: (codes & 8) === 8,

      update: (codes & 4) === 4,

      create: (codes & 2) === 2,

      read: (codes & 1) === 1,
    };
  }

  cleanUp() {
    const datasourceInstances = this.getDatasourceInstances();
    for (const id of Object.keys(datasourceInstances)) {
      this.deleteDatasourceById(id);
    }
    this.widgetInstances = {};
  }

  /*
   BEGIN DATASOURCE MANAGEMENT
   */
  registerDatasource(ds: DatasourceDefinition) {
    this.datasourceTypes = {
      ...this.datasourceTypes,
      [ds.type_name]: ds,
    };
    const wasDeferred = this.resolveDeferredSetupsForType(ds.type_name, ds);
    // call action to tell datasource instances this plugin is loaded now
    if (wasDeferred) {
      this.dispatch(datasourceDataUpdated(ds.type_name, Date.now()));
    }
  }

  addDatasourceToInstances(id: string, dsInfo: DatasourceInfo, target = {}) {
    const newDatasource = createNewDatasource(id, dsInfo, createPluginApi.call(this), target);
    this.datasourceInstances = {
      ...this.datasourceInstances,
      [id]: newDatasource,
    };
    window.datasources[newDatasource.name] = newDatasource;
    return newDatasource;
  }

  async instantiateDatasource(id: string, dsInfo: DatasourceInfo) {
    try {
      // note: order matters here. we need to add the instance to our list before calling setUp because of the way actions are dispatched during setUp
      const newDatasource = this.addDatasourceToInstances(id, dsInfo);
      const datasourceType = this.datasourceTypes[dsInfo.type];
      if (datasourceType) {
        await newDatasource.setUp(datasourceType);
      } else {
        // looks like we don't have a type yet; this datasource is probably an external plugin
        this.deferSetupForType(dsInfo.type, newDatasource);
      }

      return newDatasource;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.error('Unable to instantiate datasource', id, dsInfo, e.message);
    }
  }

  getSerializableDatasourceInstanceById(id: string, keysToOmit: string[] = []): Partial<SerializedDatasourceInfo> {
    // todo: we should probably make a separate method for omitting keys
    const rawDatasource = this.getDatasourceInstanceById(id);
    return omit<SerializedDatasourceInfo>(
      {
        id,
        type: rawDatasource.type as DatasourceTypes,
        name: rawDatasource.name,
        lastUpdated: rawDatasource.lastUpdated && rawDatasource.lastUpdated.toLocaleTimeString(),
        settings: rawDatasource.settings(),
        didLastUpdateError: rawDatasource.didLastUpdateError,
        lastDataSent: rawDatasource.lastDataSent,
      },
      keysToOmit,
    );
  }

  getDatasourceInstanceById = (id: string) => this.datasourceInstances[id];

  hasDatasourceWithId = (id: string) => this.getDatasourceInstanceById(id);

  getSerializableDatasourceInstances(keysToOmit: string[] = []): Map<SerializedDatasourceInfo> {
    let rtn = {};
    const rawDatasources = this.getDatasourceInstances();
    for (const id of Object.keys(rawDatasources)) {
      rtn = {
        ...rtn,
        [id]: this.getSerializableDatasourceInstanceById(id, keysToOmit),
      };
    }
    return rtn;
  }

  getSerializableDatasourceInstancesForPersistence() {
    return this.getSerializableDatasourceInstances(['lastUpdated', 'didLastUpdateError', 'lastDataSent']);
  }

  getDatasourceInstances = () => {
    return this.datasourceInstances;
  };

  getDatasourceInstancesWithNameAsKey = () => {
    let rtn = {};
    const datasourceInstances = this.getDatasourceInstances();
    for (const id of Object.keys(datasourceInstances)) {
      rtn = {
        ...rtn,
        [datasourceInstances[id].getName()]: datasourceInstances[id],
      };
    }
    return safeProxy(rtn, datasourceHandler);
  };

  getDatasourceTypes() {
    return this.datasourceTypes;
  }

  // this function is used to update any aggregate datasources that are supposed to listen to the given datasource ID
  updateAggregateDatasources = (newDatasourceId: string) => {
    const datasources = this.getDatasourceInstances();
    for (const id of Object.keys(datasources)) {
      if (
        datasources[id].type === DATASOURCE_AGGREGATOR_TYPE &&
        (datasources[id] as DatasourceAggregator).doesListenToDatasource(newDatasourceId)
      ) {
        datasources[id].processUpdateToSettings();
      }
    }
  };

  editDatasourceInstanceById(id: string, info: DatasourceInfo) {
    const datasource = this.getDatasourceInstanceById(id);
    const newTypeDef = this.datasourceTypes[info.type];
    if (datasource && newTypeDef) {
      if (datasource.isNewType(newTypeDef)) {
        datasource.delete();
        const newDatasource = this.addDatasourceToInstances(id, info, datasource);
        return newDatasource.setUp(newTypeDef, info.settings);
      } else {
        return Promise.resolve(datasource.editSettings(info.settings, info.name));
      }
    }
    return Promise.reject('Unable to edit datasource. Could not find datasource instance and/or type definition');
  }

  deleteDatasourceById(id: string) {
    if (this.datasourceInstances[id]) {
      this.datasourceInstances[id].delete();
    }
    this.datasourceInstances = omit(this.datasourceInstances, id);
    for (const ds in window.datasources) {
      if (window.datasources[ds].id === id) {
        delete window.datasources[ds];
        return;
      }
    }
  }

  refreshDatasourceById(id: string) {
    if (this.datasourceInstances[id]) {
      this.datasourceInstances[id].refresh();
    }
  }

  executeParsersForPage(pageId: string) {
    const widgetInstances = this.getWidgetInstances();
    const widgetsOnPage = getWidgetIdsForPage(
      pageId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store.getState().paneGridContainer.paneLayouts,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store.getState().paneGridContainer.paneGridWidgetLayouts,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store.getState().portal.panes,
    );

    widgetsOnPage.forEach((id) => {
      if (Object.prototype.hasOwnProperty.call(widgetInstances, id)) {
        widgetInstances[id].processUpdateToSettings(widgetInstances[id].settings());
      }
    });
  }

  getTemporaryDatasource() {
    return this.tempDatasource;
  }

  createTemporaryDatasource(type: DatasourceTypes, settings: SettingsInstance, rootDatasourceId: string) {
    this.tempDatasource = createNewDatasource(
      tempDsName,
      {
        id: tempDsName,
        name: tempDsName,
        settings,
        type,
      },
      createPluginApi.call(this),
    );
    this.tempDatasource.setUp(this.datasourceTypes[type], settings, {
      rootDatasourceId,
    });
    return this.tempDatasource;
  }

  cleanUpTempDatasource() {
    if (this.tempDatasource) {
      this.tempDatasource.clearSubscriptions();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.tempDatasource = null;
    }
  }

  /*
   END DATASOURCE MANAGEMENT
   */

  /*
   BEGIN WIDGET MANAGEMENT
   */

  allWidgetsLoaded = () => {
    this.dispatch(portalWidgetUpdated('ALL_WIDGETS', Date.now()));
  };

  registerWidget(widget: WidgetDefinition) {
    const w = {
      ...widget,
      settings: [...widget.settings, ...defaultWidgetSettings],
    };

    this.widgetTypes = {
      ...this.widgetTypes,
      [w.type_name]: w,
    };
    const wasDeferred = this.resolveDeferredSetupsForType(w.type_name, w);
    // call action to tell widget instances this plugin is loaded now
    if (wasDeferred) {
      this.dispatch(portalWidgetUpdated(w.type_name, Date.now()));
    }
  }

  resolveDeferredSetupsForType(pluginType: string, pluginDef: PluginDefinition) {
    const deferredIds = this.externalPluginsToSetUp[pluginType];
    if (deferredIds) {
      for (let i = 0, len = deferredIds.length; i < len; i += 1) {
        deferredIds[i](pluginDef);
      }
      delete this.externalPluginsToSetUp[pluginType];
    }
    return deferredIds;
  }

  deferSetupForType(pluginType: string, pluginInstance: PortalPluginModel<{}>) {
    if (!this.externalPluginsToSetUp[pluginType]) {
      this.externalPluginsToSetUp[pluginType] = [];
    }
    this.externalPluginsToSetUp[pluginType].push(pluginInstance.setUp.bind(pluginInstance));
  }

  addWidgetToInstances(id: string, info: WidgetInfo<{}>, target = {}) {
    const newWidget = createNewWidget(id, info, createPluginApi.call(this), target);
    // note: order matters here. we need to add the instance to our list before calling setUp because of the way actions are dispatched during setUp
    this.widgetInstances = {
      ...this.widgetInstances,
      [id]: newWidget,
    };
    return newWidget;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  instantiateWidget(id: string, info: WidgetInfo<{}>, options: SetupOptions = {}): Promise<WidgetModel<{}>> {
    try {
      // note: order matters here. we need to add the instance to our list before calling setUp because of the way actions are dispatched during setUp
      const newWidget = this.addWidgetToInstances(id, info);
      const widgetType = this.widgetTypes[info.type];
      if (widgetType) {
        return new Promise((resolve) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newWidget.setUp(widgetType, null, options).then(() => {
            resolve(newWidget);
          });
        });
      } else {
        // looks like we don't have a type yet; this widget is probably an external plugin
        this.deferSetupForType(info.type, newWidget);
      }
    } catch (e) {
      console.log('caught?', e);
      throw e;
    }
  }

  getWidgetInstanceById(id: string) {
    const widgetInstances = this.getWidgetInstances();
    if (widgetInstances) {
      return widgetInstances[id];
    }
  }

  getSerializableWidgetInstanceById(id: string) {
    const rawWidget = this.getWidgetInstanceById(id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return transformWidgetModelToJsObject(rawWidget);
  }

  getSerializableWidgetInstances(): Map<WidgetInfo<{}>> {
    const widgetInstances = this.getWidgetInstances();
    let rtn = {};
    for (const id of Object.keys(widgetInstances)) {
      rtn = {
        ...rtn,
        [id]: transformWidgetModelToJsObject(widgetInstances[id]),
      };
    }
    return rtn;
  }

  getWidgetClassByType(type: string) {
    // first see if the widgetType has been registered yet
    if (this.widgetTypes[type]) {
      // if it has check if it's a plugin (custom) widget
      if (this.widgetTypes[type].plugin) {
        // if it is, and it hasn't been registered yet, then create the plugin
        if (!this.plugins[type]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.plugins[type] = PluginWidgetWrapper(this.widgetTypes[type].class);
        }
        return this.plugins[type];
      }
      return this.widgetTypes[type].class;
    } else {
      return null;
    }
  }

  getWidgetTypes() {
    return this.widgetTypes;
  }

  getWidgetTypeById(id: string) {
    return this.getWidgetTypes()[id];
  }

  getThemeableSettingsForWidgets() {
    const rtn = [];
    const types = this.getWidgetTypes();
    let currentWidget;
    for (const type of Object.keys(types)) {
      currentWidget = {
        name: types[type].type_name,
        display_name: types[type].display_name,
        fields: types[type].settings.filter((setting) => {
          return setting.isThemeable;
        }),
        group: types[type].group,
      };
      rtn.push(currentWidget);
    }
    return rtn;
  }

  getIncomingDatasourcesForWidget(settings: PluginSettingDefinitions) {
    return this.getDatasourcesForWidget(settings, PARSER_DIRECTIONS.INCOMING_PARSER);
  }

  getOutgoingDatasourcesForWidget(settings: PluginSettingDefinitions) {
    return this.getDatasourcesForWidget(settings, PARSER_DIRECTIONS.OUTGOING_PARSER);
  }

  getDatasourcesForWidget(settings: PluginSettingDefinitions, parserType: ParserInstanceTypes) {
    const parsersOfType: { [settingName: string]: DatasourceSuggestions } = {};
    let preferredInstances: Map<PreferredDatasource> = {};
    let otherInstances: Map<DatasourceModel> = {};
    let datasources = {
      preferredInstances: {},
      otherInstances: {},
    };
    for (const setting of settings) {
      preferredInstances = {};
      otherInstances = {};
      datasources = {
        preferredInstances: {},
        otherInstances: {},
      };
      const datasourceInstances = this.getDatasourceInstances();
      // note - this might look a little verbose but it's the best way I could think of to appease the TS compiler
      if (
        (parserType === PARSER_DIRECTIONS.INCOMING_PARSER && (setting as DataSettingDefinition).incoming_parser) ||
        (parserType === PARSER_DIRECTIONS.OUTGOING_PARSER && (setting as DataSettingDefinition).outgoing_parser)
      ) {
        for (const id in datasourceInstances) {
          if (
            (setting as DataSettingDefinition).preferred_datasources &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Object.keys((setting as DataSettingDefinition).preferred_datasources).indexOf(
              datasourceInstances[id].type,
            ) > -1
          ) {
            preferredInstances[id] = {
              ...datasourceInstances[id],
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - Clark should fix this
              defaultParser: (setting as DataSettingDefinition).preferred_datasources[datasourceInstances[id].type]
                .parser
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (setting as DataSettingDefinition).preferred_datasources[datasourceInstances[id].type].parser
                : undefined,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - Clark should fix this
              defaultSettings: (setting as DataSettingDefinition).preferred_datasources[datasourceInstances[id].type]
                .settings
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (setting as DataSettingDefinition).preferred_datasources[datasourceInstances[id].type].settings
                : undefined,
            };
          } else {
            otherInstances[id] = datasourceInstances[id];
          }
        }
        datasources.preferredInstances = {
          ...preferredInstances,
        };
        datasources.otherInstances = {
          ...otherInstances,
        };
        parsersOfType[setting.name] = datasources;
      }
    }
    return parsersOfType;
  }

  getUpdateCallbackForWidgetById(id: string) {
    return this.widgetInstances[id]?.updateCallback;
  }

  getWidgetInstances() {
    return this.widgetInstances;
  }

  async editWidget(id: string, info: WidgetInfo<{}>, changedSettingType?: SettingTypes) {
    const widget = this.getWidgetInstanceById(id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    widget.setName(info.name);
    const newTypeDef = this.widgetTypes[info.type];
    if (widget && newTypeDef) {
      if (widget.isNewType(newTypeDef)) {
        const newWidget = this.addWidgetToInstances(id, info, widget);
        return newWidget.setUp(newTypeDef, info.props);
      } else {
        return Promise.resolve(widget.editSettings(info.props, info.externalScripts, changedSettingType));
      }
    }
    return Promise.reject('Unable to edit widget. Could not find widget and/or type definition');
  }

  removeWidgetById(id: string) {
    if (this.widgetInstances[id]) {
      delete this.widgetInstances[id];
      this.dispatch(portalWidgetUpdated(id, Date.now()));
    } else {
      console.warn('Could not delete widget with id: ', id);
    }
  }

  getTemporaryWidget() {
    return this.tempWidget;
  }

  createTemporaryWidget(id: string, type: string, props: SettingsInstance, externalResourcesForWidget: string[]) {
    this.tempWidget = createNewWidget(
      id,
      {
        id,
        name: id,
        type,
        props,
        externalScripts: externalResourcesForWidget,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tab: null,
      },
      createPluginApi.call(this),
    );
    this.tempWidget.setUp(this.widgetTypes[type]);
    return this.tempWidget;
  }

  async editTemporaryWidget(type: string, newTypeDef: WidgetDefinition, newSettings: SettingsInstance) {
    const currentTempWidget = this.tempWidget;
    this.tempWidget = createNewWidget(
      currentTempWidget.id,
      {
        id: currentTempWidget.id,
        name: currentTempWidget.name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tab: null,
        type,
        props: newSettings,
        externalScripts: currentTempWidget.externalScripts,
      },
      createPluginApi.call(this),
      currentTempWidget,
    );
    await this.tempWidget.setUp(newTypeDef, newSettings);
    return this.tempWidget;
  }

  cleanUpTempWidget() {
    this.tempWidget.clearSubscriptions();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.tempWidget = null;
  }

  getLayoutTargetForWidget(id: string): // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    tabId: string;
    target: WidgetContainers;
    modalName: string;
    pageId: string;
  } {
    const state = this.getStore().getState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paneLayouts = state.paneGridContainer.paneLayouts;
    if (isWidgetInMapOfLayouts(paneLayouts, id)) {
      return {
        target: PAGE_GRID,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabId: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalName: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageId: null,
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paneGridWidgetLayouts = state.paneGridContainer.paneGridWidgetLayouts;
    const tabId = isWidgetInMapOfLayouts(paneGridWidgetLayouts, id);
    if (tabId) {
      return {
        target: PANE_GRID,
        tabId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalName: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageId: null,
      };
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const flyoutLayout = state.fyoutPaneContainer.flyoutWidgetLayout;
    if (isWidgetInLayout(flyoutLayout, id)) {
      return {
        target: FLYOUT_PANE,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabId: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalName: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageId: null,
      };
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const headerLayout = state.headerContainer.headerWidgetLayout;
    if (isWidgetInLayout(headerLayout, id)) {
      return {
        target: HEADER,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabId: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalName: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageId: null,
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const userModalLayouts = state.CbUserModalContainer.userModalWidgetLayout;
    const modalName = isWidgetInMapOfLayouts(userModalLayouts, id);
    if (modalName) {
      return {
        target: USER_MODAL,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabId: null,
        modalName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageId: null,
      };
    }
  }

  /*
   END WIDGET MANAGEMENT
   */

  /*
   BEGIN PANE MANAGEMENT
   */

  changeTabBasedOnCustomPaneId(
    panes: Map<PaneConfig>,
    changeTabRef: typeof changeTab,
    customPaneId: string,
    tabNumber: number,
  ) {
    let tabId = null;
    const paneId = getPaneIdByCustomPaneId(panes, customPaneId);
    if (paneId) {
      if (tabNumber > panes[paneId].tabInfo.tabIds.length) {
        console.warn('index exceeds number of tabs');
        return;
      }
      tabId = panes[paneId].tabInfo.tabIds[tabNumber];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.dispatch(changeTabRef(tabId, paneId));
  }

  setCustomPaneLayout(
    panes: Map<PaneConfig>,
    updatePaneLayoutRef: typeof updatePaneLayout,
    customPaneId: string,
    width: number,
    height: number,
  ) {
    const paneId = getPaneIdByCustomPaneId(panes, customPaneId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pageId = panes[paneId].pageId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paneGridContainer: PaneGridState = this.getStore().getState().paneGridContainer;
    const breakpoint = paneGridContainer.currentBreakpointForPanes;
    const layouts = paneGridContainer.paneLayouts[pageId];
    let layout;
    for (const size in layouts) {
      if (size === breakpoint) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layouts[size].forEach((pane: ReactGridLayout.Layout) => {
          if (pane.i === paneId) {
            pane.h = height;
            pane.w = width;
          }
        });
        layout = layouts[size];
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.dispatch(updatePaneLayoutRef(pageId, layout, layouts));
  }

  getPaneAndTabIdByWidgetId(widgetId: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const portal: PortalState = this.getStore().getState().portal;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tabId = this.getWidgetInstanceById(widgetId).tab;
    let paneId = '';
    const panes = portal.panes;
    for (const id in panes) {
      if (panes[id].tabInfo.tabIds.includes(tabId)) {
        paneId = panes[id].id;
        break;
      }
    }
    return {
      paneId,
      tabId,
    };
  }

  setCustomWidgetLayout = (widgetId: string, width: number, height: number) => {
    const ids = this.getPaneAndTabIdByWidgetId(widgetId);
    const tabId = ids.tabId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paneGridContainer: PaneGridState = this.getStore().getState().paneGridContainer;
    const breakpoint = paneGridContainer.currentBreakpointsForWidgets[tabId];
    const layouts = paneGridContainer.paneGridWidgetLayouts[tabId];

    let layout;
    for (const size in layouts) {
      if (size === breakpoint) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layouts[size].forEach((widget: ReactGridLayout.Layout) => {
          if (widget.i === widgetId) {
            widget.h = height;
            widget.w = width;
          }
        });
        layout = layouts[size];
      }
    }

    const newLayouts = {
      layout,
      layouts,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.dispatch(updateWidgetLayoutRequest(ids.paneId, ids.tabId, breakpoint, newLayouts.layout, newLayouts.layouts));
  };

  constructCustomPaneNameObject = (panes: Map<PaneConfig>, changeTabRef: typeof changeTab) => {
    this.customPaneNameObject = safeProxy(
      reduce(
        panes,
        (result, pane) => {
          if (pane.customPaneId) {
            result[pane.customPaneId] = {
              updateCurrentTab: this.changeTabBasedOnCustomPaneId.bind(this, panes, changeTabRef, pane.customPaneId),
              setCustomDimensions: this.setCustomPaneLayout.bind(this, panes, updatePaneLayout, pane.customPaneId),
            };
          }
          return result;
        },
        {} as Map<object>,
      ),
      paneHandler,
    );
  };

  getCustomPaneNameObject = () => {
    return this.customPaneNameObject;
  };

  /*
   END PANE MANAGEMENT
   */

  /*
   BEGIN MISCELLANEOUS FUNCTIONS
   */

  setIsDirty(val: boolean) {
    hasUnsavedChanges = val;
  }

  getIsDirty() {
    return hasUnsavedChanges;
  }

  toggleFlyout() {
    this.dispatch(toggleFlyoutPane());
  }

  Modals = {
    open(modalName: string) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.dispatch(openUserModal({ modalName }));
    },
    close(modalName?: string) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.dispatch(closeUserModal({ modalName }));
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    list() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Object.keys(this.getStore().getState().portal.userModals);
    },
  };

  selectPage(path: string) {
    this.dispatch(selectPageByPathRequest(path));
  }

  // this function is meant to be used by parsers when a portal loads up.
  // i.e., a user hits a #/generators/:id page - the parsers need access to the route params in order
  // to show the correct data
  getPathParams() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pages = store.getState().portal.pages;
    const hash = getURLHash();
    const match = getCurrentPage(pages, hash);
    if (match) {
      return match.match.params;
    }

    console.warn(`Unable to find page that matches '${hash}'`);
    return {};
  }

  handleUpgrade(upgradeId: string) {
    this.upgradesPerformed = {
      ...this.upgradesPerformed,
      [upgradeId]: true,
    };
  }

  setExternalScripts(scripts: ExternalResource[] = []) {
    this.externalScripts = processExternalScripts(scripts);
    return this.externalScripts;
  }

  getExternalScripts = (): ExternalResourceWithPromise[] => {
    return this.externalScripts;
  };

  removeExternalScriptFromAllWidgets(script: ExternalResourceWithPromise) {
    const widgets = this.getWidgetInstances();
    for (const id of Object.keys(widgets)) {
      widgets[id].removeExternalScript(script.url);
    }
  }

  addExternalScriptToAllWidgets(script: ExternalResource) {
    const widgets = this.getWidgetInstances();
    for (const id of Object.keys(widgets)) {
      widgets[id].addExternalScript(script.url);
    }
  }

  modifyExternalScriptForAllWidgets(script: ExternalResource) {
    const widgets = this.getWidgetInstances();
    const scriptUrl = script.url;
    for (const id in widgets) {
      if (script.shouldBlockAllWidgets && !widgets[id].dependsOnScript(scriptUrl)) {
        widgets[id].addExternalScript(scriptUrl);
      } else if (!script.shouldBlockAllWidgets && widgets[id].dependsOnScript(scriptUrl)) {
        widgets[id].removeExternalScript(scriptUrl);
      }
    }
  }

  getPromiseForExternalScript = (url: string) => {
    return this.getExternalScripts().filter((script) => script.url === url)[0].promise;
  };

  createNotification(notification: Partial<NotificationObject>) {
    const not = createNotification(notification);
    if (not.className === 'error') {
      this.dispatch(addErrorNotification(not));
    } else {
      this.dispatch(addSuccessNotification(not));
    }
  }

  /**
   * Adds internal resources to the portal. Optional 'order' parameter sets the
   * order for all internal resources in the portal
   */
  addInternalResources = (files: Map<InternalResource>, order?: string[]) => {
    this.internalResources = {
      ...this.internalResources,
      ...files,
    };
    if (order) {
      this.internalResourceOrder = order;
    } else if (files) {
      const newOrders = Object.keys(files);
      const uniqueOrders = newOrders.filter((id) => !this.internalResourceOrder.includes(id));
      this.internalResourceOrder = [...this.internalResourceOrder, ...uniqueOrders];
    }
  };

  deleteInternalResource = (id: string) => {
    this.internalResources = omit(this.internalResources, id);
    this.internalResourceOrder = this.internalResourceOrder.filter((cur) => id !== cur);
  };

  reorderInternalResources = (order: string[]) => {
    this.internalResourceOrder = order;
  };

  getInternalResources = () => this.internalResources;

  getInternalResourceOrder = () => this.internalResourceOrder;

  /*
   END MISCELLANEOUS FUNCTIONS
   */
}

const getPaneIdByCustomPaneId = (panes: Map<PaneConfig>, customPaneId: string) => {
  for (const id in panes) {
    if (panes[id].customPaneId === customPaneId) {
      return id;
    }
  }
};

const isWidgetInLayout = (layouts: RGLLayoutsExt, id: string) => {
  for (const size in layouts) {
    if (Object.prototype.hasOwnProperty.call(layouts, size)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const found = layouts[size as RGLBreakpointsExt].find((l) => l.i === id);
      if (found) {
        return true;
      }
    }
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isWidgetInMapOfLayouts = (m: LayoutsMap, id: string): string => {
  if (m) {
    // containerId could be page name, tab name, userModal name, etc.
    for (const containerId in m) {
      if (isWidgetInLayout(m[containerId], id)) {
        return containerId;
      }
    }
  }
};

export default PortalModel;
