/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import 'intl-pluralrules';
// import '@formatjs/intl-relativetimeformat/polyfill';
// import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
// import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

import { AnyMap, Map } from 'cb-utils/console-entity-models';
import { DEFAULT_LOCALE } from './containers/App/constants';
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';

export const appLocales = ['en', 'es'];

export const formatTranslationMessages = (locale: string, messages: Map<string>) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = (defaultFormattedMessages as AnyMap)[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
};
