import React from 'react';
import { REQ_OBJECT } from '../../serviceConstants';
import { REQ_PARAMS_PATH } from '../constants';
import { TriggerSourceDefinition } from '../types';
import { UserOptions } from 'cb-utils/console-entity-models';
import { UserConditionOptions } from './utils';

const UserCreated: TriggerSourceDefinition = {
  name: UserOptions.UserCreated,
  getSummary: () => (
    <div>
      <em>User Created</em>
    </div>
  ),
  requestParser: `
    var ${REQ_OBJECT} = ${REQ_PARAMS_PATH}.user;
  `,
  conditionOptions: UserConditionOptions,
};

export default UserCreated;
