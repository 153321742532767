import { getFieldsInCondition } from 'cb-utils/ruleBuilder/ConditionBuilder';
import { REQ_OBJECT } from 'cb-utils/ruleBuilder/serviceConstants';
import { RBCondition } from 'cb-utils/ruleBuilder/utils';
import { SettingOptions } from 'utils/types';

const RULE_BUILDER_STATE_DOMAIN_PREFIX = 'RULE_BUILDER-';
export const createStateDomainId = (id: string): string => {
  return `${RULE_BUILDER_STATE_DOMAIN_PREFIX}${id}`;
};

export function createNameSpacedConstant(id: string, constant: string): string {
  return `${id}/${constant}`;
}

export const createOptionsForParameterizedTextArea = (condition: RBCondition): SettingOptions => {
  const rtn = [
    {
      value: `{{${REQ_OBJECT}}}`,
      name: 'Request Object',
    },
  ];

  const conditionFields = getFieldsInCondition(condition);
  conditionFields.forEach((field) => {
    if (field) {
      rtn.push({
        value: `{{${REQ_OBJECT}.${field}}}`,
        name: field,
      });
    }
  });

  return rtn;
};
