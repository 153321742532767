import uuid from 'uuid/v4';
import { NotificationObject } from 'react-notification';

/** Create a notification object filling in defaults.
 */
export function createNotification({
  key = uuid(),
  action = 'dismiss',
  title = 'Success',
  className = 'success',
  message = '',
  dismissAfter = 2500,
  onClick,
}: Partial<NotificationObject>): NotificationObject {
  return {
    key,
    action,
    title,
    className: `cb-notification-container ${className}`,
    message,
    dismissAfter,
    onClick,
  };
}
