// all widgets get these settings
import { createText, createBoolean } from 'containers/Portal/pluginSettingUtils';

export interface DefaultWidgetSettings {
  canFullScreen?: boolean;
  htmlId?: string;
}

const defaultSettings = [
  createText<DefaultWidgetSettings>('htmlId', 'HTML Id', 'Used to target widget element in the DOM'),
  createBoolean<DefaultWidgetSettings>('canFullScreen', 'Allow Fullscreen'),
];

export default defaultSettings;
